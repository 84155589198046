import React from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  TableRow,
  TableCell,
  Typography,
  Box,
  Tooltip,
  ListItem,
  ListItemText,
  List,
} from '@mui/material'
import { fShortenNumber } from '../../../../utils/formatNumber'
import { socialIcon } from './utils'
import useTranslation from '../../../../../localization/useTranslation'

const typographyStyles = {
  fontWeight: 600,
  fontSize: '0.685rem',
  color: '#FFFFFF',
  maxWidth: '300px',
  wordWrap: 'break-word',
  whiteSpace: 'pre-line',
}

export const CpmRows = ({ row, isForPdf }) => {
  const {
    label,
    maxCreatorValue,
    minCreatorValue,
    missingCreators,
    totalContent,
    totalCpm,
    totalReach,
    totalSpend,
    isReachEstimated,
  } = row

  const { labelStrings } = useTranslation()

  const isBoldCell = label?.endsWith('_total') || label === 'overall' || label === 'tiktok'
  const isDivider = label?.startsWith('tiktok')

  const titleTooltip = (
    <Stack>
      <Typography sx={{ ...typographyStyles, pb: isForPdf ? 1 : 0 }}>
        {labelStrings.notAllCreatorsAssignedPricing('CPM')}
      </Typography>
      <Stack>
        <Typography sx={{ ...typographyStyles, marginTop: '2px', pb: isForPdf ? 1 : 0 }}>
          {labelStrings.missingCreators}:
        </Typography>
        <List dense={true} sx={{ p: 0 }}>
          {missingCreators?.map((missingCreator, idx) => {
            if (idx > 4) return
            return (
              <ListItem key={missingCreator} sx={{ paddingRight: 0, paddingLeft: 0.5, py: 0 }}>
                <ListItemText
                  primary={
                    <Typography sx={{ ...typographyStyles, pb: isForPdf ? 1 : 0 }}>
                      @{missingCreator}
                    </Typography>
                  }
                />
              </ListItem>
            )
          })}
          {missingCreators?.length > 5 && (
            <ListItem sx={{ py: 0, px: 0.5 }}>
              <ListItemText
                sx={typographyStyles}
                primary={
                  <Typography sx={{ ...typographyStyles, pb: isForPdf ? 1 : 0 }}>
                    and more...
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
      </Stack>
    </Stack>
  )

  return (
    <TableRow
      hover
      sx={{
        borderTop: isDivider && isBoldCell ? '1px solid rgba(145, 158, 171, 0.24)' : 'inherit',
        borderBottom:
          label === 'overall' || (isDivider && isBoldCell)
            ? '1px solid rgba(145, 158, 171, 0.24)'
            : 'inherit',
      }}
    >
      <TableCell
        align={label === 'overall' ? 'center' : 'left'}
        sx={{ borderRight: '2px solid rgba(145, 158, 171, 0.24)', pb: isForPdf ? 1 : 'auto' }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          {label !== 'overall' ? (
            <Stack direction="row" spacing={0.5}>
              <Box
                sx={{
                  width: '25px',
                  height: '25px',
                  display: 'inline-block',
                  pt: isForPdf ? 1 : 0,
                }}
              >
                {socialIcon[label?.split('_')[0]]}
              </Box>
              <Typography
                noWrap
                variant={isBoldCell ? 'subtitle2' : 'inherit'}
                sx={{ textTransform: 'capitalize', minWidth: '75px' }}
              >
                {label?.split('_')[1]}
              </Typography>
            </Stack>
          ) : (
            <Typography
              textAlign="center"
              noWrap
              variant={'subtitle2'}
              sx={{ textTransform: 'capitalize', pb: isForPdf ? 1 : 0 }}
            >
              {label}
            </Typography>
          )}
        </Stack>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {totalCpm ? fShortenNumber(totalCpm) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {totalSpend ? fShortenNumber(totalSpend) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {totalReach ? `${fShortenNumber(totalReach)}${isReachEstimated ? '*' : ''}` : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {totalContent ? fShortenNumber(totalContent) : ''}{' '}
        </Typography>
      </TableCell>

      <TableCell align="center">
        {' '}
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {minCreatorValue ? fShortenNumber(minCreatorValue) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {maxCreatorValue ? fShortenNumber(maxCreatorValue) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Tooltip title={titleTooltip} placement="top" arrow>
          <Typography
            sx={{
              color: isBoldCell ? '#FF5D5A' : 'inherit',
              cursor: 'pointer',
              pb: isForPdf ? 1 : 0,
            }}
            noWrap
            variant={isBoldCell ? 'subtitle2' : 'inherit'}
          >
            {missingCreators?.length ? `${fShortenNumber(missingCreators?.length)} Creators` : ''}
          </Typography>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

CpmRows.propTypes = {
  row: PropTypes.object,
}
