import React from 'react'
import { Stack, Skeleton, Box } from '@mui/material'

export const CreatorsLikeBasedSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#FFF5F5 !important',
        borderRadius: '13px',
        width: '168px',
        p: 1.5,
      }}
    >
      <Stack alignItems="center" spacing={1.5}>
        <Skeleton sx={{ backgroundColor: '#EBD0D0' }} variant="circular" width={60} height={60} />

        <Stack alignItems="center" spacing={0} sx={{ width: '100%' }}>
          <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="100%" height="20px" variant="text" />
          <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="90%" height="15px" variant="text" />
          <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="95%" variant="text" />
        </Stack>

        <Stack alignItems="center" spacing={0} sx={{ width: '100%' }}>
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} variant="circular" width={9} height={9} />
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} variant="circular" width={9} height={9} />
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} variant="circular" width={9} height={9} />
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} variant="circular" width={9} height={9} />
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} variant="circular" width={9} height={9} />
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} variant="circular" width={9} height={9} />
          </Stack>
          <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="100%" variant="text" />
        </Stack>

        <Stack alignItems="center" spacing={0.5} sx={{ width: '100%' }}>
          <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="70px" height="15px" variant="text" />
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Skeleton
              sx={{ backgroundColor: '#EBD0D0' }}
              variant="circular"
              width={24}
              height={24}
            />
            <Skeleton
              sx={{ backgroundColor: '#EBD0D0' }}
              variant="circular"
              width={24}
              height={24}
            />
            <Skeleton
              sx={{ backgroundColor: '#EBD0D0' }}
              variant="circular"
              width={24}
              height={24}
            />
            <Skeleton
              sx={{ backgroundColor: '#EBD0D0' }}
              variant="circular"
              width={24}
              height={24}
            />
            <Skeleton
              sx={{ backgroundColor: '#EBD0D0' }}
              variant="circular"
              width={24}
              height={24}
            />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          sx={{ width: '100%' }}
        >
          <Skeleton sx={{ backgroundColor: '#EBD0D0' }} variant="circular" width={15} height={15} />
          <Skeleton
            sx={{ backgroundColor: '#EBD0D0' }}
            width="100px"
            height="15px"
            variant="text"
          />
        </Stack>
      </Stack>
    </Box>
  )
}
