import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Popup from 'reactjs-popup'

const TextWrapper = styled.span`
  color: #442424;
  margin: 0 auto;
  font-family: Inter;
`

const Wrapper = styled.div`
  .collection-sidebar-menu {
    z-index: 9999 !important;
  }

  .popup-content {
    min-width: 180px !important;
    width: fit-content !important;
    display: flex !important;

    z-index: 1000 !important;
  }

  .collection-sidebar-menu-content {
    font-size: 14px;
    min-width: 180px !important;
    width: fit-content !important;
    display: flex !important;
    z-index: 1000 !important;
  }

  .collection-sidebar-menu-content,
  .email-popup-content {
    text-align: center;
    min-width: 180px !important;
    width: fit-content !important;
    display: flex !important;

    z-index: 400 !important;
    background: ${props => props.theme.pickColorDependOnPlatform(props.userindex).tipBG} !important;
    box-shadow: 0px 0px 6px ${props => props.theme.color.white} !important;
    border: none !important;
    border-radius: 5px;
    padding: 8px 12px 8px 12px !important;

    .collection-sidebar-menu-arrow,
    .email-popup-arrow {
      z-index: 300 !important;
      background: ${props =>
        props.theme.pickColorDependOnPlatform(props.userindex).tipBG} !important;
      box-shadow: 0px 0px 6px transparent !important;
      border: none !important;
      border-radius: 1px !important;
      // left: 50% !important;
      ${props => props.hideTipArrow && `display: none !important;`}
    }
    .collection-sidebar-menu-arrow {
      // display: none;
      // z-index: 100;
    }
  }
`

const WrapperWithWhiteBGTip = styled(Wrapper)`
  .collection-sidebar-menu {
    z-index: 1000 !important;
  }

  .popup-content {
    min-width: 180px !important;
    width: fit-content !important;
    display: flex !important;

    z-index: 1000 !important;
  }

  .collection-sidebar-menu-content {
    font-size: 14px;
    min-width: 180px !important;
    width: fit-content !important;
    display: flex !important;
    z-index: 1000 !important;
  }

  .collection-sidebar-menu-content,
  .email-popup-content {
    text-align: center;
    min-width: 180px !important;
    width: fit-content !important;
    display: flex !important;

    z-index: 400 !important;
    background: ${props => props.theme.color.white} !important;
    box-shadow: 0px 10px 20px #84363040 !important;
    border: none !important;
    border-radius: 5px;
    padding: 8px 12px 8px 12px !important;

    .collection-sidebar-menu-arrow,
    .email-popup-arrow {
      z-index: 300 !important;
      background: ${props => props.theme.color.white} !important;
      box-shadow: 0px 0px 6px transparent !important;
      border: none !important;
      border-radius: 1px !important;
      // left: 50% !important;
    }
  }
`

const WithTip = ({
  children = null,
  tipText = '',
  position = 'right center',
  userindex = '',
  TipComponent = null,
  useWhiteBG = false,
  ...props
}) => {
  const OuterWrapper = useWhiteBG ? WrapperWithWhiteBGTip : Wrapper

  const hideTipArrow = position === 'center center'

  return (
    <OuterWrapper userindex={userindex} hideTipArrow={hideTipArrow}>
      <Popup
        trigger={children}
        position={position}
        // on="click" // for tests
        // on="hover"
        on={['hover', 'focus']}
        className={'collection-sidebar-menu'}
        closeOnDocumentClick
        {...props}
      >
        {TipComponent || <TextWrapper>{tipText}</TextWrapper>}
      </Popup>
    </OuterWrapper>
  )
}

WithTip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  tipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  position: PropTypes.string,
  userindex: PropTypes.string,
  TipComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
  useWhiteBG: PropTypes.bool,
}

export default WithTip
