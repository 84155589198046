import React from 'react'
import { Stack, Box, Paper, Typography, IconButton, Button } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded'
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded'
import { useDispatch } from 'react-redux'
import {
  changeShowReceiveSurveyResult,
  receiveSurveyResult,
} from '../../../redux/ducks/searchDucks'
import useTranslation from '../../../localization/useTranslation'

export const ReceiveSurveyResultDialog = () => {
  const dispatch = useDispatch()
  const { labelStrings } = useTranslation()

  const onCloseReceiveSurveyResult = () => {
    dispatch(changeShowReceiveSurveyResult(false))
  }

  const onReceiveSurveyResult = result => {
    dispatch(
      receiveSurveyResult({
        result,
      })
    )
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 0.75, // Reduced from 1.5
        position: 'fixed',
        top: `calc(100vh - 55px)`, // Reduced from 110px
        left: '50%',
        transform: `translateX(-50%)`,
        backgroundColor: '#F4E8E8',
        zIndex: 9999999,
      }}
    >
      <Stack sx={{ position: 'relative' }}>
        <Typography
          textAlign="center"
          sx={{
            fontFamily: 'Inter',
            color: '#442424',
            fontSize: '0.75rem', // Added to reduce text size
          }}
        >
          {labelStrings?.searchSuggestions?.receiveSurveyResultLabel}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          {' '}
          {/* Reduced spacing from 2 */}
          <Button
            onClick={() => onReceiveSurveyResult('positive')}
            sx={{
              fontFamily: 'Inter',
              color: '#442424',
              fontSize: '0.75rem', // Added to reduce text size
              padding: '4px 8px', // Added to reduce button size
              minWidth: 'auto', // Added to allow smaller button width
            }}
            size="small" // Added to reduce button size
            startIcon={<ThumbUpRoundedIcon sx={{ fontSize: '1rem' }} />} // Reduced icon size
          >
            {labelStrings?.searchSuggestions?.receiveSurveyResultPositive}
          </Button>
          <Button
            onClick={() => onReceiveSurveyResult('negative')}
            sx={{
              fontFamily: 'Inter',
              color: '#442424',
              fontSize: '0.75rem', // Added to reduce text size
              padding: '4px 8px', // Added to reduce button size
              minWidth: 'auto', // Added to allow smaller button width
            }}
            size="small" // Added to reduce button size
            startIcon={<ThumbDownRoundedIcon sx={{ fontSize: '1rem' }} />} // Reduced icon size
          >
            {labelStrings?.searchSuggestions?.receiveSurveyResultNegative}
          </Button>
        </Stack>
        <Box sx={{ position: 'absolute', top: '-8px', right: '-7px' }}>
          {' '}
          {/* Reduced from -16px and -14px */}
          <IconButton
            onClick={onCloseReceiveSurveyResult}
            sx={{
              '&:hover': { opacity: 0.7 },
              padding: '4px', // Added to reduce button size
            }}
            size="small"
            disableRipple
            disableTouchRipple
          >
            <CloseRoundedIcon sx={{ fontSize: '1rem', fill: '#442424' }} />{' '}
            {/* Reduced icon size */}
          </IconButton>
        </Box>
      </Stack>
    </Paper>
  )
}
