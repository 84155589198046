import React, { useMemo, useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Divider, Paper, Skeleton, Stack, Typography, Avatar, Tooltip } from '@mui/material'
import Carousel from 'react-multi-carousel'
import { differenceInDays, parseISO } from 'date-fns'
import { RunningCampaignCardSkeleton } from './RunningCampaignCardSkeleton'
import { delayFunc, pretifyBigNum } from '../../utils'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { CreateCampaign } from '../sections/create-campaign/CreateCampaign'
import { useStep } from '../hooks/useStep'
import { canCreateCampaignsSelector, userStartupDataLoadingSelector } from '../../redux/selectors'
import { useSelector } from 'react-redux'
import useTranslation from '../../localization/useTranslation'
import { simulateResize } from '../pages/Search/utils'

const GradientNewCampaignWithIcon = () => (
  <>
    <svg width={0} height={0} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset="1.15%" stop-color="#FFB904" />
          <stop offset="30.19%" stop-color="#FF6B00" />
          <stop offset="57.11%" stop-color="#FF403B" />
          <stop offset="100%" stop-color="#FF3B99" />
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#customGradient)" />
    </svg>

    <AddCircleRoundedIcon sx={{ fill: 'url(#linearColors)', fontSize: '30px' }} />
  </>
)

const CampaignMetrics = ({ metrics }) => (
  <Stack spacing={0} sx={{ p: 1, px: 2 }}>
    {metrics?.map(metric => (
      <Stack direction="row" alignItems="center" spacing={0.5} key={metric.label}>
        <Typography
          sx={{
            fontSize: '11px',
            fontFamily: 'Inter',
            color: '#6B4545',
            lineHeight: '120%',
            fontWeight: 700,
          }}
        >
          {pretifyBigNum(metric.value)}
        </Typography>
        <Typography
          sx={{
            fontSize: '11px',
            fontFamily: 'Inter',
            color: '#6B4545',
            lineHeight: '120%',
          }}
        >
          {metric.label}
        </Typography>
      </Stack>
    ))}
  </Stack>
)

const staticStyles = {
  carouselArrow: {
    background: '#FAEBEB',
    boxShadow: `0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)`,
    '&:hover': {
      filter: 'brightness(0.95)',
      background: '#FAEBEB',
    },
    '&:before': {
      color: '#784E4E',
    },
  },
  campaignBox: {
    borderRadius: '18px',
    backgroundColor: 'rgba(255, 245, 245, 0.50)',
    width: '167px',
  },
  campaignTypography: {
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#6B4545',
    fontWeight: 700,
    p: 1,
    px: 2,
    backgroundColor: '#FFF',
    borderRadius: '18px 18px 0 0',
  },
}

export const RunningCampaigns = ({ campaignArray }) => {
  const containerRef = useRef(null)
  const history = useHistory()
  const { labelStrings } = useTranslation()

  const isUserStartUpDataLoading = useSelector(userStartupDataLoadingSelector)
  const canCreateCampaigns = useSelector(canCreateCampaignsSelector)

  const [isCreateCampaignOpen, setIsCreateCampaignOpen] = useState(false)

  const filteredCampaignArray = campaignArray?.length
    ? campaignArray.filter(
        x => !x.isDeleted && !x.isArchived && !x.setInactiveOn && x.previewMetrics
      )
    : []

  const [currentStep, helpers] = useStep(9)
  // @ts-ignore
  const { goToNextStep, goToPrevStep, setStep, reset } = helpers

  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth - 1)
      }
    }

    // Initial calculation (run immediately)
    handleResize()
    
    // Run again after a short delay to ensure DOM is fully rendered
    const timer = setTimeout(() => {
      handleResize()
    }, 100)
    
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    delayFunc(() => simulateResize(), 500)
  }, [])

  const today = useMemo(() => new Date(), [])
  const daysDifference = useMemo(() => currDate => differenceInDays(today, parseISO(currDate)), [
    today,
  ])

  const countSlideToShow = useMemo(
    () => {
      // Make sure to handle the case when width might still be 0 or undefined
      const width = containerWidth > 0 ? containerWidth : 
               (containerRef.current?.offsetWidth || window.innerWidth * 0.7);
      // Add a small buffer to avoid edge cases where items might overlap
      return Math.max(2, Math.floor((width - 30) / 180));
    },
    [containerWidth]
  )

  const showSkeletonSlides = useMemo(
    () => Array.from({ length: countSlideToShow }, (_, idx) => idx),
    [countSlideToShow]
  )

  const updatedArray = [
    ...filteredCampaignArray,
    { campaignId: 'new', campaignName: 'Create A New Campaign Now' },
  ]

  const onOpenCampaign = campaign => {
    const { dashboards, campaignId } = campaign || {}
    const dashboardId = dashboards[0]?.dashboardId

    history?.push(`/campaigns/${campaignId}/dashboard?dashboardId=${dashboardId}`)
  }

  const toggleIsCreateCampaignOpen = () => {
    if (!canCreateCampaigns) return
    setIsCreateCampaignOpen(!isCreateCampaignOpen)
  }

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          width: '100%',
          '& .react-multiple-carousel__arrow': staticStyles.carouselArrow,
          '& .react-multi-carousel-track': {
            pb: 1,
          },
          '& .react-multi-carousel-list': {
            overflow: 'visible',
          },
          pb: 3,
        }}
      >
        {isUserStartUpDataLoading ? (
          <Skeleton
            sx={{ backgroundColor: '#EBD0D0', mb: 1 }}
            width="260px"
            height="20px"
            variant="text"
          />
        ) : (
          <Typography
            sx={{ fontFamily: 'Inter', fontSize: '20px', color: '#442424', fontWeight: 700, mb: 1 }}
          >
            {labelStrings.yourRunningCampaigns}
          </Typography>
        )}
        {isUserStartUpDataLoading ? (
          <Stack direction="row" justifyContent="space-between">
            {showSkeletonSlides?.map(idx => (
              <div style={{ width: 'fit-content' }} key={idx}>
                <RunningCampaignCardSkeleton />
              </div>
            ))}
          </Stack>
        ) : (
          <Carousel
            arrows={!isUserStartUpDataLoading}
            renderButtonGroupOutside
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            showDots={false}
            slidesToSlide={countSlideToShow - 0.2}
            swipeable={!isUserStartUpDataLoading}
            responsive={{
              desktop: {
                breakpoint: { max: 5000, min: 1024 },
                items: countSlideToShow - 0.2,
                partialVisibilityGutter: 40,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: Math.min(4, countSlideToShow),
                partialVisibilityGutter: 20
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                partialVisibilityGutter: 20
              }
            }}
          >
            {updatedArray?.map(campaign => {
              if (campaign.campaignId === 'new') {
                return (
                  <Box
                    onClick={toggleIsCreateCampaignOpen}
                    sx={{
                      width: '167px',
                      height: '129px',
                      backgroundColor: '#FFF',
                      borderRadius: '18px',
                      p: 2,
                      boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                      cursor: 'pointer',
                      '&:hover': {
                        filter: 'brightness(0.95)',
                      },
                    }}
                  >
                    <Stack spacing={1} alignItems="center">
                      <GradientNewCampaignWithIcon />
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '14px',
                          color: '#6B4545',
                          textAlign: 'center',
                        }}
                      >
                        {labelStrings.createNewCampaign}
                      </Typography>
                    </Stack>
                  </Box>
                )
              }

              return (
                <Paper
                  sx={{
                    width: 'fit-content',
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255, 245, 245, 0.50)',
                    boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                    borderRadius: '18px',
                    '&:hover': {
                      opacity: 0.7,
                    },
                  }}
                  key={campaign?._id}
                  onClick={() => onOpenCampaign(campaign)}
                >
                  <Box sx={staticStyles.campaignBox}>
                    <Typography noWrap sx={{ ...staticStyles.campaignTypography, minHeight: '40px' }}>
                      {campaign?.campaignName}
                    </Typography>
                    {campaign?.creators && campaign.creators.length > 0 && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 1 }}>
                        <Stack 
                          direction="row" 
                          sx={{ 
                            maxWidth: '140px',
                            overflow: 'hidden' 
                          }}
                        >
                          {campaign.creators
                            // Sort creators to put brand accounts first
                            .sort((a, b) => {
                              if (b.isBrandAccount && !a.isBrandAccount) return 1;
                              if (!b.isBrandAccount && a.isBrandAccount) return -1;
                              return 0;
                            })
                            .slice(0, Math.min(5, campaign.creators.length))
                            .map((creator, idx) => (
                              <Tooltip key={creator.id || idx} title={creator.username || 'Creator'}>
                                <Avatar
                                  src={creator.profilePicURL}
                                  alt={creator.username || 'Creator'}
                                  sx={{
                                    width: 26,
                                    height: 26,
                                    marginLeft: idx > 0 ? '-8px' : 0,
                                    border: '1px solid #fff',
                                    fontSize: '0.75rem',
                                    zIndex: 10 - idx,
                                    bgcolor: creator.profilePicURL ? undefined : '#FF5C00'
                                  }}
                                >
                                  {!creator.profilePicURL && creator.username ? creator.username.charAt(0).toUpperCase() : ''}
                                </Avatar>
                              </Tooltip>
                          ))}
                          {campaign.creators.length > 5 && (
                            <Tooltip title={`${campaign.creators.length - 5} more creators`}>
                              <Avatar
                                sx={{
                                  width: 26,
                                  height: 26,
                                  marginLeft: '0px',
                                  border: '1px solid #fff',
                                  fontSize: '0.75rem',
                                  bgcolor: '#FF5C00',
                                  color: '#FFF',
                                  zIndex: 5,
                                  fontWeight: 'bold'
                                }}
                              >
                                +{campaign.creators.length - 5}
                              </Avatar>
                            </Tooltip>
                          )}
                        </Stack>
                      </Box>
                    )}
                    <Divider sx={{ borderColor: '#FFF1F1' }} />
                    <CampaignMetrics
                      metrics={[
                        {
                          label: 'Content Pieces',
                          value: campaign?.previewMetrics?.contentPieces,
                        },
                        {
                          label: 'Likes Received',
                          value: campaign?.previewMetrics?.likesReceived,
                        },
                        { label: 'Accounts Reached', value: campaign?.previewMetrics?.reach },
                      ]}
                    />
                    {campaign?.previewMetrics?.lastGenerated && (
                      <Typography
                        sx={{
                          color: '#6B4545',
                          fontFamily: 'Inter',
                          fontSize: '11px',
                          fontWeight: 400,
                          py: 1,
                          px: 2,
                        }}
                      >
                        {labelStrings.inLast7Days}
                      </Typography>
                    )}
                  </Box>
                </Paper>
              )
            })}
          </Carousel>
        )}
      </Box>
      {isCreateCampaignOpen && (
        <CreateCampaign
          openModal={isCreateCampaignOpen}
          setOpenModal={setIsCreateCampaignOpen}
          currentStep={currentStep}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
          setStep={setStep}
          resetStep={reset}
        />
      )}
    </>
  )
}
