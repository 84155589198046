import React, { useEffect, useState } from "react"
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material"

import { useLocalStorage } from "../../../hooks/useLocalStorage"
import useTranslation from "../../../localization/useTranslation"
import { useSelector } from "react-redux"
import { newsModalContentObjectSelector } from "../../../redux/selectors"
import Scrollbar from "../scrollbar/Scrollbar"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "900px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  outline: 'none'
}

export const ModalContentNews = () => {
  const { labelStrings, currentLang } = useTranslation()
  const { version, englishHeadline, englishText, germanHeadline, germanText } =
    useSelector(newsModalContentObjectSelector) || {}

  const isSmallScreen = useMediaQuery('(max-height:680px)')

  const [
    newsContentModalVersionFromLocalStorage,
    setNewsContentModalVersionFromLocalStorage,
  ] = useLocalStorage("newsContentModalVersion", "")
  const [
    isShowNewsContentModalFromLocalStorage,
    setIsShowNewsContentModalFromLocalStorage,
  ] = useLocalStorage("isOpenNewsContentModal", "")

  const [openNewsContentModal, setOpenNewsContentModal] = useState(false)

  const handleOpenNewsContentModal = () => setOpenNewsContentModal(true)
  const handleCloseNewsContentModal = () => {
    setOpenNewsContentModal(false)
    setIsShowNewsContentModalFromLocalStorage(false)
  }

  useEffect(() => {
    if (version) {
      if (isShowNewsContentModalFromLocalStorage) {
        handleOpenNewsContentModal()
      }

      if (version !== newsContentModalVersionFromLocalStorage) {
        setNewsContentModalVersionFromLocalStorage(version)
        setIsShowNewsContentModalFromLocalStorage(true)

        if (!openNewsContentModal) {
          handleOpenNewsContentModal()
        }
      }
    }
  }, [
    version,
    newsContentModalVersionFromLocalStorage,
    isShowNewsContentModalFromLocalStorage,
  ])

  return (
    <Modal
      keepMounted
      open={openNewsContentModal}
      onClose={handleCloseNewsContentModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        "&:focus": {
          outline: "none",
        },
        outline: 'none'
      }}
    >
      <Fade in={openNewsContentModal}>
        <Box sx={style}>
          <Stack alignItems='center'>
            <Typography
              sx={{
                fontSize: "24px",
                textAlign: "center",
                mb: 4,
              }}
            >
              {currentLang === "de" ? germanHeadline : englishHeadline}
            </Typography>
            <Scrollbar sx={{ width: "100%", maxHeight: isSmallScreen ? '250px' : '400px', p: 1 }}>
              <Box
                sx={{
                  '& ul': {
                    pl: 3,
                    my: 1,
                  },
                  '& li': {
                    display: 'list-item',
                    listStyleType: 'disc',
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: currentLang === "de" ? germanText : englishText,
                }}
              />
            </Scrollbar>
            <Button
              onClick={handleCloseNewsContentModal}
              sx={{ minWidth: "150px", marginTop: "15px" }}
              variant='outlined'
            >
              {labelStrings.close}
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}
