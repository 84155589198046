import React, { useEffect, useMemo, useState } from 'react'
import { TableRow, TableCell, Collapse, Box } from '@mui/material'
import { QueryMatchedContentCard } from './QueryMatchedContentCard'
import Carousel from 'react-multi-carousel'
import { useSelector } from 'react-redux'
import { searchLoadingStatusSelector } from '../../../../redux/selectors'
import { QueryMatchedContentSkeleton } from './QueryMatchedContentSkeleton'

const CONTENT_TYPE_HEIGHTS = {
  reel: 280,
  story: 290,
  post: 272,
  default: 160,
}
const OTHER_HEIGHTS = 61
const OFFSET = 15

export const QueryMatchedContent = ({
  openQueryMatchedContent,
  tableWidth,
  initialTableWidth,
  queryMatchedContent,
}) => {
  const isSearchLoading = useSelector(searchLoadingStatusSelector)
  const slideWidth = tableWidth || initialTableWidth

  const [isShowSkeleton, setIsShowSkeleton] = useState(true)

  const countSlideToShow = useMemo(() => Math.floor(slideWidth / 200) || 1, [
    slideWidth,
    isSearchLoading,
  ])

  const showSkeletonSlides = Array.from(Array(countSlideToShow + 1).keys())

  const calculateHeight = content => {
    const contentHeight = CONTENT_TYPE_HEIGHTS[content.contentType] || CONTENT_TYPE_HEIGHTS.default
    const foundCaptionsHeight =
      content?.foundCaptions?.length > 260
        ? content?.foundCaptions?.substring(0, 265)?.length / 1.5
        : content?.foundCaptions?.length / 1.5

    return Math.floor(contentHeight + OTHER_HEIGHTS + foundCaptionsHeight + OFFSET)
  }

  const heightCardsArray = useMemo(() => queryMatchedContent?.map(calculateHeight), [
    queryMatchedContent,
  ])
  const maxHeight =
    heightCardsArray?.length && openQueryMatchedContent ? Math.max(...heightCardsArray) : 0

  useEffect(() => {
    if (openQueryMatchedContent) {
      const timeoutId = setTimeout(() => setIsShowSkeleton(false), 1000)
      return () => clearTimeout(timeoutId)
    }
  }, [openQueryMatchedContent])

  return (
    <TableRow
      sx={{ borderBottom: '1px solid #FFF5F5', position: 'relative', transform: `translateZ(0)` }}
    >
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse
          easing={{ enter: 'cubic-bezier(0, 1.5, .8, 1)', exit: 'linear' }}
          in={openQueryMatchedContent}
          timeout={{ enter: '0.8s', exit: '0.3s' }}
          unmountOnExit
          keepMounted
          sx={{ height: `${isShowSkeleton ? 444 : maxHeight + 32}px !important` }}
        >
          <Box
            sx={{
              transition: 'backdrop-filter 1s ease-out',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 99998,
              px: 1.5,
              my: 2,
              '& .react-multiple-carousel__arrow': {
                background: 'rgba(255, 245, 245, 0.8)',
                '&:hover': {
                  background: 'rgba(255, 245, 245, 0.8)',
                  opacity: 0.7,
                },
                '&:before': {
                  color: '#784E4E',
                },
              },
              '& .react-multiple-carousel__arrow--left': {
                left: '0px !important',
              },
              '& .react-multiple-carousel__arrow--right': {
                right: '0px !important',
              },
              '& .react-multi-carousel-track': {
                px: 5,
              },
              '& .react-multi-carousel-item': {
                maxWidth: '200px',
              },
            }}
          >
            <Carousel
              arrows={isShowSkeleton ? false : true}
              renderButtonGroupOutside
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              showDots={false}
              slidesToSlide={countSlideToShow - 1}
              swipeable={isShowSkeleton ? false : true}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 8000,
                    min: 1024,
                  },
                  items: countSlideToShow - 1,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 4,
                  partialVisibilityGutter: 30,
                },
              }}
            >
              {isShowSkeleton
                ? showSkeletonSlides?.map((el, idx) => (
                    <div style={{ width: 'fit-content' }} key={idx}>
                      <QueryMatchedContentSkeleton />
                    </div>
                  ))
                : queryMatchedContent?.map(slideData => (
                    <div style={{ width: 'fit-content' }} key={slideData?.contentId}>
                      <QueryMatchedContentCard data={slideData} />
                    </div>
                  ))}
            </Carousel>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
