import React from 'react'
import { Stack, Skeleton, Box } from '@mui/material'

export const RunningCampaignCardSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#FFF5F5 !important',
        borderRadius: '13px',
        width: '168px',
        p: 1,
      }}
    >
      <Stack alignItems="center" spacing={0}>
        <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="100%" variant="text" />

        <Stack sx={{ width: '100%', height: 'auto' }} spacing={0}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="30px" variant="text" />
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="100%" variant="text" />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="30px" variant="text" />
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="100%" variant="text" />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="30px" variant="text" />
            <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="100%" variant="text" />
          </Stack>
          <Skeleton sx={{ backgroundColor: '#EBD0D0' }} width="100%" variant="text" />
        </Stack>
      </Stack>
    </Box>
  )
}
