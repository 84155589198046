import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, Box, IconButton, Menu, MenuItem, Portal } from '@mui/material'
import Chart, { useChart } from '../index'
import { pretifyBigNum } from '../../../../utils'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import { TooltipComponent } from '../../tooltip/TooltipComponent'
import html2canvas from 'html2canvas'
import pptxgen from 'pptxgenjs'
import { fShortenNumber } from '../../../utils/formatNumber'
import { ColumnAreaChartExport } from './Export/ColumnAreaChartExport'

export const ColumnAreaChart = ({ title, subheader, chart, helperText = '', ...other }) => {
  const { labels, colors, series, options, isData } = chart
  const chartRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const exportElement = chartRef.current

    const cardImage = await html2canvas(exportElement, { scale: 2 })
    const cardDataUrl = cardImage.toDataURL('image/jpeg', 1.0)

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const cardAspectRatio = exportElement.offsetWidth / exportElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / cardAspectRatio

    slide.addImage({
      data: cardDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: slideHeight,
      type: 'contain',
    })
    pptx.writeFile('Chart.pptx').then(() => {
      handleDownloadClose()
    })
  }

  const exportToImage = async () => {
    const cardElement = chartRef.current

    const options = {
      scale: 2,
      useCORS: true,
      logging: true,
    }

    const cardImage = await html2canvas(cardElement, options)
    const cardDataUrl = cardImage.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = cardDataUrl
    link.download = 'Chart.png'
    link.click()
    handleDownloadClose()
  }

  const chartOptions = useChart({
    chart: {
      type: 'line',
      stacked: false,
    },
    colors,
    plotOptions: {
      bar: {
        columnWidth: '20%',
        borderRadius: 2,
      },
    },
    fill: {
      type: series?.map(i => i.fill),
    },
    stroke: {
      curve: 'smooth',
    },
    legend: {
      offsetY: -2,
      horizontalAlign: 'start',
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    labels,
    xaxis: {
      type: 'datetime',
      labels: {
        show: !isData?.length ? false : true,
        format: 'yyyy/MM/dd',
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        labels: {
          show: !isData?.length ? false : true,
          formatter: function(value) {
            return pretifyBigNum(value)
          },
        },
        title: {
          text: isData?.length ? 'Reach' : '',
          offsetY: -156,
          offsetX: 25,
          rotate: 0,
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value, obj, t) => {
          if (typeof value !== 'undefined') {
            return fShortenNumber(value)
          }
          return value
        },
      },
    },
    ...options,
  })

  return (
    <>
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <>
              {helperText?.length > 0 && (
                <IconButton aria-label="Helper-Text">
                  <TooltipComponent text={helperText}>
                    <InfoOutlinedIcon />
                  </TooltipComponent>
                </IconButton>
              )}
              <IconButton aria-label="Download" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
                <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
                <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
              </Menu>
            </>
          }
        />

        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <Chart type="line" series={series} options={chartOptions} height={364} width={'100%'} />
        </Box>
      </Card>
      <Portal>
        <Box
          ref={chartRef}
          sx={{ position: `fixed`, top: `-9999px`, left: `-9999px`, zIndex: `-9999` }}
        >
          <ColumnAreaChartExport title={title} subheader={subheader} chart={chart} other={other} />
        </Box>
      </Portal>
    </>
  )
}

ColumnAreaChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  helperText: PropTypes.string,
}
