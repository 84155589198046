import httpService from '../../services/httpService'
import { ERROR_MSG } from '../../constants/errorMessages'
import { FAV_COLLECTION_NAME } from '../../constants/collections'

import { changeProfileAction } from './profileSelectedDucks'
import {
  changeUserDataInOpenedCollection,
  setOpenedCollection,
  setCollectionsList,
  getAllCollections,
} from './collectionsDucks'
import {
  changeUsersInOpenedCampaign,
  setAllCampaigns,
  setCurrentOpenedCampaign,
} from './campaignsDucks'
import { updateSearchUsersList } from './searchDucks'
import { setError } from './errorDucks'
import {
  userCollectionsAllDataSelector,
  searchResultsUsersSelector,
  selectedProfileDetailsSelector,
  openedCollectionSelector,
  currentOpenedCampaignSelector,
  allCampaignsSelector,
  usernameSelector,
} from '../selectors'

import {
  changeProfileInProfilesList,
  generateRandomId,
  isProfileExistsInProfilesList,
} from '../../utils'

export const setSelectedUsersCommonAction = ({
  nextAction = null,
  users,
  userId = null,
  selectAll = true,
}) => dispatch => {
  // debugger
  const { userdata, metadata } = users
  const updatedUsers = {
    userdata: userdata.map(user => {
      if (userId) {
        return userId === user._id ? { ...user, isSelected: !user.isSelected } : user
      } else {
        return { ...user, isSelected: selectAll }
      }
    }),
    metadata: metadata,
  }
  if (nextAction) dispatch(nextAction(updatedUsers))
  return updatedUsers
}

// changes profile in opened collection, opened campaign, search list, selected profile
export const modifyProfileElementEverywhere = ({
  profileId = '',
  changeField = '',
  newValue = '',
}) => async (dispatch, getState) => {
  if (!profileId || !changeField) return
  try {
    // change profile on server
    await httpService.changeProfileElement({ objectId: profileId, changeField, newValue })

    // modify selected profile
    const currentSelectedProfile = selectedProfileDetailsSelector(getState())

    if (currentSelectedProfile && currentSelectedProfile._id === profileId) {
      dispatch(changeProfileAction({ [changeField]: newValue }))
    }
    // modify profile in collectionsList
    const openedCollection = openedCollectionSelector(getState())
    if (openedCollection.id) {
      const { userdata } = openedCollection.users
      const isProfileInOpenedCollection = isProfileExistsInProfilesList(profileId, userdata)
      if (isProfileInOpenedCollection) {
        const updatedUsers = changeProfileInProfilesList({
          profileId,
          changeField,
          newValue,
          profilesList: userdata,
        })
        dispatch(changeUserDataInOpenedCollection(updatedUsers))
      }
    }

    // HANDLE CAMPAIGNS ONLY FOR CNANGING COMMENTS, BECAUSE CAMPAIGN USERS DATA SHAPE DIFFERS FROM OTHER USERS
    if (changeField === 'comment') {
      // modify profile on opened campaign list
      const openedCampaign = currentOpenedCampaignSelector(getState())
      if (openedCampaign.campaignId && !!openedCampaign.users.length) {
        const { users } = openedCampaign
        const isProfileInOpenedCampaign = isProfileExistsInProfilesList(profileId, users)
        if (isProfileInOpenedCampaign) {
          const updatedUsers = changeProfileInProfilesList({
            profileId,
            changeField,
            newValue,
            profilesList: users,
          })
          dispatch(changeUsersInOpenedCampaign(updatedUsers))
        }
      }
    }

    // modify profile in search list
    const searchUsers = searchResultsUsersSelector(getState())
    if (searchUsers.length) {
      const isProfileInSearchResults = isProfileExistsInProfilesList(profileId, searchUsers)
      if (isProfileInSearchResults) {
        const updatedSearchUsers = changeProfileInProfilesList({
          profileId,
          changeField,
          newValue,
          profilesList: searchUsers,
        })
        dispatch(updateSearchUsersList(updatedSearchUsers))
      }
    }
  } catch (err) {
    dispatch(setError(ERROR_MSG.failUser))
  }
}

// adds/removes users to/from collections around all app
export const modifyElementsInCollectionsEverywhere = (
  collectionId,
  objectId,
  usersToAdd = [],
  contentId
) => async (dispatch, getState) => {
  const usersToAddArr = Array.isArray(usersToAdd) ? usersToAdd : !usersToAdd ? [] :[usersToAdd]
  const objectIds = typeof objectId === 'string' ? [objectId] : objectId
  const contentIds = typeof contentId === 'string' ? [contentId] : contentId

  const payloadIds = contentIds ? contentIds : objectIds

  const isDeleteElements = !usersToAddArr?.length

  // add/remove to/from opened collection
  const { openedCollection, collectionsList } = userCollectionsAllDataSelector(getState())
  const { id: openCollectionId, users } = openedCollection
  const { userdata, metadata } = users
  if (collectionId === openCollectionId) {
    const updatedUserdata = isDeleteElements
      ? userdata?.filter(u => !payloadIds?.includes(u._id))
      : [
          ...(openCollectionId?.startsWith(FAV_COLLECTION_NAME)
            ? usersToAddArr?.map(u => ({ ...u, isFav: true }))
            : usersToAddArr),
          ...userdata,
        ]
    dispatch(
      setOpenedCollection({
        collectionId: openCollectionId,
        users: {
          userdata: updatedUserdata,
          metadata,
        },
      })
    )
  } else if (collectionId?.startsWith(FAV_COLLECTION_NAME)) {
    dispatch(
      setOpenedCollection({
        collectionId: openCollectionId,
        users: {
          userdata: userdata?.map(u =>
            payloadIds.includes(u._id) ? { ...u, isFav: isDeleteElements ? false : true } : u
          ),
          metadata,
        },
      })
    )
  }

  // add/remove to/from collections list
  if (isDeleteElements) {
    dispatch(
      setCollectionsList(
        collectionsList?.map(c =>
          c.collectionId === collectionId
            ? {
                ...c,
                count: c.count - payloadIds.length,
              }
            : c
        )
      )
    )
  } else {
    // FOR 1 ITEM
    if (usersToAddArr.length === 1) {
      dispatch(
        setCollectionsList(
          collectionsList?.map(c =>
            c.collectionId === collectionId ? { ...c, count: c.count + payloadIds.length } : c
          )
        )
      )
    } else {
      /*
        FOR MORE THAN 2 ITEMS => if we copy/move items - we use api call to get latest counts from all collections
        this is used to avoid situation of adding duplicate users to collections. in this case BE handles it correctly,
        but since we don't have full data of all collections at the same time, we need to fetch collections data to make sure 
        we didn't made mistake in increasing counter
          e.g. we add 2 users to collection, which already has one of them. In this case only one user will be added at BE,
          but since we don't know, that user is already in collection, we will increase counter by 2, and here we go - incorrect value
      */
      await dispatch(getAllCollections())
    }
  }

  // add/remove to/from search
  const searchUsersList = searchResultsUsersSelector(getState())
  dispatch(
    updateSearchUsersList(
      searchUsersList?.map(u => {
        if (payloadIds?.includes(u._id)) {
          if (collectionId?.startsWith(FAV_COLLECTION_NAME)) u.isFav = isDeleteElements ? false : true
          if (isDeleteElements) {
            u.collectionCount--
            u.collectionArray = u.collectionArray?.filter(cId => cId !== collectionId)
          } else {
            u.collectionCount++
            u.collectionArray = [...u.collectionArray, collectionId]
          }
        }
        return u
      })
    )
  )

  //add/remove to/from selected profile
  const selectedProfile = selectedProfileDetailsSelector(getState())
  if (selectedProfile && payloadIds?.includes(selectedProfile._id)) {
    const updatedProfile = { ...selectedProfile }
    if (collectionId?.startsWith(FAV_COLLECTION_NAME)) updatedProfile.isFav = isDeleteElements ? false : true
    if (isDeleteElements) {
      updatedProfile.collectionCount--
      updatedProfile.collectionArray = updatedProfile.collectionArray?.filter(
        cId => cId !== collectionId
      )
    } else {
      updatedProfile.collectionCount++
      updatedProfile.collectionArray = [...updatedProfile.collectionArray, collectionId]
    }

    dispatch(changeProfileAction({ ...updatedProfile }))
  }

  return true
}

export const modifyElementsInCampaignsEverywhere = ({
  campaignId,
  objectIds = [],
  isDelete = false,
}) => (dispatch, getState) => {
  // add/remove elements to/from campaigns list
  const allCampaigns = allCampaignsSelector(getState())
  const updatedCampaigns = { ...allCampaigns }
  if (isDelete) {
    updatedCampaigns.trackNow =
      allCampaigns.trackNow - objectIds.length < 0 ? 0 : allCampaigns.trackNow - objectIds.length
    updatedCampaigns.campaignArray = allCampaigns.campaignArray.map(c => {
      const newCount = c.campaignId === campaignId ? c.count - objectIds.length : c.count
      return {
        ...c,
        count: newCount < 0 ? 0 : newCount,
      }
    })
  } else {
    updatedCampaigns.trackNow = allCampaigns.trackNow + objectIds.length
    updatedCampaigns.campaignArray = allCampaigns.campaignArray.map(c => {
      return {
        ...c,
        count: c.campaignId === campaignId ? c.count + objectIds.length : c.count,
      }
    })
  }
  dispatch(setAllCampaigns({ ...updatedCampaigns }))

  // remove from current opened campaign (when add, the fetch of latest campaign data is used in campaignsDucks)
  if (isDelete) {
    const currentCampaign = currentOpenedCampaignSelector(getState())
    const updatedCurrentCampaign = {
      ...currentCampaign,
      count:
        currentCampaign.count - objectIds.length < 0 ? 0 : currentCampaign.count - objectIds.length,
      users: currentCampaign.users.filter(user => {
        return !objectIds.includes(user._id)
      }),
    }
    dispatch(setCurrentOpenedCampaign({ ...updatedCurrentCampaign }))
  }

  // add/remove to/from profile selected campaignArray
  const selectedProfile = selectedProfileDetailsSelector(getState())
  if (selectedProfile && objectIds.includes(selectedProfile._id)) {
    const updatedProfile = { ...selectedProfile }
    if (isDelete) {
      const newCount = updatedProfile.campaignCount - 1
      updatedProfile.campaignCount = newCount < 0 ? 0 : newCount
      updatedProfile.campaignArray = updatedProfile.campaignArray.filter(id => id !== campaignId)
    } else {
      updatedProfile.campaignCount += 1
      updatedProfile.campaignArray = [...updatedProfile.campaignArray, campaignId]
    }
    dispatch(changeProfileAction({ ...updatedProfile }))
  }

  // add/remove to/from opened collection users campaignArray
  const { openedCollection } = userCollectionsAllDataSelector(getState())
  if (openedCollection.id && !!openedCollection.users.userdata.length) {
    dispatch(
      setOpenedCollection({
        collectionId: openedCollection.id,
        users: {
          userdata: openedCollection.users.userdata.map(u => {
            if (objectIds.includes(u._id)) {
              if (isDelete) {
                const newCount = u.campaignCount - 1
                u.campaignCount = newCount < 0 ? 0 : newCount
                u.campaignArray = u.campaignArray.filter(cId => cId !== campaignId)
              } else {
                u.campaignCount += 1
                u.campaignArray = [...u.campaignArray, campaignId]
              }
            }
            return u
          }),
          metadata: openedCollection.users.metadata,
        },
      })
    )
  }
  // TODO: add campaigns modify for search, if it will be needed in future
}

export const notifyWhenAudienceAnalysisAvailable = ({
  influencerName,
  influencerId,
  platform,
  notify = true
}) => async () => {
  await httpService.notifyWhenAudienceAnalysisAvailable({
    username: influencerName,
    userid: influencerId,
    platform,
    notify
  })
}

export const changeProfileAdditionalFields = newValue => (dispatch, getState) => {
  const selectedProfile = selectedProfileDetailsSelector(getState())
  const { additionalFields } = selectedProfile ?? { additionalFields: [] }

  const hasAdditionalFieldValue = additionalFields?.some(field => field.code === newValue.code)
  let updatedAdditionalsField

  if (hasAdditionalFieldValue) {
    updatedAdditionalsField = additionalFields?.map(field => {
      if (field.code === newValue.code) {
        return { ...field, value: newValue.value }
      }
      return field
    })
  } else {
    updatedAdditionalsField = [
      ...additionalFields,
      {
        code: newValue.code,
        value: newValue.value,
      },
    ]
  }

  return dispatch(changeProfileAction({ additionalFields: updatedAdditionalsField }))
}

export const addProfileComment = commentValue => async (dispatch, getState) => {
  const username = usernameSelector(getState())
  const { _id: profileId, commentThread = [] } = selectedProfileDetailsSelector(getState())
  const newComment = {
    code: generateRandomId(),
    user: username,
    comment: commentValue,
    isPinned: false,
    linkToResources: null,
    timeStamp: new Date(),
  }
  let isSuccessful

  try {
    await httpService.changeProfileElement({
      objectId: profileId,
      changeField: 'commentThread',
      newValue: newComment,
    })
    isSuccessful = true
    dispatch(
      changeProfileAction({
        commentThread: [...(!!commentThread ? commentThread : []), newComment],
      })
    )
  } catch (err) {
    isSuccessful = false
    dispatch(setError(ERROR_MSG.failedToAddNote))
  }

  return isSuccessful
}

export const editProfileComment = editedCommentData => async (dispatch, getState) => {
  const { _id: profileId, commentThread = [] } = selectedProfileDetailsSelector(getState())

  try {
    await httpService.changeProfileElement({
      objectId: profileId,
      changeField: 'commentThread',
      newValue: editedCommentData,
    })
    dispatch(
      changeProfileAction({
        commentThread: commentThread.map(commentData =>
          commentData.code === editedCommentData.code ? editedCommentData : commentData
        ),
      })
    )
  } catch (err) {
    dispatch(setError(ERROR_MSG.failedToEditNote))
  }
}

export const deleteProfileComment = commentCode => async (dispatch, getState) => {
  const { _id: profileId, commentThread = [] } = selectedProfileDetailsSelector(getState())

  try {
    await httpService.changeProfileElement({
      objectId: profileId,
      changeField: 'commentThread',
      newValue: {
        code: commentCode,
        deleteComment: true,
      },
    })
    dispatch(
      changeProfileAction({
        commentThread: commentThread.filter(commentData => commentData.code !== commentCode),
      })
    )
  } catch (err) {
    dispatch(setError(ERROR_MSG.failedToDeleteNote))
  }
}

export const changeCampaignLimitTrackNow = ({ type }) => async (dispatch, getState) => {
  const { trackNow, ...other } = allCampaignsSelector(getState())
  dispatch(
    setAllCampaigns({
      ...other,
      trackNow: type === 'incr' ? trackNow + 1 : trackNow - 1,
    })
  )
}