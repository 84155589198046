import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { alpha, useTheme } from '@mui/material/styles'
import { Box, Card, Typography, IconButton, Menu, MenuItem, Portal } from '@mui/material'
import { bgGradient } from '../../utils/cssStyles'
import { fPercent, fShortenNumber } from '../../utils/formatNumber'
import { Icon } from '@iconify/react'
import DownloadIcon from '@mui/icons-material/Download'
import html2canvas from 'html2canvas'
import pptxgen from 'pptxgenjs'
import { BigCardWidgetExport } from './export/BigCardWidgetExport'

export const BigCardWidget = ({
  title,
  subtitle,
  total,
  totalPercentage,
  subTotal,
  icon,
  color = 'success',
  metricName,
  sx = {},
  compareDifference,
  compareRate,
  ...other
}) => {
  const theme = useTheme()
  const exportRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const cardElement = exportRef.current

    const cardImage = await html2canvas(cardElement, { scale: 2 })
    const cardDataUrl = cardImage.toDataURL('image/jpeg', 1.0)

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const cardAspectRatio = cardElement.offsetWidth / cardElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / cardAspectRatio

    slide.addImage({
      data: cardDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: slideHeight,
      type: 'contain',
    })

    pptx.writeFile('BigCardWidget.pptx').then(() => {
      handleDownloadClose()
    })
  }

  const exportToImage = async () => {
    const cardElement = exportRef.current
    const options = {
      scale: 2,
      useCORS: true,
      logging: true,
    }

    const cardImage = await html2canvas(cardElement, options)
    const cardDataUrl = cardImage.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = cardDataUrl
    link.download = 'BigCardWidget.png'
    link.click()
    handleDownloadClose()
  }

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: 2,
          boxShadow: 0,
          textAlign: 'center',
          color: color === 'success' ? 'black' : theme.palette[color].darker,
          bgcolor: '#fff', //theme.palette[color].lighter,
          ...sx,
          minHeight: '232px',
          position: 'relative', // Add relative positioning
        }}
        {...other}
      >
        <IconButton
          aria-label="Download"
          onClick={handleDownloadClick}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            transform: 'scale(0.8)',
          }}
        >
          <DownloadIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
          <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
          <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
        </Menu>

        <Box
          sx={{
            mb: 1,
            p: 2,
            width: 64,
            height: 64,
            borderRadius: '50%',
            color: color === 'success' ? 'black' : theme.palette[color].dark,
            ...bgGradient({
              direction: '135deg',
              startColor: `${alpha(theme.palette[color].dark, 0)} 0%`,
              endColor: `${alpha(theme.palette[color].dark, 0.24)} 100%`,
            }),
            margin: '0 auto',
          }}
        >
          {<Icon icon={icon} fontSize={26} />}
        </Box>

        {totalPercentage ? (
          <Typography variant="h4" sx={{ mt: 1, lineHeight: subTotal ? '24px' : 'inherit' }}>
            {totalPercentage ? fPercent(totalPercentage) : fShortenNumber(total)}
          </Typography>
        ) : (
          <Typography variant="h4" sx={{ mt: 1, lineHeight: subTotal ? '24px' : 'inherit' }}>
            {fShortenNumber(total)}
          </Typography>
        )}

        {subTotal && (
          <Typography variant="h6">
            {typeof subTotal === 'string' ? subTotal : fShortenNumber(subTotal)}
          </Typography>
        )}

        {metricName && (
          <Typography variant="h6" sx={{ lineHeight: '20px' }}>
            {metricName}
          </Typography>
        )}

        <Typography variant="subtitle2" sx={{ opacity: 0.64, px: 1, lineHeight: '20px' }}>
          {title}
        </Typography>

        {subtitle && (
          <Typography
            variant="subtitle2"
            sx={{ opacity: 0.8, px: 1, lineHeight: '16px', fontSize: '14px' }}
          >
            {subtitle}
          </Typography>
        )}

        <div style={{ flexGrow: 1 }} />

        {compareRate && compareDifference && (
          <Typography
            variant="subtitle2"
            sx={{
              opacity: 0.8,
              px: 1,
              lineHeight: '16px',
              fontSize: '12px',
              color: compareRate < 0 || compareDifference < 0 ? 'red' : 'inherit',
              mt: 2,
            }}
          >
            {`${
              compareRate >= 0 ? `+${Math.floor(compareRate * 100)}` : Math.floor(compareRate * 100)
            }% (${
              compareDifference >= 0
                ? `+${fShortenNumber(compareDifference)}`
                : fShortenNumber(compareDifference)
            })`}
          </Typography>
        )}
      </Card>
      <Portal>
        <Box
          ref={exportRef}
          sx={{ position: `fixed`, top: `-9999px`, left: `-9999px`, zIndex: `-9999` }}
        >
          <BigCardWidgetExport
            title={title}
            subtitle={subtitle}
            total={total}
            totalPercentage={totalPercentage}
            subTotal={subTotal}
            icon={icon}
            color="success"
            metricName={metricName}
            sx={sx}
            compareDifference={compareDifference}
            compareRate={compareRate}
            other={other}
          />
        </Box>
      </Portal>
    </>
  )
}

BigCardWidget.propTypes = {
  sx: PropTypes.object,
  icon: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  total: PropTypes.number,
  totalPercentage: PropTypes.number,
  subTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  metricName: PropTypes.string,
}
