import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Box, CardHeader, styled } from '@mui/material'
import Carousel, { CarouselArrows } from '../index'
import { PublishedItem } from './PublishedItem'

const StyledWrapper = styled(Box)({
  paddingTop: 3,
  '.slick-track': {
    marginLeft: '0 !important',
  },
})

export const PublishedContentCarousel = props => {
  const {
    setEditItem,
    openModal,
    isNotWidget = false,
    carouselSettings = {},
    title = '',
    subheader = '',
    list = [],
    sx = {},
    isDataEnrichment = false,
    setCurrentCreatorsData,
    ...other
  } = props || {}

  const [currSlide, setCurrSlide] = useState(0)

  const config = {
    ...carouselSettings,
    afterChange: currentSlide => {
      setCurrSlide(currentSlide)
    },
  }

  const carouselRef = useRef(null)

  const handlePrev = () => {
    carouselRef.current?.slickPrev()
  }

  const handleNext = () => {
    if (currSlide === list?.length / config.rows - config.slidesToShow) return
    carouselRef.current?.slickNext()
  }

  return (
    <StyledWrapper
      id="carousel-wrapper"
      sx={{ paddingBottom: isNotWidget ? 1 : 3, ...sx }}
      {...other}
    >
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <CarouselArrows
            onNext={handleNext}
            onPrevious={handlePrev}
            disabledPrev={currSlide === 0}
            disabledNext={currSlide === list?.length / config.rows - config.slidesToShow}
          />
        }
        sx={{
          p: 0,
          mb: 3,
          '& .MuiCardHeader-action': {
            alignSelf: isDataEnrichment ? 'top' : 'center',
            marginRight: '50px',
          },
        }}
      />

      <Carousel ref={carouselRef} {...config}>
        {list?.map(item => (
          <PublishedItem
            key={item.id}
            item={item}
            isNotWidget={isNotWidget}
            openModal={openModal}
            setEditItem={setEditItem}
            isDataEnrichment={isDataEnrichment}
            setCurrentCreatorsData={setCurrentCreatorsData}
          />
        ))}
      </Carousel>
    </StyledWrapper>
  )
}

PublishedContentCarousel.propTypes = {
  sx: PropTypes.object,
  list: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subheader: PropTypes.string,
  carouselSettings: PropTypes.object,
  isNotWidget: PropTypes.bool,
  openModal: PropTypes.func,
  setEditItem: PropTypes.func,
  isDataEnrichment: PropTypes.bool,
  setCurrentCreatorsData: PropTypes.func,
}
