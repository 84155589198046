import React, { useEffect, useState } from 'react'
import { Stack, Grow } from '@mui/material'
import { SearchSmartBar } from './SearchSmartBar'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  searchDataSelector,
  searchLoadingStatusSelector,
  searchSuggestionsSelector,
} from '../../../../redux/selectors'
import {
  changeSuggestionsData,
  changeTempBrandMentionsData,
  getSearchBySmartText,
  resetSearchResults,
  updateSearch,
} from '../../../../redux/ducks/searchDucks'
import {
  defaultSearchQuery,
  getValidValues,
  splitMultipleValues,
  transformObjectToArray,
} from '../utils'
import { searchContentTypes } from '../../../../constants/search'
import { SearchChip } from './SearchChip'
import { ChipSkeletonLoader } from './ChipSkeletonLoader'
import { changeAppSocialPlatform } from '../../../../redux/ducks/appSettingsDucks'
import { cancelAllFetches } from '../../../components/search-sidebar/searchSidebar/searchbarTipsFetches'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import { BuildLookalikeBasedOnBrandAccount } from './BuildLookalikeBasedOnBrandAccount'

const _BRANDS_INDEXES = {
  [SOCIAL_PLATFORMS_NAMES.instagram.name]: 'BRAND_INSTAGRAM',
  [SOCIAL_PLATFORMS_NAMES.tiktok.name]: 'BRAND_TIKTOK',
  [SOCIAL_PLATFORMS_NAMES.youtube.name]: 'BRAND_YOUTUBE',
}

export const SearchSmartBarContainer = ({
  searchType,
  setCurrentOpenSearchParam,
  searchUsers,
  currentPlatform,
  currentQuery,
  clearSearchMain,
  isSearchQueryTheSame,
  isLookalike,
  lookalikeId,
}) => {
  const dispatch = useDispatch()
  const { data, isLoading } = useSelector(searchSuggestionsSelector, shallowEqual)
  const isSearchLoading = useSelector(searchLoadingStatusSelector)
  const { tempBrandMentionsData } = useSelector(searchDataSelector) || {}

  const [openModalLookalikeAi, setOpenModalLookalikeAi] = useState(false)
  
  const isInitial =
    isSearchQueryTheSame &&
    ((data && !Object.keys(data)?.length) || data?.action === null || data?.action === 'error')

  const [inputValue, setInputValue] = useState(currentQuery?.promptSuggestion)

  useEffect(() => {
    if (!inputValue?.length && currentQuery?.promptSuggestion) {
      const promptQueryValue = decodeURIComponent(currentQuery?.promptSuggestion)
      setInputValue(promptQueryValue)
    }
  }, [currentQuery])

  useEffect(() => {
    if (inputValue?.length && !currentQuery?.promptSuggestion) {
      setInputValue('')
    }
  }, [searchType])

  const onGetSearchBySmartText = inputText => {
    if (!!inputText?.trim()?.length || !!inputValue?.trim()?.length) {
      cancelAllFetches()
      // @ts-ignore
      dispatch([resetSearchResults(), changeSuggestionsData({})])
      localStorage?.removeItem('searchQuery')

      // @ts-ignore
      dispatch([
        // @ts-ignore
        getSearchBySmartText({
          inputParam: inputText || inputValue || '',
          platform:
            searchType === searchContentTypes.BRAND
              ? _BRANDS_INDEXES[currentPlatform]
              : currentPlatform,
        }),
      ])
    }
  }

  const currentSearchQueryParams = getValidValues(currentQuery)
  const toArrayQueryParams = transformObjectToArray(currentSearchQueryParams)
  const suggestionsData = !!data.suggestions?.length
    ? !data?.action
      ? data.suggestions
      : splitMultipleValues(data.suggestions)
    : []

  const hasQueryParams = Array.isArray(toArrayQueryParams) && toArrayQueryParams?.length > 0
  const hasSuggestions = Array.isArray(suggestionsData) && suggestionsData?.length > 0

  const searchParamsWithSuggestions = [
    ...(isInitial ? [] : hasQueryParams ? toArrayQueryParams : []),
    ...((isSearchQueryTheSame || data?.action) && hasSuggestions ? suggestionsData : []),
  ]

  const isShowBuildLookalikeBasedOnBrandAccount =
    currentPlatform === SOCIAL_PLATFORMS_NAMES.instagram.name &&
    searchType === searchContentTypes.CREATOR &&
    currentQuery['brandMentions']

  const changeSearchParamsFromQuery = ({ key, value }) => {
    const dataVal = value?.currentVal || value.default
    const valueFromQuery = currentQuery[key]

    if (key === 'brandMentions') {
      const filtered = tempBrandMentionsData?.some(el => el.username === valueFromQuery)
      dispatch(changeTempBrandMentionsData(filtered ? [] : tempBrandMentionsData))
    }

    if (key === 'placesId' || key === 'radiusKM') {
      dispatch(
        updateSearch({
          newValuesObj: {
            placesId: defaultSearchQuery.placesId,
            radiusKM: defaultSearchQuery.radiusKM,
          },
        })
      )
      return
    }

    const splittedValueFromQuery =
      Number(valueFromQuery) || !valueFromQuery
        ? ''
        : valueFromQuery?.split(',')?.map(item => item?.trim())
    if (splittedValueFromQuery?.length > 1) {
      const isIncluded = splittedValueFromQuery?.includes(dataVal)
      const filtered = splittedValueFromQuery?.filter(el => el?.trim() !== dataVal)
      const resVal = isIncluded ? filtered : [...splittedValueFromQuery, dataVal]
      const joined = resVal?.join()

      dispatch(
        updateSearch({
          newValuesObj: {
            [key]: joined,
          },
        })
      )
    } else {
      dispatch(
        updateSearch({
          newValuesObj: {
            [key]: value?.default,
          },
        })
      )
    }
  }

  const addSuggestions = dataFromProps => {
    if (dataFromProps?.method === 'specifySearch') {
      changeSearchParamsFromQuery({
        key: dataFromProps?.key,
        value: { default: dataFromProps?.value },
      })
    }

    if (dataFromProps?.action === 'textInput') {
      setInputValue(dataFromProps?.value)
      onGetSearchBySmartText(dataFromProps?.value)
    }
    if (dataFromProps?.action === 'search') {
      const platformFromQuery = dataFromProps?.query?.index
      const isTheSamePlatform = platformFromQuery?.toLowerCase() === currentPlatform?.toLowerCase()

      if (!isTheSamePlatform) {
        dispatch(changeAppSocialPlatform(platformFromQuery?.toUpperCase()))
      }

      dispatch(
        updateSearch({
          newValuesObj: {
            ...dataFromProps?.query,
            followerMax:
              dataFromProps?.query?.followerMax === 'Infinity'
                ? ''
                : dataFromProps?.query?.followerMax,
          },
        })
      )
    }
    if (data?.action === 'lookalike') {
      return
    }
  }

  return (
    <Stack spacing={1.5}>
      <SearchSmartBar
        inputValue={inputValue}
        setInputValue={setInputValue}
        onGetSearchBySmartText={onGetSearchBySmartText}
        searchType={searchType}
        dataSuggestions={data?.suggestions || []}
        clearSearchMain={clearSearchMain}
        openModalLookalikeAi={openModalLookalikeAi}
        setOpenModalLookalikeAi={setOpenModalLookalikeAi}
        currentQuery={currentQuery}
        tempBrandMentionsData={tempBrandMentionsData}
        lookalikeId={lookalikeId}
      />
      {isLoading || isSearchLoading ? (
        <ChipSkeletonLoader />
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ flexWrap: 'wrap', rowGap: 1, columnGap: 1 }}
          >
            {searchParamsWithSuggestions?.map((searchParam, idx) => {
              if (
                (searchParam?.index && !searchUsers?.length) ||
                searchParam?.contentTypes ||
                searchParam?.location ||
                isLookalike ||
                searchParam?.token ||
                searchParam?.lookalikeId ||
                searchParam?.promptSuggestion
              )
                return null

              return (
                <Grow key={idx}>
                  <SearchChip
                    searchParam={searchParam}
                    removeSearchParamsFromQuery={changeSearchParamsFromQuery}
                    addSuggestions={addSuggestions}
                    currentQuery={currentQuery}
                    setCurrentOpenSearchParam={setCurrentOpenSearchParam}
                    isContentType={searchType === searchContentTypes.CONTENT}
                    currentPlatform={currentPlatform}
                  />
                </Grow>
              )
            })}
          </Stack>
          {isShowBuildLookalikeBasedOnBrandAccount && (
            <BuildLookalikeBasedOnBrandAccount
              currentQuery={currentQuery}
              setOpenModalLookalikeAi={setOpenModalLookalikeAi}
            />
          )}
        </>
      )}
    </Stack>
  )
}
