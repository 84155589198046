import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslation from '../../../localization/useTranslation'

import { HeartFull, HeartEmpty } from '../../common/icons'
import WithTip from '../../common/popups/WithTip'
import WithUpgradeTip from '../../common/popups/WithUpgradeTip'
import { Button } from '@mui/material'

const IconWrapper = styled.div`
  ${props => props.disabled && props.theme.disableBlock};
  position: relative;
`

const ProfileFavsTool = ({
  isFav,
  removeFromFavs,
  addToFavs,
  userindex = '',
  canAddToFavs,
  enableFavorites,
  isUserAuthenticated,
  tipPosition,
}) => {
  const [favSelected, setFavSelected] = useState(isFav)
  const { labelStrings } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleToggleFavorite = async () => {
    if (isLoading || !enableFavorites || (!canAddToFavs && !isFav)) return

    const newFavState = !favSelected
    if (!canAddToFavs && !isFav) return

    setIsLoading(true)
    const success = await (newFavState ? addToFavs() : removeFromFavs())

    setIsLoading(false)

    if (success) {
      setFavSelected(newFavState)
    }
  }

  const FullHeartIcon = (
    <IconWrapper disabled={!enableFavorites}>
      <HeartFull userindex={userindex} tabIcon={true} />
    </IconWrapper>
  )
  const EmptyHeartIcon = (
    <IconWrapper disabled={!enableFavorites}>
      <HeartEmpty userindex={userindex} isActiveIcon={false} tabIcon={true} />
    </IconWrapper>
  )

  return (
    <Button
      onClick={handleToggleFavorite}
      disableFocusRipple
      disableRipple
      sx={{
        width: 'fit-content',
        minWidth: '30px',
        cursor: isLoading ? 'wait' : 'pointer',
        '&:hover': {
          backgroundColor: 'transparent',
          span: {
            cursor: isLoading ? 'wait' : 'pointer',
            svg: {
              cursor: isLoading ? 'wait' : 'pointer',
            },
          },
        },
        svg: {
          opacity: isLoading ? 0.5 : 1,
        },
      }}
    >
      {!enableFavorites || !canAddToFavs ? (
        !canAddToFavs && favSelected ? (
          <WithTip
            tipText={labelStrings.removeFromFavorites}
            position="top center"
            userindex={userindex}
          >
            <span>{FullHeartIcon}</span>
          </WithTip>
        ) : (
          <WithUpgradeTip
            tipText={labelStrings.addMoreUsers}
            userindex={userindex}
            withSignup={!isUserAuthenticated}
          >
            {EmptyHeartIcon}
          </WithUpgradeTip>
        )
      ) : (
        <WithTip
          tipText={favSelected ? labelStrings.removeFromFavorites : labelStrings.addToFavorites}
          position={tipPosition || 'top center'}
          userindex={userindex}
        >
          <span>{favSelected ? FullHeartIcon : EmptyHeartIcon}</span>
        </WithTip>
      )}
    </Button>
  )
}

ProfileFavsTool.propTypes = {
  isFav: PropTypes.bool,
  removeFromFavs: PropTypes.func,
  addToFavs: PropTypes.func,
  userindex: PropTypes.string,
  canAddToFavs: PropTypes.bool,
  enableFavorites: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool,
  tipPosition: PropTypes.string,
}

export default ProfileFavsTool
