import React from 'react'
import { CardHeader, Box } from '@mui/material'
import { fShortenNumber } from '../../../../utils/formatNumber'
import Chart, { useChart } from '../../index'
import { StyledStackedColumnChart } from '.././styles.js'

export const StackedColumnChartExport = ({
  title,
  subheader = '',
  chart,
  height = '464px',
  ...other
}) => {
  const { labels, colors, series = [], options } = chart

  const chartOptions = useChart({
    chart: {
      stacked: true,
      stackType: 'normal',
    },
    colors,
    stroke: {
      width: 0,
    },
    fill: {
      opacity: 1,
    },
    xaxis: {
      categories: labels,
      tickPlacement: 'on',
      position: 'bottom',
      floating: false,
      labels: {
        trim: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function(value) {
          return fShortenNumber(value)
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '40%',
        dataLabels: {
          position: 'top',
          total: {
            enabled: series[0]?.data[0]?.data?.length <= 10, // hide if more than 10 bars
            offsetY: 0,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 500,
              colors: ['#212B36'],
            },
            formatter: function(value) {
              return fShortenNumber(value)
            },
          },
        },
      },
    },
    ...options,
  })

  return (
    <Box {...other} sx={{ height }}>
      <CardHeader title={title} subheader={subheader} />

      {series?.map((item, idx) => (
        <Box key={idx} sx={{ mt: 3, mx: 3 }} dir="ltr">
          <StyledStackedColumnChart>
            <Chart type="bar" series={item.data} options={chartOptions} height={364} />
          </StyledStackedColumnChart>
        </Box>
      ))}
    </Box>
  )
}
