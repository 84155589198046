import React from 'react'
import { Paper, Stack } from '@mui/material'
import { RunningCampaigns } from './RunningCampaings'
import { CreatorsLikeBased } from './CreatorsLikesBased'
import { MeetOurInfluencers } from './MeetOurInfluencers'
import { YourLookalikeLists } from './YourLookalikeLists'
import { NAVBAR_FOOTER_SUM_HEIGHT } from '../../constants/appSettings'

export const DashboardView = props => {
  const {
    campaignArray,
    suggestionDashboardData,
    isLoadingSuggestion,
    organizationCurrency,
    isUserAuthenticated,
    canAddToFavs,
    enable_collections,
    collectionsList,
    infludataExpertsArray,
    availableExpertCredits,
    lookalikeListsData,
    currentQuery,
    tempBrandMentionsData,
    onLoadMoreCreatorsYouMightLike,
    onRenewCreatorsList,
  } = props || {}

  return (
    <Paper
      sx={{
        zIndex: 2,
        my: 2,
        px: 2,
        backgroundColor: '#FFF1F1',
        height: '100%',
        minHeight: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)`,
      }}
    >
      <Stack>
        <CreatorsLikeBased
          suggestionDashboardData={suggestionDashboardData}
          isLoadingSuggestion={isLoadingSuggestion}
          organizationCurrency={organizationCurrency}
          isUserAuthenticated={isUserAuthenticated}
          canAddToFavs={canAddToFavs}
          enable_collections={enable_collections}
          collectionsList={collectionsList}
          onLoadMoreCreatorsYouMightLike={onLoadMoreCreatorsYouMightLike}
          onRenewCreatorsList={onRenewCreatorsList}
        />
        <RunningCampaigns campaignArray={campaignArray} />
        <YourLookalikeLists
          lookalikeListsData={lookalikeListsData}
          currentQuery={currentQuery}
          tempBrandMentionsData={tempBrandMentionsData}
        />
        {false && !!infludataExpertsArray?.length && (
          <MeetOurInfluencers
            infludataExpertsArray={infludataExpertsArray}
            availableExpertCredits={availableExpertCredits}
          />
        )}
      </Stack>
    </Paper>
  )
}
