import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { Slider, Stack, Typography, Button } from '@mui/material'
import { infludataThemes } from '../../../../../themes/infludataThemes'

const marks = [
  {
    value: -95,
    label: '100%',
  },
  {
    value: 95,
    label: '100%',
  },
]

export const AudienceGenderFilter = props => {
  const { selectedPlatform, query, handlersMap, permissions } = props || {}

  const { labelStrings } = useTranslation()

  const [localSliderValue, setLocalSliderValue] = useState(0)

  const handleChange = (event, newValue) => {
    setLocalSliderValue(newValue)
  }

  const onChangeQueryValues = (e, value) => {
    const genderValue = value < 0 ? `${Math.abs(value)}_male` : `${Math.abs(value)}_female`
    handlersMap.audience({
      audienceCountries: query.audienceCountries,
      audienceGender: value === 0 ? '' : genderValue,
      audienceRealPeople: query.audienceRealPeople,
      audienceAgeRanges: query.audienceAgeRanges,
      audienceLanguages: query.audienceLanguages,
    })
  }

  const onHandleOff = () => {
    handleChange(null, 0)
    handlersMap.audience({
      audienceCountries: query.audienceCountries,
      audienceGender: '',
      audienceRealPeople: query.audienceRealPeople,
      audienceAgeRanges: query.audienceAgeRanges,
      audienceLanguages: query.audienceLanguages,
    })
  }

  useEffect(() => {
    const splittedValue = query?.audienceGender?.split('_')
    const numValue = splittedValue?.length ? splittedValue[0] : 0
    const sliderValue =
      splittedValue?.length && splittedValue[1] === 'female' ? Number(numValue) : Number(-numValue)
    setLocalSliderValue(sliderValue)
  }, [])

  return (
    <Stack sx={{ px: 1, minWidth: '200px' }}>
      <Typography
        sx={{
          color: '#784E4E',
          fontWeight: 700,
          fontSize: '11px',
          textTransform: 'uppercase',
          fontFamily: 'Inter',
        }}
      >
        {labelStrings.audienceByGender}
      </Typography>
      <Stack sx={{ py: 1.5 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            sx={{
              color: '#784E4E',
              fontWeight: 700,
              fontSize: '11px',
              textTransform: 'uppercase',
              fontFamily: 'Inter',
            }}
          >
            {labelStrings.male}
          </Typography>
          <Typography
            sx={{
              color: '#784E4E',
              fontWeight: 700,
              fontSize: '11px',
              textTransform: 'uppercase',
              fontFamily: 'Inter',
            }}
          >
            {labelStrings.female}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Typography
            sx={{
              color: infludataThemes[selectedPlatform]?.platformColor,
              fontWeight: 700,
              fontSize: '10px',
              textTransform: 'lowercase',
              fontFamily: 'Inter',
            }}
          >
            100%
          </Typography>
          <Slider
            sx={{
              mt: '-8px',
              width: '100%',
              height: '5px',
              borderRadius: '8px',
              color: infludataThemes[selectedPlatform]?.platformColor,
              '& .MuiSlider-valueLabelOpen': {
                fontWeight: 'bold',
                color: infludataThemes[selectedPlatform]?.platformColor,
                fontFamily: 'Inter',
                fontSize: '10px',
              },
              '& .MuiSlider-valueLabel': {
                background: 'unset',
                transformOrigin: 'bottom left',
                transform: 'translate(50%, 100%) rotate(45deg) scale(0)',
                '&::before': { display: 'none' },
                '&.MuiSlider-valueLabelOpen': {
                  transform: 'translate(50%, -50%) rotate(-225deg) scale(1)',
                },
                '& > *': {
                  transform: 'rotate(-135deg)',
                },
              },
              '& .MuiSlider-markLabel': {
                display: 'none',
              },
            }}
            value={localSliderValue}
            onChange={handleChange}
            onChangeCommitted={onChangeQueryValues}
            valueLabelDisplay="on"
            valueLabelFormat={val => (val === 0 ? 'Off' : `${Math.abs(val)}%`)}
            marks={marks}
            min={-100}
            max={100}
          />
          <Typography
            sx={{
              color: infludataThemes[selectedPlatform]?.platformColor,
              fontWeight: 700,
              fontSize: '10px',
              textTransform: 'lowercase',
              fontFamily: 'Inter',
            }}
          >
            100%
          </Typography>
        </Stack>
      </Stack>
      {localSliderValue !== 0 && (
        <Stack direction="row" justifyContent="center">
          <Button
            variant="text"
            onClick={onHandleOff}
            sx={{
              width: 'fit-content',
              color: infludataThemes[selectedPlatform]?.platformColor,
              fontWeight: 700,
              fontSize: '10px',
              textTransform: 'uppercase',
              fontFamily: 'Inter',
              minWidth: '40px',
              p: '4px 8px',
            }}
          >
            {labelStrings.off}
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
