import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  isUserSeatsChangeProgressSelector,
  userCredsSelector,
  userGrantSelector,
} from '../../../redux/selectors'
import {
  createNewOrganization,
  getAllOrganizationsData,
  switchOrganizationalAccount,
} from '../../../redux/ducks/userDucks'
import Organizations from './Organizations'
import { CreateNewOrganizationsAccountModal } from './modals/CreateNewOrganizationsAccountModal'

const OrganizationsContainer = () => {
  const dispatch = useDispatch()

  const isSeatsUpdateInProgress = useSelector(isUserSeatsChangeProgressSelector)
  const { organizations, companyMembers } = useSelector(userCredsSelector)

  const { organization_limit, user_seats } = useSelector(userGrantSelector, shallowEqual)

  const [isCreateNewOrganizations, setIsCreateNewOrganizations] = useState(false)

  const onCreateNewOrganizationsModal = () => {
    setIsCreateNewOrganizations(prev => !prev)
  }

  const onCloseCreateNewOrganizationsModal = () => {
    setIsCreateNewOrganizations(false)
  }

  const onSubmitModal = ({ companyName }) => {
    // @ts-ignore
    dispatch(createNewOrganization({ companyName }))
  }

  const onSwitchToOrganizations = organizationsData => {
    const { orgId } = organizationsData || {}
    dispatch(
      // @ts-ignore
      switchOrganizationalAccount({
        orgId,
      })
    )
  }

  useEffect(() => {
    // @ts-ignore
    dispatch(getAllOrganizationsData())
  }, [])

  return (
    <>
      <Organizations
        organizationLimit={organization_limit}
        limitTeamMemberSeats={user_seats}
        isSeatsUpdateInProgress={isSeatsUpdateInProgress}
        organizationsData={organizations}
        companyMembers={companyMembers}
        onSwitchToOrganizations={onSwitchToOrganizations}
        onCreateNewOrganizationsModal={onCreateNewOrganizationsModal}
      />
      {isCreateNewOrganizations && (
        <CreateNewOrganizationsAccountModal
          isOpenModal={isCreateNewOrganizations}
          closeModal={onCloseCreateNewOrganizationsModal}
          onSubmit={onSubmitModal}
        />
      )}
    </>
  )
}

export default OrganizationsContainer
