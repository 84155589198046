import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton, Stack, Typography } from '@mui/material'
import { WithScrollWrapper } from '../../../../components/common/styledWrappers'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { LoadingButton } from '@mui/lab'
import {
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
  setCollectionsList,
} from '../../../../redux/ducks/collectionsDucks'

const moveToTop = (collections, collectionId, isUserInCollection) => {
  if (!collections?.length) return []

  const index = collections?.findIndex(collection => collection.collectionId === collectionId)
  if (index <= 2) return collections

  const updatedCollections = [...collections]

  const [item] = updatedCollections?.splice(index, 1)

  const modifiedItem = {
    ...item,
    count: isUserInCollection ? Number(item.count) + -1 : Number(item.count) + 1,
  }

  if (!isUserInCollection) {
    updatedCollections?.unshift(modifiedItem)
  } else {
    updatedCollections?.splice(index, 0, modifiedItem)
  }

  return updatedCollections
}

export const AddProfileToCollection = props => {
  const {
    collectionsList,
    row,
    isExpandedCollectionsList,
    setExpandCollectionsList,
    setSearchUsers,
    usersData,
    countResults,
    setIsCreateCollectionWithCurrentProfile,
    isSearch,
    source
  } = props || {}

  const dispatch = useDispatch()

  const [currentCollectionId, setCurrentCollectionId] = useState('')

  const filteredCollectionList = collectionsList?.filter(
    x =>
      !x.collectionId.includes('contentFav') &&
      !x.collectionId.includes('fav') &&
      !x.collectionId.includes('unlocked')
  )

  const firstThreeCollections = filteredCollectionList?.slice(0, isSearch ? 1 : 3)
  const otherCollections = filteredCollectionList?.slice(isSearch ? 1 : 3)

  const onAddToCollection = async (e, collectionId) => {
    e.preventDefault()
    e.stopPropagation()

    if (!collectionId || currentCollectionId) return

    setCurrentCollectionId(collectionId)
    setExpandCollectionsList('')

    const isUserInCollection = row.collectionArray?.includes(collectionId)

    const handler = isUserInCollection
      ? removeSelectedProfileFromCollection
      : addSelectedProfileToCollection

    try {
      const handlerResponse = await dispatch(
        // @ts-ignore
        handler(row?._id, collectionId, row?.contentId || '', source)
      )

      if (handlerResponse) {
        const modifiedCollections = moveToTop(
          filteredCollectionList,
          collectionId,
          isUserInCollection
        )
        const modifiedUsers = usersData?.map(user => {
          const isCurrentUser = user?._id === row?._id
          if (isCurrentUser) {
            return {
              ...user,
              collectionArray: isUserInCollection
                ? user.collectionArray?.filter(el => el !== collectionId)
                : [...user.collectionArray, collectionId],
              collectionCount: isUserInCollection
                ? Number(user.collectionCount) - 1
                : Number(user.collectionCount) + 1,
            }
          } else {
            return user
          }
        })
        dispatch(setCollectionsList(modifiedCollections))
        setSearchUsers(modifiedUsers, countResults)
        setCurrentCollectionId('')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentCollectionId('')
    }
  }

  const onExpandCollections = e => {
    e.preventDefault()
    e.stopPropagation()
    setExpandCollectionsList(prev => (prev === row?._id ? '' : row?._id))
  }

  const onCreateNewCollection = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsCreateCollectionWithCurrentProfile(row)
  }

  return (
    <Stack
      direction="row"
      alignItems="start"
      justifyContent="end"
      sx={{ width: '80%', mr: isSearch ? 0 : '20px', pb: isSearch ? 0 : 0.5 }}
      spacing={1}
    >
      {!isSearch && (
        <IconButton
          size="small"
          onClick={e => onCreateNewCollection(e)}
          disableRipple
          disableTouchRipple
          sx={{
            zIndex: '999999',
            color: '#6B4545',
            mr: '4px !important',
            p: 0,
          }}
        >
          <CreateNewFolderIcon />
        </IconButton>
      )}
      {/* Render first three collections */}
      {firstThreeCollections?.map(el => {
        const isUserInCollection = row.collectionArray?.includes(el.collectionId)
        return (
          <LoadingButton
            key={el.collectionId}
            loading={currentCollectionId === el.collectionId}
            size="small"
            loadingPosition="end"
            sx={{
              backgroundColor: isUserInCollection ? '#CCFFE1' : '#FBEBEB',
              borderRadius: '24px',
              color: '#6B4545',
              px: 1,
              '& .MuiButton-endIcon': { ml: '2px' },
              '&:hover': { borderRadius: '24px' },
              height: '24px !important',
              '& .MuiLoadingButton-loadingIndicatorEnd': {
                right: '6px',
              },
              '& .MuiCircularProgress-circle': {
                stroke: '#6B4545',
              },
              opacity: currentCollectionId === el.collectionId ? 0.5 : 1,
            }}
            onClick={e => onAddToCollection(e, el.collectionId)}
            endIcon={
              isUserInCollection ? (
                <CheckCircleRoundedIcon sx={{ fill: '#01DC5C' }} />
              ) : (
                <AddRoundedIcon />
              )
            }
          >
            <Typography
              noWrap
              sx={{
                maxWidth: '180px',
                color: '#6B4545',
                fontFamily: 'Inter',
                fontSize: '13px',
                fontWeight: 400,
              }}
            >
              {el.name}
            </Typography>
          </LoadingButton>
        )
      })}
      {/* Expand button if there are more than 3 collections */}
      {filteredCollectionList?.length > (isSearch ? 1 : 3) && (
        <Stack sx={{ position: 'relative', ml: isSearch ? '6px' : '12px !important' }}>
          <IconButton onClick={onExpandCollections} size="small" sx={{ p: 0 }}>
            <ExpandCircleDownRoundedIcon
              sx={{
                fill: isExpandedCollectionsList ? '#F50' : '#6B4545',
                transform: `rotate(${isExpandedCollectionsList ? 180 : 0}deg)`,
              }}
            />
          </IconButton>
          {isExpandedCollectionsList && (
            <Stack
              sx={{
                position: 'absolute',
                top: '28px',
                right: '-20px',
                zIndex: 99999,
                backgroundColor: '#FFF',
                border: '1px solid #FBEBEB',
                boxShadow: `0px 17px 33px -13px rgba(44, 22, 22, 0.30)`,
                borderRadius: '19px',
                p: 1,
                width: '200px',
                transition: 'opacity 0.3s ease, transform 0.3s ease',
                opacity: isExpandedCollectionsList ? 1 : 0,
                transform: isExpandedCollectionsList ? 'translateY(0)' : 'translateY(-10px)',
              }}
            >
              <WithScrollWrapper
                showScroll
                withoutPaddingBottom
                height={200}
                style={{ maxHeight: '200px' }}
              >
                <Stack spacing={1}>
                  {otherCollections?.map(el => {
                    const isUserInCollection = row.collectionArray?.includes(el.collectionId)
                    return (
                      <LoadingButton
                        key={el.collectionId}
                        loading={currentCollectionId === el.collectionId}
                        size="small"
                        loadingPosition="end"
                        sx={{
                          backgroundColor: isUserInCollection ? '#CCFFE1' : '#FBEBEB',
                          borderRadius: '24px',
                          color: '#6B4545',
                          px: isSearch ? 0.5 : 1,
                          '& .MuiButton-endIcon': { ml: '2px' },
                          '&:hover': { borderRadius: '24px' },
                          height: '24px !important',
                          '& .MuiLoadingButton-loadingIndicatorEnd': {
                            right: '6px',
                          },
                          '& .MuiCircularProgress-circle': {
                            stroke: '#6B4545',
                          },
                          width: 'fit-content',
                          opacity: currentCollectionId === el.collectionId ? 0.5 : 1,
                        }}
                        onClick={e => onAddToCollection(e, el.collectionId)}
                        endIcon={
                          isUserInCollection ? (
                            <CheckCircleRoundedIcon sx={{ fill: '#01DC5C' }} />
                          ) : (
                            <AddRoundedIcon />
                          )
                        }
                      >
                        <Typography
                          noWrap
                          sx={{
                            maxWidth: '130px',
                            color: '#6B4545',
                            fontFamily: 'Inter',
                            fontSize: '13px',
                            fontWeight: 400,
                          }}
                        >
                          {el.name}
                        </Typography>
                      </LoadingButton>
                    )
                  })}
                </Stack>
              </WithScrollWrapper>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  )
}
