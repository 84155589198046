import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, Box, IconButton, Menu, MenuItem, Portal } from '@mui/material'
import { fShortenNumber } from '../../../utils/formatNumber'
import Chart, { useChart } from '../index'
import { StyledStackedColumnChart } from './styles.js'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import { TooltipComponent } from '../../tooltip/TooltipComponent'
import html2canvas from 'html2canvas'
import pptxgen from 'pptxgenjs'
import { StackedColumnChartExport } from './Export/StackedColumnChartExport'

export const StackedColumnChart = ({
  title,
  subheader = '',
  chart,
  height = '464px',
  helperText = '',
  isPdf,
  ...other
}) => {
  const { labels, colors, series = [], options } = chart
  const chartRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const exportElement = chartRef.current

    const cardImage = await html2canvas(exportElement, { scale: 2 })
    const cardDataUrl = cardImage.toDataURL('image/jpeg', 1.0)

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const cardAspectRatio = exportElement.offsetWidth / exportElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / cardAspectRatio

    slide.addImage({
      data: cardDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: slideHeight,
      type: 'contain',
    })
    pptx.writeFile('Chart.pptx').then(() => {
      handleDownloadClose()
    })
  }

  const exportToImage = async () => {
    const exportElement = chartRef.current
    const options = {
      scale: 2,
      useCORS: true,
      logging: true,
    }

    const cardImage = await html2canvas(exportElement, options)
    const cardDataUrl = cardImage.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = cardDataUrl
    link.download = 'Chart.png'
    link.click()
    handleDownloadClose()
  }

  const chartOptions = useChart({
    chart: {
      stacked: true,
      stackType: 'normal',
    },
    colors,
    stroke: {
      width: 0,
    },
    fill: {
      opacity: 1,
    },
    xaxis: {
      categories: labels,
      tickPlacement: 'on',
      position: 'bottom',
      floating: false,
      labels: {
        trim: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function(value) {
          return fShortenNumber(value)
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '40%',
        dataLabels: {
          position: 'top',
          total: {
            enabled: series[0]?.data[0]?.data?.length <= 10, // hide if more than 10 bars
            offsetY: 0,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 500,
              colors: ['#212B36'],
            },
            formatter: function(value) {
              return fShortenNumber(value)
            },
          },
        },
      },
    },
    ...options,
  })

  return (
    <>
      <Card {...other} sx={{ height }}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <>
              {!!helperText?.length && (
                <IconButton className="ignore-export" aria-label="Helper-Text">
                  <TooltipComponent text={helperText}>
                    <InfoOutlinedIcon />
                  </TooltipComponent>
                </IconButton>
              )}
              {!isPdf && (
                <>
                  <IconButton aria-label="Download" onClick={handleDownloadClick}>
                    <DownloadIcon />
                  </IconButton>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
                    <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
                    <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
                  </Menu>
                </>
              )}
            </>
          }
        />

        {series?.map((item, idx) => (
          <Box key={idx} sx={{ mt: 3, mx: 3 }} dir="ltr">
            <StyledStackedColumnChart>
              <Chart type="bar" series={item.data} options={chartOptions} height={364} />
            </StyledStackedColumnChart>
          </Box>
        ))}
      </Card>
      <Portal>
        <Box
          ref={chartRef}
          sx={{ position: `fixed`, top: `-9999px`, left: `-9999px`, zIndex: `-9999` }}
        >
          <StackedColumnChartExport
            title={title}
            subheader={subheader}
            chart={chart}
            height={height}
            other={other}
          />
        </Box>
      </Portal>
    </>
  )
}

StackedColumnChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  helperText: PropTypes.string,
  isPdf: PropTypes.bool,
}
