import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import useTranslation from '../../../localization/useTranslation'

import { GoBackIcon } from '../../common/icons'
import { GoBackToResultsLink, GoBackToResultsLabel } from './styles'
import { USER_INDEXES } from '../../../constants/appSettings'
import { useDispatch } from 'react-redux'
import { setLastPageBeforeProfileRedirect } from '../../../redux/ducks/appSettingsDucks'

const GoBackToResults = ({ platform }) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { goBack } = history

  const onHandleClick = () => {
    dispatch(setLastPageBeforeProfileRedirect(''))
    goBack()
  }

  return (
    <GoBackToResultsLink platform={platform} onClick={onHandleClick}>
      <GoBackIcon />
      <GoBackToResultsLabel>{labelStrings.goBackToResults}</GoBackToResultsLabel>
    </GoBackToResultsLink>
  )
}

GoBackToResults.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
}

export default GoBackToResults
