import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import {
	Typography,
	Stack,
	Button,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Link,
	Select,
	MenuItem,
	FormControl,
	InputLabel
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { changeOrganizationCurrencyData, changePaymentMethod } from "../../../redux/ducks/userDucks"
import { userDetailsSelector } from "../../../redux/selectors"
import { setError } from "../../../redux/ducks/errorDucks"
import { themeCommonStyles } from "../../../themes/infludataThemes"
import { LoadingButton } from "@mui/lab"
import LoopRoundedIcon from "@mui/icons-material/LoopRounded"
import useTranslation from "../../../localization/useTranslation"
import CommonSettingSection from "../CommonSettingSection"
import { formatDateToLocale } from "../../../utils"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { currencyList } from "../../../constants/appSettings"

// The billing fields required
const _REQUIRED_ADDRESS_FIELDS = [
	"first_name",
	"last_name",
	"company",
	"line1",
	"zip",
	"city",
	"country"
]

const isAnyKeyMissingOrEmpty = (object, keys) => {
	if (!object) {
		return true
	}
	return keys?.some(
		key =>
			!(key in object) ||
			object[key] === null ||
			object[key] === undefined ||
			object[key] === ""
	)
}



// Shared MUI Button styling
const MuiBtnStyles = {
	fontFamily: "Inter",
	fontSize: "13px",
  minWidth: "200px",
  minHeight: "40px",
	borderRadius: "10px",
	textTransform: "uppercase",
	width: "fit-content",
	color: "#FF5C00",
	borderColor: "#FF5C00",
	backgroundColor: "#fff",
	"&:hover": {
		color: "white",
		backgroundColor: "#FF5500"
	}
}

const SubscriptionSettings = ({
	isCanceledStatus,
	setIsOpenEditDetails,
	trial_end_date_infludata,
	in_trial_infludata,
	is_self_service,
	no_payment_method,
  is_unbilled_plan,
	subscriptionText,
	onAddSubscription,
	onCancelSubscription,
	onReactivateSubscription,
	onRemoveCancelation,
	non_renewing_infludata,
	expiration_date,
	current_term_end,
  no_plan,
	isLoadingActivateSubscriptions
}) => {
	const { labelStrings, currentLang } = useTranslation()
	const dispatch = useDispatch()
	const { billing_address } = useSelector(userDetailsSelector) || {}

	const [isOpenCancelSubscription, setIsOpenCancelSubscription] = useState(false)

  // Updated step label strings (for the new 3-step flow)
const stepLabelStrings = {
	stepTitle: labelStrings.trialSubscribe.stepTitle,
	step1: labelStrings.trialSubscribe.step1,
  step1Subtext: labelStrings.trialSubscribe.step1Subtext,
	step2: labelStrings.trialSubscribe.step2,
  step2Subtext: labelStrings.trialSubscribe.step2Subtext,
	step3: labelStrings.trialSubscribe.step3,
  step3Subtext: labelStrings.trialSubscribe.step3Subtext,
  greenCheck: "✔"
}

	// Check if billing data is incomplete
	const isOpenEditAddress = isAnyKeyMissingOrEmpty(billing_address, _REQUIRED_ADDRESS_FIELDS)

	// Format relevant dates
	const formattedDateTrialEnd = trial_end_date_infludata
		? formatDateToLocale({
				dateStr: moment((trial_end_date_infludata + 1) * 1000).format(),
				currentLang
		  })
		: ""
	const formattedDateCancellation = expiration_date
		? formatDateToLocale({
				dateStr: moment((expiration_date + 1) * 1000).format(),
				currentLang
		  })
		: ""
	const subscriptionEndDate = current_term_end
		? formatDateToLocale({
				dateStr: moment((current_term_end + 1) * 1000).format(),
				currentLang
		  })
		: ""

	// OLD LOGIC FLAGS (unchanged from original code)
	const isSubscriptionsNoSelfServiceTrial = !in_trial_infludata && !no_payment_method
	const isShowCancelSubscription = is_self_service && !non_renewing_infludata

	const onCloseDialog = () => {
		setIsOpenCancelSubscription(false)
	}

	// Handler for adding payment method
	const handleAddPaymentMethod = () => {
		if (isOpenEditAddress) {
			// If missing billing data, show an error first
			dispatch(setError(labelStrings.pleaseAddBillingErrorMsg))
			setIsOpenEditDetails(true)
		} else {
			dispatch(changePaymentMethod())
		}
	}

	// NEW 3-STEP FLOW (only if in_trial_infludata && is_self_service)
	const step1Complete = !isOpenEditAddress
	const step2Complete = !no_payment_method
	const step3Complete = !in_trial_infludata

	const renderTrialSelfServiceSteps = () => {
		return (
			<Box>
				{/* Show original subscription status text, e.g. "In Trial until ..." */}
				<Stack sx={{ mt: 1 }}>
					<Typography
						sx={{
							fontSize: "15px",
							fontFamily: "Inter",
							mr: "5px",
							color: themeCommonStyles.color.black,
							fontWeight: 600
						}}
					>
						{labelStrings.subscriptionStatus}
					</Typography>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontSize: "15px",
							fontWeight: 500,
							color: "#393939"
						}}
					>
						{!non_renewing_infludata && !no_payment_method
							? labelStrings.activateTrialLabel(formattedDateTrialEnd)
							: `${labelStrings.inTrial} - ${labelStrings.nonRenewingTrialLabel(formattedDateTrialEnd)}`}
					</Typography>
				</Stack>

				<Typography
					sx={{
						fontSize: "15px",
						fontWeight: 600,
						fontFamily: "Inter",
						color: "#393939",
						marginTop: "20px",
						marginBottom: "10px"
					}}
				>
					{stepLabelStrings.stepTitle}
				</Typography>

				{/* STEP 1: Billing data */}
          <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
            {/* Left part: main label + subtext in a column */}
            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'Inter',
                  color: !step1Complete ? themeCommonStyles.color.red : themeCommonStyles.color.black
                }}
              >
                {stepLabelStrings.step1}
              </Typography>

              {!step1Complete && (
                <Typography sx={{ color: themeCommonStyles.color.grey, fontWeight: 400, fontSize: '13px' }}>
                  {stepLabelStrings.step1Subtext}
                </Typography>
              )}
            </Stack>

            {/* Right part: green check if step1 is complete */}
            {step1Complete && (
              <Typography sx={{ color: '#0A6A47', fontWeight: 700 }}>
                {stepLabelStrings.greenCheck}
              </Typography>
            )}
          </Stack>


          {/* STEP 2: Payment Method */}
          <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
            {/* Left part: main label + subtext in a column */}
            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'Inter',
                  color: step1Complete
                    ? step2Complete
                      ? themeCommonStyles.color.black
                      : themeCommonStyles.color.red
                    : themeCommonStyles.color.grey
                }}
              >
                {stepLabelStrings.step2}
              </Typography>

              {/* If you have subtext for step 2, show it when step2 is NOT complete */}
              {!step2Complete && step1Complete && (
                <Typography sx={{ color: themeCommonStyles.color.grey, fontWeight: 400, fontSize: '13px' }}>
                  {stepLabelStrings.step2Subtext}
                </Typography>
              )}
            </Stack>

            {/* Right part: green check if step2 is complete */}
            {step2Complete ? (
              <Typography sx={{ color: '#0A6A47', fontWeight: 700 }}>
                {stepLabelStrings.greenCheck}
              </Typography>
            ) : step1Complete ? (
              <Button
                sx={MuiBtnStyles}
                onClick={handleAddPaymentMethod}
                variant="outlined"
                disabled={!step1Complete}
              >
                {labelStrings.addPaymentMethod}
              </Button>
            ) : <></>}
          </Stack>


          {/* STEP 3: Activate Immediately */}
          <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
            {/* Left part: main label + subtext in a column */}
            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'Inter',
                  color: step1Complete && step2Complete
                    ? step3Complete
                      ? themeCommonStyles.color.black
                      : themeCommonStyles.color.red
                    : themeCommonStyles.color.grey
                }}
              >
                {stepLabelStrings.step3}
              </Typography>

              {/* If you have subtext for step 3, show it when step3 is NOT complete */}
              {!step3Complete && step1Complete && step2Complete && (
                <Typography sx={{ color: themeCommonStyles.color.grey, fontWeight: 400, fontSize: '13px' }}>
                  {stepLabelStrings.step3Subtext}
                </Typography>
              )}
            </Stack>

            {/* Right part: green check if step3 is complete */}
            {step3Complete ? (
              <Typography sx={{ color: '#0A6A47', fontWeight: 700 }}>
                {stepLabelStrings.greenCheck}
              </Typography>
            ) : step2Complete ? (
              <LoadingButton
                size="small"
                sx={MuiBtnStyles}
                onClick={onAddSubscription}
                variant="outlined"
                disabled={!step1Complete || !step2Complete}
                loadingPosition="end"
                loading={isLoadingActivateSubscriptions}
                endIcon={<LoopRoundedIcon />}
              >
                {labelStrings.activateSubscriptions}
              </LoadingButton>
            ) : <></>}
          </Stack>


				{/* Any extra subscription text passed in */}
				{subscriptionText && (
					<Typography
						dangerouslySetInnerHTML={{ __html: subscriptionText }}
						sx={{
							fontFamily: "Inter",
							fontSize: "14px",
							fontWeight: 600,
							color: "#a60000",
							marginBottom: "6px"
						}}
					/>
				)}

				{/* Cancel link if renewing + payment method */}
				{!non_renewing_infludata && !no_payment_method && (
					<Box sx={{ textAlign: "right" }}>
						<Link
							component="button"
							onClick={() => setIsOpenCancelSubscription(true)}
							sx={{
								fontSize: "12px",
								fontFamily: "Inter",
								textDecoration: "underline",
								color: "#FF5C00",
								cursor: "pointer",
								marginTop: "6px",
								"&:hover": {
									color: "#FF5500"
								}
							}}
						>
							{labelStrings.cancelAutoRenewButton}
						</Link>
					</Box>
				)}
			</Box>
		)
	}

	// Active Subscription:
	const renderDefaultSubscriptionView = () => {
		return (
			<Box>
				{/* Inactive Subscription, no payment, never set up, never cancelled */}
				{no_plan && !isCanceledStatus && (
					<Stack sx={{ mt: 2 }} spacing={0.5}>
						<Stack>
							<Typography
								sx={{
									fontSize: "15px",
									fontFamily: "Inter",
									mr: "5px",
									color: themeCommonStyles.color.black
								}}
							>
								{labelStrings.subscriptionStatus}
							</Typography>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "15px",
									fontWeight: 600,
									color: "#393939",
									paddingBottom: "20px"
								}}
							>
								{`${labelStrings.noPlanSubscriptionLabel}`}
							</Typography>
						</Stack>
					</Stack>
				)}

				{/* Fully active subscription (not cancelled, not trial) */}
				{!isCanceledStatus && !in_trial_infludata && !no_plan && !non_renewing_infludata && (
					<Stack sx={{ mt: 2 }} spacing={0.5}>
						<Stack>
							<Typography
								sx={{
									fontSize: "15px",
									fontFamily: "Inter",
									mr: "5px",
									color: themeCommonStyles.color.black
								}}
							>
								{labelStrings.subscriptionStatus}
							</Typography>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "15px",
									fontWeight: 600,
									color: "#0A6A47",
									paddingBottom: "8px"
								}}
							>
								{`${labelStrings.defaultSubscriptionsLabel(subscriptionEndDate)}`}
							</Typography>
							{isShowCancelSubscription && (
								<Box sx={{ textAlign: "right" }}>
									<Link
										component="button"
										onClick={() => setIsOpenCancelSubscription(true)}
										sx={{
											fontSize: "12px",
											fontFamily: "Inter",
											textDecoration: "underline",
											color: "#FF5C00",
											cursor: "pointer",
											marginTop: "6px",
											"&:hover": {
												color: "#FF5500"
											}
										}}
									>
										{labelStrings.cancelSubscription}
									</Link>
								</Box>
							)}
						</Stack>
					</Stack>
				)}

				{/* Plan is currently cancelled => remove cancellation */}
				{non_renewing_infludata && !in_trial_infludata && (
					<Stack spacing={0.5} sx={{ mt: 1 }}>
						<Typography
							sx={{
								fontSize: "15px",
								fontFamily: "Inter",
								mr: "5px",
								color: themeCommonStyles.color.black
							}}
						>
							{labelStrings.subscriptionStatus}
						</Typography>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "15px",
								fontWeight: 500,
								whiteSpace: "pre-wrap",
								paddingBottom: "20px"
							}}
						>
							{labelStrings.removeCancellationLabel(formattedDateCancellation)}
						</Typography>
						<Button sx={MuiBtnStyles} onClick={onRemoveCancelation} variant="outlined">
							{labelStrings.removeCancellation}
						</Button>
					</Stack>
				)}

				{/* Cancelled or no payment method */}
				{isCanceledStatus && (
					<Stack sx={{ maxWidth: "750px", mt: 1 }}>
						<Typography
							sx={{
								fontSize: "15px",
								fontFamily: "Inter",
								mr: "5px",
								color: themeCommonStyles.color.black
							}}
						>
							{labelStrings.subscriptionStatus}
						</Typography>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "15px",
								fontWeight: 500,
								color: "#FF5D5A",
								marginBottom: "20px"
							}}
						>
							{labelStrings.cancelled}
						</Typography>
						{!no_payment_method && !is_unbilled_plan && (
							<>
								<Button
									sx={{
										...MuiBtnStyles
									}}
									onClick={onReactivateSubscription}
									variant="outlined"
								>
									{labelStrings.activateFromCancelledButton}
								</Button>
								<Typography
									sx={{
										fontFamily: "Inter",
										fontSize: "12px",
										color: "#FF5D5A",
										whiteSpace: "pre-wrap",
										marginTop: "10px"
									}}
								>
									{labelStrings.reactivateFromCancelledSubText}
								</Typography>
							</>
						)}
					</Stack>
				)}

				{/* In Trial, but schedueld to cancel */}
				{in_trial_infludata && (non_renewing_infludata) && (
					<Stack sx={{ maxWidth: "450px", mt: 1 }}>
						<Typography
							sx={{
								fontSize: "15px",
								fontFamily: "Inter",
								mr: "5px",
								color: themeCommonStyles.color.black
							}}
						>
							{labelStrings.subscriptionStatus}
						</Typography>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "15px",
								fontWeight: 500,
								color: themeCommonStyles.color.black
							}}
						>
							{labelStrings.inTrial}
						</Typography>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "12px",
								color: "#FF5D5A",
								whiteSpace: "pre-wrap",
								marginBottom: "20px"
							}}
						>
							{`${labelStrings.inTrial} (${labelStrings.nonRenewingTrialLabel(formattedDateTrialEnd || formattedDateCancellation)})`}
						</Typography>
					</Stack>
				)}

				{/* If no payment method => let them add it */}
				{no_payment_method && (!no_plan || isCanceledStatus) && (
					<Stack sx={{ mt: 1 }}>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: "500",
								fontFamily: "Inter",
								mr: "5px",
								color: themeCommonStyles.color.black
							}}
						>
							{labelStrings.addPaymentMethodContinue}
						</Typography>
						<Button
							sx={{
								mt: 0.5,
								...MuiBtnStyles
							}}
							onClick={() => handleAddPaymentMethod()}
							variant="outlined"
						>
							{labelStrings.addPaymentMethod}
						</Button>
						<Typography
							sx={{
								ml: 2,
								mt: 0.5,
								fontFamily: "Inter",
								fontSize: "12px",
								color: "#FF5D5A"
							}}
						>
							*{labelStrings.pleaseAddBilling}
						</Typography>
					</Stack>
				)}
			</Box>
		)
	}

	// Decide which subscription UI to show
	let Content
	if (in_trial_infludata && is_self_service) {
		Content = renderTrialSelfServiceSteps()
	} else {
		Content = renderDefaultSubscriptionView()
	}

	return (
		<>
			<CommonSettingSection
				title={labelStrings.yourSubscription}
				Content={Content}
			/>
			{/* Cancel subscription dialog */}
			<Dialog
				fullScreen={false}
				open={isOpenCancelSubscription}
				onClose={onCloseDialog}
			>
				<DialogTitle sx={{ textAlign: "center", fontFamily: "Inter" }}>
					{labelStrings.attention}
				</DialogTitle>
				<DialogContent sx={{ maxWidth: "500px" }}>
					<DialogContentText sx={{ fontFamily: "Inter" }}>
						{labelStrings.cancelSubscriptionAttentionText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						onClick={() => {
							onCancelSubscription()
							onCloseDialog()
						}}
						autoFocus
					>
						{labelStrings.confirm}
					</Button>
					<Button variant="outlined" onClick={onCloseDialog}>
						{labelStrings.cancel}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

const AccountSettings = ({
	changePassword,
	email = "",
	isCanceledStatus,
	setIsOpenEditDetails,
	trial_end_date_infludata,
	in_trial_infludata,
  is_unbilled_plan,
	is_self_service,
  no_plan,
	no_payment_method,
	subscriptionText,
	onAddSubscription,
	onCancelSubscription,
	non_renewing_infludata,
	onRemoveCancelation,
	onReactivateSubscription,
	expiration_date,
	current_term_end,
	onAccountChangeEmail,
	associated_org_id,
	associated_org_name,
	isLoadingActivateSubscriptions,
	roles = [],
	organizationCurrency
}) => {
	const { labelStrings } = useTranslation()
	const dispatch = useDispatch()
	const { profile_data } = useSelector(userDetailsSelector) || {}

	const [currentCurrency, setCurrentCurrency] = useState('')
	const [, setCurrencyFromStorage] = useLocalStorage('currency', currentCurrency)

	const changeCurrency = ({ target }) => {
    setCurrentCurrency(target?.value)
    setCurrencyFromStorage(target?.value)
    // @ts-ignore
    dispatch(changeOrganizationCurrencyData({ currency: target?.value }))
  }

	useEffect(() => {
		if (!currentCurrency?.length && organizationCurrency) {
      setCurrentCurrency(organizationCurrency?.toLowerCase())
      setCurrencyFromStorage(organizationCurrency?.toLowerCase())
    }
	}, [organizationCurrency, currentCurrency])

	const CustomButton = (
		<Stack spacing={1}>
			<Button size="small" sx={MuiBtnStyles} onClick={changePassword} variant="outlined">
				{labelStrings.changePassword}
			</Button>
			<Button size="small" sx={MuiBtnStyles} onClick={onAccountChangeEmail} variant="outlined">
				{labelStrings.changeEmail}
			</Button>
		</Stack>
	)

	const AccountContent = (
    <Box>
      <Box sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '15px',
            fontFamily: 'Inter',
            mr: '5px',
            color: themeCommonStyles.color.black,
          }}
        >
          {labelStrings.currentOrganization || 'Current Organization'}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: themeCommonStyles.color.black,
          fontSize: '15px',
          fontFamily: 'Inter',
          paddingBottom: '30px',
        }}
      >
        {`${associated_org_name} - Org. Id: ${associated_org_id}`}
      </Typography>

      <Box sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '15px',
            fontFamily: 'Inter',
            mr: '5px',
            color: themeCommonStyles.color.black,
          }}
        >
          {labelStrings.email}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: themeCommonStyles.color.black,
          fontSize: '15px',
          fontFamily: 'Inter',
          paddingBottom: '30px',
        }}
      >
        {(profile_data && profile_data.email) || email}
      </Typography>

      {currentCurrency?.length > 0 && (
        <Stack spacing={0.5}>
          <Typography
            sx={{
              color: themeCommonStyles.color.black,
              fontSize: '15px',
              fontFamily: 'Inter',
            }}
          >
            {labelStrings.currency}
          </Typography>
          <FormControl size="small" variant="standard" sx={{ minWidth: 100, maxWidth: '120px' }}>
            <Select
              sx={{ textAlign: 'center' }}
              autoWidth
              size="small"
              id="profile-settings-currency-select"
              value={currentCurrency}
              onChange={changeCurrency}
            >
              {currencyList?.map(el => {
                return (
                  <MenuItem
                    sx={{ fontFamily: 'Inter', fontSize: '15px' }}
                    key={el.value}
                    value={el.value}
                  >
                    {el.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
      )}
    </Box>
  )

	return (
		<>
			<CommonSettingSection
				title={labelStrings.yourAccount}
				Button={CustomButton}
				Content={AccountContent}
			/>

			{roles?.includes("admin") && (
				<SubscriptionSettings
					isCanceledStatus={isCanceledStatus}
					setIsOpenEditDetails={setIsOpenEditDetails}
					trial_end_date_infludata={trial_end_date_infludata}
					in_trial_infludata={in_trial_infludata}
          is_unbilled_plan={is_unbilled_plan}
					is_self_service={is_self_service}
					no_payment_method={no_payment_method}
					subscriptionText={subscriptionText}
					onAddSubscription={onAddSubscription}
					onCancelSubscription={onCancelSubscription}
					non_renewing_infludata={non_renewing_infludata}
          no_plan={no_plan}
					onRemoveCancelation={onRemoveCancelation}
					onReactivateSubscription={onReactivateSubscription}
					expiration_date={expiration_date}
					current_term_end={current_term_end}
					isLoadingActivateSubscriptions={isLoadingActivateSubscriptions}
				/>
			)}
		</>
	)
}

// PropTypes
SubscriptionSettings.propTypes = {
	isCanceledStatus: PropTypes.bool,
	setIsOpenEditDetails: PropTypes.func,
	trial_end_date_infludata: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	is_self_service: PropTypes.bool,
	in_trial_infludata: PropTypes.bool,
	no_payment_method: PropTypes.bool,
	subscriptionText: PropTypes.string,
	onAddSubscription: PropTypes.func,
	onCancelSubscription: PropTypes.func,
	non_renewing_infludata: PropTypes.bool,
	onReactivateSubscription: PropTypes.func,
	onRemoveCancelation: PropTypes.func,
	expiration_date: PropTypes.string,
	current_term_end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoadingActivateSubscriptions: PropTypes.bool
}

AccountSettings.propTypes = {
	changePassword: PropTypes.func,
	email: PropTypes.string,
	isCanceledStatus: PropTypes.bool,
	setIsOpenEditDetails: PropTypes.func,
	trial_end_date_infludata: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	is_self_service: PropTypes.bool,
	in_trial_infludata: PropTypes.bool,
	no_payment_method: PropTypes.bool,
	subscriptionText: PropTypes.string,
	onAddSubscription: PropTypes.func,
	onCancelSubscription: PropTypes.func,
	non_renewing_infludata: PropTypes.bool,
	onRemoveCancelation: PropTypes.func,
	onReactivateSubscription: PropTypes.func,
	expiration_date: PropTypes.string,
	current_term_end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onAccountChangeEmail: PropTypes.func,
	associated_org_id: PropTypes.string,
	associated_org_name: PropTypes.string,
	roles: PropTypes.arrayOf(PropTypes.string),
	isLoadingActivateSubscriptions: PropTypes.bool,
	organizationCurrency: PropTypes.string
}

export default AccountSettings
