import React, { useState } from 'react'
import useTranslation from '../../../localization/useTranslation'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Portal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useDispatch } from 'react-redux'
import { changeShowScoreModal } from '../../../redux/ducks/userDucks'
import { receiveNpsScore } from '../../../redux/ducks/searchDucks'

const boxes = [
  { number: 1, color: '#FF8C82' },
  { number: 2, color: '#FF8C82' },
  { number: 3, color: '#FF8C82' },
  { number: 4, color: '#FF8C82' },
  { number: 5, color: '#FF8C82' },
  { number: 6, color: '#FF8C82' },
  { number: 7, color: '#FEC700' },
  { number: 8, color: '#FEC700' },
  { number: 9, color: '#96D35F' },
  { number: 10, color: '#96D35F' },
]

export const ModalScore = () => {
  const { labelStrings, currentLang } = useTranslation()
  const dispatch = useDispatch()

  const [scoreValue, setScoreValue] = useState(0)
  const [textInputValue, setTextInputValue] = useState('')

  const onClose = () => {
    // @ts-ignore
    dispatch([
      changeShowScoreModal(false),
      receiveNpsScore({ score: scoreValue === 0 ? '' : scoreValue, textInput: textInputValue }),
    ])
  }

  const onChangeTextInputValue = ({ target }) => {
    setTextInputValue(target.value)
  }

  const onSubmit = () => {
    onClose()
  }

  const titleForScoreValue = () => {
    if (scoreValue <= 6) {
      return labelStrings.howCanWeImprove
    }

    if (scoreValue > 6 && scoreValue <= 8) {
      return labelStrings.whatCanWeImprove
    }

    if (scoreValue > 8 && scoreValue <= 10) {
      return labelStrings.whatDoULikeMost
    }
  }

  const getContent =
    scoreValue > 0 ? (
      <Stack
        sx={{ minWidth: '608px', minHeight: '100px', height: '100%' }}
        alignItems="center"
        spacing={1}
      >
        <Typography
          textAlign="center"
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Inter',
            color: '#442424',
            fontSize: '14px',
          }}
        >
          {titleForScoreValue()}
        </Typography>
        <TextField
          placeholder={labelStrings.scoreInputHelperText}
          sx={{
            width: '80%',
            height: 'auto',
            borderRadius: '8px',
            borderColor: '#A06E6E',
            backgroundColor: '#FDFCFF',
            '& .MuiInputLabel-root': {
              fontFamily: 'Inter',
              color: '#A77D7D !important',
              '&:focused, &:active': {
                color: '#A77D7D',
              },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#A06E6E',
              },
              '&:hover fieldset': {
                borderColor: '#A06E6E',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#A06E6E',
              },
            },
          }}
          type="text"
          size="small"
          autoComplete="off"
          value={textInputValue}
          onChange={onChangeTextInputValue}
          multiline
          rows={3}
          maxRows={3}
          focused
          autoFocus
        />

        <Button
          onClick={onSubmit}
          variant="contained"
          disabled={!textInputValue?.length}
          sx={{
            minWidth: '120px',
            fontFamily: 'Inter',
            fontSize: '14px',
            background: !textInputValue?.length
              ? 'inherit'
              : 'transparent linear-gradient(87deg, #FFB904 1.15%, #FF7000 47.11%, #FF403B 100%)',
            color: '#FFF',
          }}
        >
          {labelStrings.submit}
        </Button>
      </Stack>
    ) : (
      <>
        <Typography
          textAlign="center"
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Inter',
            color: '#442424',
            fontSize: '14px',
          }}
        >
          {labelStrings?.howLikelyRecommend}
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap={1}
        >
          <Grid container spacing={2} justifyContent="center">
            {boxes.map(box => (
              <Grid item key={box.number}>
                <Box
                  onClick={() => setScoreValue(box.number)}
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: box.color,
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderRadius: 1,
                    '&:hover': {
                      opacity: 0.7,
                    },
                    ml: box.number === 1 ? 2 : 0,
                    mr: box.number === 10 ? 2 : 0,
                  }}
                >
                  {box.number}
                </Box>
              </Grid>
            ))}
          </Grid>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              textAlign="center"
              sx={{
                fontFamily: 'Inter',
                color: '#442424',
                fontSize: '11px',
              }}
            >
              🙁 {labelStrings?.notAtAllLikely}
            </Typography>
            <Typography
              textAlign="center"
              sx={{
                fontFamily: 'Inter',
                color: '#442424',
                fontSize: '11px',
              }}
            >
              😊 {labelStrings?.extremelyLikely}
            </Typography>
          </Stack>
        </Box>
      </>
    )

  return (
    <Portal>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          position: 'fixed',
          top: currentLang === 'de' ? 'calc(100vh - 225px)' : 'calc(100vh - 205px)',
          left: '50%',
          transform: `translateX(-50%)`,
          backgroundColor: '#ffddd1',
          zIndex: 8999,
        }}
      >
        <Stack sx={{ position: 'relative' }} spacing={1}>
          {getContent}

          <Box sx={{ position: 'absolute', top: '-19px', right: '-14px' }}>
            <IconButton
              onClick={onClose}
              sx={{
                '&:hover': { opacity: 0.7 },
                padding: '4px',
              }}
              size="small"
              disableRipple
              disableTouchRipple
            >
              <CloseRoundedIcon sx={{ fontSize: '1rem', fill: '#442424' }} />
            </IconButton>
          </Box>
        </Stack>
      </Paper>
    </Portal>
  )
}
