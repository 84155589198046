import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
  Slider,
  Typography,
} from '@mui/material'
import { COMMA_SPLITTER, UNDERSCORE_SPLITTER } from '../../../../../constants/search'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import { getUniqueId } from '../utils'
import { infludataThemes } from '../../../../../themes/infludataThemes'
import { simulateResize } from '../../../../pages/Search/utils'

const formControlStyles = {
  m: 1,
  width: 300,
  '& .MuiOutlinedInput-root': {
    outline: 'unset',
    '& fieldset': {
      borderColor: '#BC9797',
    },
    '&:hover fieldset': {
      borderColor: '#784E4E',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#784E4E',
    },
  },
}
const inputLabelStyles = {
  color: '#784E4E',
  fontWeight: 'bold',
  '&.Mui-focused': {
    color: '#784E4E',
  },
}
const selectLabelStyles = {
  color: '#784E4E',
  fontWeight: 500,
  fontSize: '11px',
  textTransform: 'uppercase',
  fontFamily: 'Inter',
}

export const AudienceLanguageFilter = ({ selectedPlatform, query, handlersMap, isModalOpen }) => {
  const { languageOptions } = useTranslation()

  const audienceLanguages = query.audienceLanguages || ''

  const languages = useMemo(() => {
    if (!audienceLanguages) return []
    const _cArr = audienceLanguages.split(COMMA_SPLITTER)
    return _cArr.reduce((_arr, c) => {
      if (c.includes(UNDERSCORE_SPLITTER)) {
        const [language, range] = c.split(UNDERSCORE_SPLITTER)
        _arr.push({ language, range: parseInt(range) })
      }
      return _arr
    }, [])
  }, [audienceLanguages])

  const [selectCount, setSelectCount] = useState([])
  const [localSliderValue, setLocalSliderValue] = useState({})
  const [currHeight, setCurrHeight] = useState(120)

  const handleChange = (newValue, id) => {
    setLocalSliderValue(prev => ({
      // @ts-ignore
      ...prev,
      [id]: newValue,
    }))
  }

  const updateLanguage = languagesForUpdate => {
    if (!audienceLanguages && !languagesForUpdate.length) return

    const getAudienceLanguages = languagesForUpdate.reduce(
      (str, item) => `${str ? `${str},` : ''}${item.language}${UNDERSCORE_SPLITTER}${item.range}`,
      ''
    )

    handlersMap.audience({
      ...query,
      audienceLanguages: getAudienceLanguages,
    })
  }

  const onChangeQueryValues = (value, language, id) => {
    if (!language) return

    const updated = value
      ? languages.map(lang => (lang.language === language ? { ...lang, range: value } : lang))
      : languages.filter(lang => lang.language !== language)
    updateLanguage(updated)
  }

  const onChangeAudienceCountries = (prev, newLanguageObj) => {
    const newLanguageValue = newLanguageObj?.value || newLanguageObj
    const { language: prevLanguage, range: prevRange } = prev || {}

    if (!newLanguageObj || newLanguageValue === 'clear') {
      updateLanguage(languages.filter(lang => lang.language !== prevLanguage))
      return
    }

    if (languages?.some(lang => lang.language === newLanguageValue)) return

    if (prevLanguage === newLanguageValue) return

    let updatedLanguages = [...languages]

    // Find the index of the language to update
    const indexToUpdate = updatedLanguages.findIndex(lang => lang.language === prevLanguage)

    // If the previous language is found, update it
    if (indexToUpdate !== -1) {
      updatedLanguages[indexToUpdate] = { language: newLanguageValue, range: prevRange }
    } else {
      // If the previous language is not found, extend or trim the array to the required length
      while (updatedLanguages.length < selectCount?.length) {
        updatedLanguages.push({ language: newLanguageValue, range: 10 }) // Use newLanguageValue for new entries
      }
      if (updatedLanguages.length === 0) {
        updatedLanguages.push({ language: newLanguageValue, range: 10 }) // Add the first element if the array was initially empty
      }
    }

    updateLanguage(updatedLanguages)
  }

  const handleAddComponent = () => {
    if (selectCount.length < 3) {
      const id = getUniqueId()
      setSelectCount(prev => [...prev, id])
      setLocalSliderValue({ [id]: 0 })
    }
  }

  const handleRemoveComponent = index => {
    setSelectCount(prev => prev.filter((_, idx) => idx !== index))
    const languagesToRemove = languages[index]?.language
    if (languagesToRemove) {
      onChangeAudienceCountries(languagesToRemove, null)
    }
  }

  useEffect(() => {
    setCurrHeight(120 * selectCount?.length)
    simulateResize()
    if (!selectCount.length && audienceLanguages) {
      const initialVal = audienceLanguages.split(COMMA_SPLITTER).map(() => getUniqueId())
      setSelectCount(initialVal)
      const initialSliderValues = initialVal?.reduce((obj, key) => {
        obj[key] = 0
        return obj
      }, {})
      setLocalSliderValue(initialSliderValues)
    } else if (!selectCount?.length && !audienceLanguages) {
      const initialId = getUniqueId()
      setSelectCount([initialId])
      setLocalSliderValue({ [initialId]: 0 })
    }
  }, [audienceLanguages, selectCount.length])

  return (
    <Stack spacing={3} sx={{ minHeight: '120px', height: `${currHeight}px` }}>
      {selectCount.map((id, idx) => {
        const isLastOne = idx === selectCount.length - 1
        const selected = languages[idx] || {}
        const currentLanguage =
          languageOptions.find(el => el.value === selected.language)?.value || ''
        const currentSliderValue =
          localSliderValue[id] === 0 || !localSliderValue[id]
            ? selected?.range
            : localSliderValue[id]
        return (
          <CurrentSelect
            key={`${id}-${currentLanguage}-${selected.range}`}
            currentLanguage={currentLanguage}
            onChangeAudienceCountries={onChangeAudienceCountries}
            selected={selected}
            languageOptions={languageOptions}
            selectCount={selectCount}
            currentSliderValue={currentSliderValue}
            isLastOne={isLastOne}
            handleAddComponent={handleAddComponent}
            handleRemoveComponent={handleRemoveComponent}
            handleChange={handleChange}
            onChangeQueryValues={onChangeQueryValues}
            isModalOpen={isModalOpen}
            selectedPlatform={selectedPlatform}
            languages={languages}
            id={id}
            idx={idx}
          />
        )
      })}
    </Stack>
  )
}

const CurrentSelect = props => {
  const {
    currentLanguage,
    onChangeAudienceCountries,
    selected,
    languageOptions,
    selectCount,
    currentSliderValue,
    isLastOne,
    handleAddComponent,
    handleRemoveComponent,
    handleChange,
    onChangeQueryValues,
    isModalOpen,
    selectedPlatform,
    id,
    idx,
    languages,
  } = props || {}

  const { labelStrings } = useTranslation()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (isModalOpen && selectCount?.length === 1) {
  //       setOpen(true)
  //     } else {
  //       // setOpen(false)
  //     }
  //   }, 200)

  //   return () => clearTimeout(timer)
  // }, [])

  return (
    <Stack spacing={0}>
      <Stack direction="row" alignItems="center" sx={{ width: '280px' }}>
        <FormControl sx={formControlStyles}>
          <InputLabel sx={inputLabelStyles} size="small">
            {labelStrings.selectLanguage}
          </InputLabel>
          <Select
            open={open}
            onClose={handleClose}
            onOpen={() => setOpen(true)}
            size="small"
            value={currentLanguage}
            onChange={e => onChangeAudienceCountries(selected, e.target.value)}
            input={<OutlinedInput label={labelStrings.selectLanguage} />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                },
              },
            }}
          >
            {languageOptions.map(lang => (
              <MenuItem
                key={lang.value}
                value={lang.value}
                disabled={languages?.some(el => el.language === lang.value)}
              >
                {lang.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {idx < 2 && (
          <IconButton
            onClick={isLastOne ? handleAddComponent : () => handleRemoveComponent(idx)}
            disabled={isLastOne && selectCount.length >= 3}
          >
            {isLastOne ? (
              <AddRoundedIcon
                sx={{
                  fill: selectCount.length >= 3 ? '#637381' : '#784E4E',
                }}
              />
            ) : (
              <RemoveRoundedIcon sx={{ fill: '#784E4E' }} />
            )}
          </IconButton>
        )}
      </Stack>
      <Stack sx={{ px: 2 }}>
        <Typography sx={selectLabelStyles}>{labelStrings.minAudienceUsingLanguage}</Typography>
        <Slider
          disabled={!currentLanguage}
          sx={{
            mt: '-8px',
            width: '100%',
            height: '5px',
            borderRadius: '8px',
            color: infludataThemes[selectedPlatform]?.platformColor,
            '& .MuiSlider-valueLabelOpen': {
              fontWeight: 'bold',
              color: infludataThemes[selectedPlatform]?.platformColor,
            },
            '& .MuiSlider-valueLabel': {
              background: 'unset',
              transformOrigin: 'bottom left',
              transform: 'translate(50%, 100%) rotate(45deg) scale(0)',
              '&::before': { display: 'none' },
              '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -40%) rotate(-225deg) scale(1)',
              },
              '& > *': {
                transform: 'rotate(-135deg)',
              },
            },
          }}
          value={currentSliderValue}
          onChange={(e, value) => handleChange(value, id)}
          onChangeCommitted={(e, value) => onChangeQueryValues(value, currentLanguage, id)}
          valueLabelDisplay="on"
          valueLabelFormat={val => (val === 0 ? 'Off' : `${Math.abs(val)}%`)}
          min={0}
          max={90}
        />
      </Stack>
    </Stack>
  )
}
