import React, { useEffect } from 'react'
import { DashboardView } from './DashboardView'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  allCampaignsSelector,
  canAddToCollectionDynamicSelector,
  collectionsSelector,
  currentSearchQuerySelector,
  currentSuggestionDashboardData,
  currentSuggestionLoading,
  noRerenderEqFn,
  searchDataSelector,
  suggestedCreatorsArraySelector,
} from '../../redux/selectors'
import {
  getLookalikeLists,
  getSuggestedUsersByIdForSearchDashboard,
} from '../../redux/ducks/exploreDucks'

export const DashboardContainer = props => {
  const {
    organizationCurrency,
    isUserAuthenticated,
    enable_collections,
    infludataExpertsArray,
    availableExpertCredits,
  } = props || {}
  const dispatch = useDispatch()

  const { campaignArray } = useSelector(allCampaignsSelector)
  const suggestionDashboardData = useSelector(currentSuggestionDashboardData)
  const isLoadingSuggestion = useSelector(currentSuggestionLoading)
  const collectionsList = useSelector(collectionsSelector, shallowEqual)
  const canAddToCollectionDynamic = useSelector(canAddToCollectionDynamicSelector, noRerenderEqFn)
  const lookalikeListsData = useSelector(suggestedCreatorsArraySelector)
  const currentQuery = useSelector(currentSearchQuerySelector, shallowEqual)
const { tempBrandMentionsData } = useSelector(searchDataSelector) || {}

  const canAddToFavs = canAddToCollectionDynamic({
    collectionId: 'fav',
  })

  useEffect(() => {
    // @ts-ignore
    dispatch([
      // @ts-ignore
      getSuggestedUsersByIdForSearchDashboard({ queryId: 'forDashboard' }),
      getLookalikeLists(),
    ])
  }, [])

  const onLoadMoreCreatorsYouMightLike = (page, cb) => {
    // @ts-ignore
    dispatch(getSuggestedUsersByIdForSearchDashboard({ queryId: 'forDashboard', page, withoutLoadingIndicator: true, cb }))
  }

  const onRenewCreatorsList = (page, listIdToRemove) => {
    dispatch(
      // @ts-ignore
      getSuggestedUsersByIdForSearchDashboard({ queryId: 'forDashboard', page, listIdToRemove })
    )
  }

  return (
    <DashboardView
      campaignArray={campaignArray}
      suggestionDashboardData={suggestionDashboardData}
      isLoadingSuggestion={isLoadingSuggestion}
      organizationCurrency={organizationCurrency}
      canAddToFavs={canAddToFavs}
      isUserAuthenticated={isUserAuthenticated}
      enable_collections={enable_collections}
      collectionsList={collectionsList}
      infludataExpertsArray={infludataExpertsArray}
      availableExpertCredits={availableExpertCredits}
      lookalikeListsData={lookalikeListsData}
      currentQuery={currentQuery}
      tempBrandMentionsData={tempBrandMentionsData}
      onLoadMoreCreatorsYouMightLike={onLoadMoreCreatorsYouMightLike}
      onRenewCreatorsList={onRenewCreatorsList}
    />
  )
}
