import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { searchContentTypes } from '../../constants/search'
import { useDispatch, useSelector } from 'react-redux'
import { isUserAuthenticatedSelector } from '../../redux/selectors'
import { Stack, Button, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/system'
import useTranslation from '../../localization/useTranslation'
import { BrandIcon, ContentIcon, CreatorIcon } from '../common/icons/icons'
import { bgGradients } from '../../new-ui/components/nav-section/CreatorsSuggestions/styles'
import { changeAppSocialPlatform } from '../../redux/ducks/appSettingsDucks'
import { updateSearch } from '../../redux/ducks/searchDucks'
import { USER_INDEXES } from '../../constants/appSettings'

export const StyledBtn = styled(Button)(({ is_active, userindex }) => ({
  width: '60px',
  minWidth: '60px',
  '& .MuiTypography-root': {
    fontSize: '11px',
    fontWeight: 700,
    fontFamily: 'Inter',
    color: is_active === 'true' ? '#FFFFFF' : '#442424',
    '&:disabled': {
      color: '#442424',
    },
  },

  '& svg': {
    color: is_active === 'true' ? '#FFFFFF' : '#442424',
    '&:disabled': {
      color: '#442424',
    },
  },

  height: '55px',
  background: is_active === 'true' ? bgGradients[userindex?.toUpperCase()] : '#F6DBDB',
  '&:hover': {
    background: is_active === 'true' ? bgGradients[userindex?.toUpperCase()] : '#F6DBDB',
    color: is_active === 'true' ? '#FFFFFF' : '#442424',
    filter: 'brightness(95%)',
  },
  '&:disabled': {
    opacity: 0.5,
    color: '#442424',
    backgroundColor: '#F6DBDB',
    background: '#F6DBDB',
  },
}))

export const ToggleSwitchSearchContent = props => {
  const { checked = '', onChange, isContentSearch = false, userindex } = props

  const dispatch = useDispatch()
  const { labelStrings } = useTranslation()
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)

  const onSwitchToBrandType = () => {
    onChange(searchContentTypes.BRAND)
    const socialType = userindex === USER_INDEXES.youtube ? USER_INDEXES.instagram : userindex
    // @ts-ignore
    dispatch([
      changeAppSocialPlatform(socialType?.toUpperCase()),
      updateSearch({
        newValuesObj: {
          index: socialType,
          isSimilarContentPieces: false,
          skipCount: 0,
        },
      }),
    ])
  }

  useEffect(() => {
    if (!isContentSearch && checked === searchContentTypes.CONTENT) {
      onChange()
    }
  }, [checked, isContentSearch])

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <StyledBtn
        onClick={() => onChange(searchContentTypes.CREATOR)}
        disabled={!isUserAuthenticated}
        is_active={`${checked === searchContentTypes.CREATOR}`}
        variant="contained"
        userindex={userindex}
      >
        <Stack alignItems="center" spacing={0.5}>
          <CreatorIcon />
          <Typography>{labelStrings.creator}</Typography>
        </Stack>
      </StyledBtn>
      <StyledBtn
        onClick={onSwitchToBrandType}
        disabled={!isUserAuthenticated}
        is_active={`${checked === searchContentTypes.BRAND}`}
        userindex={userindex}
        variant="contained"
        sx={{ lineHeight: '1.2' }}
      >
        <Stack alignItems="center" spacing={0.5}>
          <BrandIcon />
          <Typography>{labelStrings.brand}</Typography>
        </Stack>
      </StyledBtn>
      <Tooltip
        title={!isContentSearch ? labelStrings.disabledFunctionInYourPlanTip : ''}
        arrow
        placement="bottom-start"
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: '250px',
              fontSize: '13px',
              fontFamily: 'Inter',
              p: 1,
              px: 2,
              backgroundColor: '#F6DBDB',
              color: 'black',
              '& .MuiTooltip-arrow': {
                color: '#F6DBDB',
              },
            },
          },
        }}
      >
        <div>
          <StyledBtn
            onClick={() => onChange(searchContentTypes.CONTENT)}
            disabled={!isUserAuthenticated || !isContentSearch}
            is_active={`${checked === searchContentTypes.CONTENT}`}
            variant="contained"
            userindex={userindex}
          >
            <Stack alignItems="center" spacing={0.5}>
              <ContentIcon />
              <Typography>{labelStrings.content}</Typography>
            </Stack>
          </StyledBtn>
        </div>
      </Tooltip>
    </Stack>
  )
}

ToggleSwitchSearchContent.propTypes = {
  checked: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isContentSearch: PropTypes.bool,
}
