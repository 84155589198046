import React, { memo, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  fetchSearchResults,
  updateSearchTypeStatus,
  updateSearch,
  clearSearch,
  setActiveMapMarker,
  changeSuggestionsData,
  getSearchBySmartText,
  resetSearchResults,
  changeTempBrandMentionsData,
  setSearchResults,
} from '../redux/ducks/searchDucks'
import {
  searchResultsSelector,
  searchLoadingStatusSelector,
  userCredsLoadProgressSelector,
  isUserAuthenticatedSelector,
  userGrantSelector,
  currentSearchQuerySelector,
  searchTypeSelector,
  selectedPlatformSelector,
  searchSuggestionsReceiveSurveyResultSelector,
  userCredsSelector,
  currentSuggestionUsersDataSelector,
  currentSuggestionsAdditionalDataSelector,
} from '../redux/selectors'
import { Stack, Box } from '@mui/material'
import SearchPagePagination from '../components/pagination/SearchPagePagination'
import {
  Layout,
  MainContainer,
  SearchListHeader,
  ListWrapper,
} from '../components/common/styledWrappers'
import SearchResultsPageTip from '../components/pagesTips/SearchResultsPageTip'
import { changeAdditionalFieldValue } from '../redux/ducks/additionalFieldsDucks'
import { searchContentTypes } from '../constants/search'
import { ProfileContentTable } from '../components/profileCommon/profileListTable/profileContentUser/ProfileContentTable'
import {
  _CREATOR_BASE_TYPE,
  _LOOKALIKE_TYPE,
  NAVBAR_FOOTER_SUM_HEIGHT,
  USER_INDEXES,
} from '../constants/appSettings'
import { MapContent } from '../components/profileCommon/profileListTable/profileContentUser/MapContent'
import { SkeletonLoader } from '../components/profileCommon/profileListTable/SkeletonLoader'
import { SearchSmartBarContainer } from '../new-ui/pages/Search/SearchSmartBar'
import { SearchTableContainer } from '../new-ui/pages/Search/Table/SearchTableContainer'
import SidebarContainer from '../new-ui/components/search-sidebar/SidebarContainer'
import urlQueryService from '../services/urlQueryService'
import { ReceiveSurveyResultDialog } from '../new-ui/pages/Search/ReceiveSurveyResultDialog'
import { PlanInactiveModal } from '../new-ui/components/no-plan-modal/PlanInactiveModal'
import { NoPlanModal } from '../new-ui/components/no-plan-modal/NoPlanModal'
import { cancelAllFetches } from '../components/sidebar/searchSidebar/searchbarTipsFetches'
import { defaultSearchQuery } from '../new-ui/pages/Search/utils'
import { LookalikesButton } from '../new-ui/pages/Search/Lookalikes/LookalikesButton'
import {
  areSearchQueriesEqualFroSuggestions,
  transformValuesToNumberOrBoolean,
} from '../utils/search'
import { changeSuggestionSelectedData } from '../redux/ducks/exploreDucks'
import { DashboardContainer } from '../new-ui/dashboard/DashboardContainer'
import { ModalScore } from '../new-ui/components/modal-score'

const uploadFromRegex = /uploadedFrom=([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
const uploadUploadTo = /uploadedTo=([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
const filteredByUserId = ({ array = [], userId = '' }) => array?.filter(user => user._id !== userId)

const SearchResultsPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const searchResults = useSelector(searchResultsSelector)
  const searchType = useSelector(searchTypeSelector)
  const isSearchLoading = useSelector(searchLoadingStatusSelector)
  const isPageLoading = useSelector(userCredsLoadProgressSelector)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const {
    no_plan,
    content_search,
    enable_ig_search,
    enable_yt_search,
    enable_tt_search,
    is_self_service,
    enable_collections,
  } = useSelector(userGrantSelector, shallowEqual)
  const {
    showNoPlanModal,
    organizationCurrency,
    infludataExpertsArray,
    availableExpertCredits,
    showNpcScoreRequest,
  } = useSelector(userCredsSelector) || {}
  const currentQuery = useSelector(currentSearchQuerySelector, shallowEqual)
  const currentPlatform = useSelector(selectedPlatformSelector)
  const { showReceiveSurveyResult } = useSelector(
    searchSuggestionsReceiveSurveyResultSelector,
    shallowEqual
  )
  const {
    userdata: lookalikesData,
    isLoading: lookalikesLoading,
    label: lookalikeLabel,
    creatorBase,
  } = useSelector(currentSuggestionUsersDataSelector, shallowEqual) || {}
  const { formattedString, reference, id: lookalikeId } =
    useSelector(currentSuggestionsAdditionalDataSelector) || {}

  const params = new URLSearchParams(location?.search)
  const isActionLookaLike = params.get('action') === 'lookalike'
  const isContentSearchFromQuery = params.get('fromContentSearch') === 'true'
  const isBrandSearchFromQuery = params.get('businessSearch') === 'true'

  const latestQuery = urlQueryService.parseCurrentSearchQuery(location, currentQuery)

  const defaultQuery =
    searchType === searchContentTypes.CONTENT
      ? { ...defaultSearchQuery, fromContentSearch: true }
      : defaultSearchQuery

  const isSearchQueryTheSame = areSearchQueriesEqualFroSuggestions(
    transformValuesToNumberOrBoolean(currentQuery),
    defaultQuery
  )

  const [currentOpenSearchParam, setCurrentOpenSearchParam] = useState('')

  const [showMap, setShowMap] = useState(false)
  const [activeCard, setActiveCard] = useState({})

  const onChangeSearchType = type => {
    if (!isUserAuthenticated) return

    const actions = [clearSearch(), changeSuggestionsData({}), updateSearchTypeStatus(type)]

    if (type === searchContentTypes.CREATOR) {
      actions.push(
        getSearchBySmartText({
          inputParam: null,
          platform: null,
          method: 'init',
          withoutSearchLoading: true,
        })
      )
      actions.push(
        fetchSearchResults({
          ...currentQuery,
          businessSearch: false,
        })
      )
    }

    if (type === searchContentTypes.CONTENT) {
      actions.push(updateSearch({ newValuesObj: { fromContentSearch: true } }))
    }

    if (type === searchContentTypes.BRAND) {
      actions.push(
        updateSearch({
          newValuesObj: {
            businessSearch: true,
          },
        })
      )
    }
    dispatch(actions)
  }

  const { users, count, dataSuccess, content } = searchResults || {}

  const [lookalikeTypeData, setLookalikeTypeData] = useState(_LOOKALIKE_TYPE)

  const setSearchUsers = (data, dataCount) => {
    dispatch(
      setSearchResults({
        results: {
          users: data,
          count: dataCount || data?.length,
          content: [],
          dataSuccess,
        },
      })
    )
  }

  const isLookalike = !!(lookalikesData?.length && creatorBase?.userdata?.length)
  const isLookaLikeList = !!lookalikesData?.length && lookalikeTypeData === _LOOKALIKE_TYPE

  const isDownloadSearchResults = count <= 500

  const filteredContentForMap = content?.filter(el => el.locationArray?.length)

  const resultsExist = !!users?.length || !!content?.length

  const onBlockUnblockUser = async ({ profileId, isBlack }) => {
    const filteredUsers = filteredByUserId({
      array: !!users?.length ? users : users,
      userId: profileId,
    })
    setSearchUsers(filteredUsers)

    await dispatch(
      changeAdditionalFieldValue({
        profileId: profileId,
        changeField: 'isBlack',
        valueObj: `${!isBlack}`,
      })
    )
  }

  useEffect(() => {
    if (location?.search && (isContentSearchFromQuery || isBrandSearchFromQuery)) {
      const isTypePosts = isContentSearchFromQuery && location?.search?.includes('posts') && 'posts'
      const isTypeReels = isContentSearchFromQuery && location?.search?.includes('reels') && 'reels'
      const isTypeStories =
        isContentSearchFromQuery && location?.search?.includes('stories') && 'stories'
      const isUploadFrom = isContentSearchFromQuery && location?.search?.match(uploadFromRegex)
      const isUploadTo = isContentSearchFromQuery && location?.search?.match(uploadUploadTo)

      const contentTypesValues = [isTypePosts, isTypeStories, isTypeReels]?.filter(Boolean)?.join()

      dispatch(
        // @ts-ignore
        updateSearchTypeStatus(
          isContentSearchFromQuery ? searchContentTypes.CONTENT : searchContentTypes.BRAND
        )
      )

      dispatch(
        // @ts-ignore
        updateSearch({
          newValuesObj: {
            contentTypes: contentTypesValues,
            uploadedFrom: !!isUploadFrom?.length ? isUploadFrom[1] : '',
            uploadedTo: !!isUploadTo?.length ? isUploadTo[1] : '',
          },
        })
      )
    }
  }, [content_search, isContentSearchFromQuery, isBrandSearchFromQuery])

  useEffect(() => {
    if (isSearchLoading) return

    if (
      (enable_ig_search || enable_tt_search || enable_yt_search) &&
      !users?.length &&
      !isActionLookaLike
    ) {
      dispatch(
        // @ts-ignore
        updateSearch({
          newValuesObj: latestQuery,
        })
      )
    }
  }, [enable_ig_search, enable_tt_search, enable_yt_search, isActionLookaLike])

  const onHandleShowMap = () => {
    setShowMap(!showMap)
    !showMap && dispatch(setActiveMapMarker({}))
  }

  const clearSearchMain = () => {
    cancelAllFetches()
    // @ts-ignore
    dispatch([
      updateSearchTypeStatus(searchContentTypes.CREATOR),
      resetSearchResults(null, { searchType }),
      changeSuggestionsData({}),
      changeTempBrandMentionsData({}),
      changeSuggestionSelectedData({}),
    ])
    localStorage?.removeItem('searchQuery')
    urlQueryService.setNewSearchQuery({
      latestQuery: defaultSearchQuery,
    })
    setSearchUsers([])
    setLookalikeTypeData(_LOOKALIKE_TYPE)
  }

  const onChangeLookalikesData = () => {
    if (!lookalikesData?.length) return
    setLookalikeTypeData(_LOOKALIKE_TYPE)
    setSearchUsers(lookalikesData)
  }

  const onChangeCreatorsData = () => {
    if (!creatorBase?.userdata?.length) return
    setLookalikeTypeData(_CREATOR_BASE_TYPE)
    setSearchUsers(creatorBase?.userdata)
  }

  // const onOpenSuggestionsMain = (users) => {
  //   cancelAllFetches();
  //   const usersData = lookalikesData?.length ? lookalikesData : users;
  //   setSearchUsers(usersData);
  // }

  if (no_plan && isUserAuthenticated) {
    return showNoPlanModal ? (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }} />
    ) : (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }}>
        {is_self_service ? <NoPlanModal /> : <PlanInactiveModal />}
      </Box>
    )
  }

  return (
    <Layout>
      <SidebarContainer
        searchType={searchType}
        onChangeSearchType={onChangeSearchType}
        currentOpenSearchParam={currentOpenSearchParam}
        setCurrentOpenSearchParam={setCurrentOpenSearchParam}
        withSearch
      />

      <MainContainer
        id="searchResults"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight:
            isSearchLoading || lookalikesLoading
              ? `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)`
              : '100%',
          padding: '0 20px',
          overflowY: isSearchLoading || lookalikesLoading ? 'hidden' : 'auto',
          overflowX: 'hidden',
        }}
      >
        {isUserAuthenticated && (
          <SearchSmartBarContainer
            searchType={searchType}
            setCurrentOpenSearchParam={setCurrentOpenSearchParam}
            searchUsers={users}
            currentPlatform={currentPlatform}
            currentQuery={currentQuery}
            clearSearchMain={clearSearchMain}
            isSearchQueryTheSame={isSearchQueryTheSame}
            isLookalike={isLookalike}
            lookalikeId={lookalikeId}
          />
        )}

        {isSearchLoading || lookalikesLoading ? (
          <Box>
            <SkeletonLoader searchType={searchType} />
          </Box>
        ) : (
          <>
            {searchType !== searchContentTypes.CONTENT && (
              <>
                {isLookalike && (
                  <LookalikesButton
                    reference={reference}
                    formattedString={formattedString}
                    currentPlatform={currentPlatform}
                    lookalikesData={lookalikesData}
                    lookalikeTypeData={lookalikeTypeData}
                    creatorBase={creatorBase}
                    onChangeLookalikesData={onChangeLookalikesData}
                    onChangeCreatorsData={onChangeCreatorsData}
                    lookalikeId={lookalikeId}
                    lookalikeLabel={lookalikeLabel}
                  />
                )}
                <SearchListHeader>
                  {!!count && !isLookalike && (
                    <SearchPagePagination
                      searchType={searchType}
                      isDownloadSearchResults={isDownloadSearchResults}
                      withResultsCount
                    />
                  )}
                </SearchListHeader>
              </>
            )}
            {resultsExist && (
              <ListWrapper hasPagination>
                {resultsExist && searchType !== searchContentTypes.CONTENT ? (
                  <SearchTableContainer
                    isLoading={lookalikesLoading || isSearchLoading}
                    data={users}
                    onBlockUnblockUser={onBlockUnblockUser}
                    currentPlatform={currentPlatform}
                    currentQuery={currentQuery}
                    isLookalike={isLookalike}
                    isLookaLikeList={isLookaLikeList}
                    setSearchUsers={setSearchUsers}
                    countResults={count}
                    searchType={searchType}
                  />
                ) : (
                  <Stack
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.30)',
                      borderRadius: '24px',
                      py: 1,
                      px: 2,
                      mt: 2,
                    }}
                  >
                    {isUserAuthenticated && searchType === searchContentTypes.CONTENT && (
                      <SearchListHeader isShowMap={showMap} isContent>
                        {!!content?.length && (
                          <SearchPagePagination
                            withResultsCount
                            searchType={searchContentTypes.CONTENT}
                            platform={currentPlatform.toLowerCase()}
                            isShowMap={showMap}
                            onHandleShowMap={onHandleShowMap}
                          />
                        )}
                        {showMap && currentPlatform.toLowerCase() === USER_INDEXES.instagram && (
                          <MapContent
                            filteredContentForMap={filteredContentForMap}
                            activeCard={activeCard}
                          />
                        )}
                      </SearchListHeader>
                    )}
                    <ProfileContentTable
                      content={content}
                      isSearchLoading={isSearchLoading}
                      setActiveCard={setActiveCard}
                      activeCard={activeCard}
                      currentQuery={currentQuery}
                    />
                  </Stack>
                )}
                {!!count && !isLookalike && (
                  <SearchPagePagination onlyPagination searchType={searchType} isBottom />
                )}
              </ListWrapper>
            )}
            {!resultsExist && !isSearchLoading && !isPageLoading && (
              <>
                {isUserAuthenticated && isSearchQueryTheSame ? (
                  <DashboardContainer
                    organizationCurrency={organizationCurrency}
                    isUserAuthenticated={isUserAuthenticated}
                    enable_collections={enable_collections}
                    infludataExpertsArray={infludataExpertsArray}
                    availableExpertCredits={availableExpertCredits}
                  />
                ) : (
                  <SearchResultsPageTip
                    noResults={!dataSuccess}
                    isUserAuthenticated={isUserAuthenticated}
                    noPlan={no_plan}
                    platform={currentPlatform}
                  />
                )}
              </>
            )}
          </>
        )}
        {showNpcScoreRequest && <ModalScore />}
        {showReceiveSurveyResult && !showNpcScoreRequest && <ReceiveSurveyResultDialog />}
      </MainContainer>
    </Layout>
  )
}

export default memo(SearchResultsPage)
