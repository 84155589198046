import styled from 'styled-components'
import { Link } from 'react-router-dom'

// PROFILE LIST TABLE BASIC STYLES
export const TableWrapper = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  height: 100%;
  /* min-height: 86vh; */
  background-color: ${props => props.theme.colors.offWhite};
  border-radius: ${props => props.isListMode ? '13px 13px 0 0' : '13px'};
  ${props => props.isListMode &&  props.theme.scrollbarStyles}
  ${props =>
    props.isListMode &&
    `
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0px 0px 13px 13px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #784E4E;
      border-radius: 5px;
      border: 2px solid #EED9D9;
    }
    &::-webkit-scrollbar-thumb:hover {
      border: 0px solid #f4f4f4;
    }
    &::-webkit-scrollbar-track-piece:start {
      margin-left: 10px;
    }
    &::-webkit-scrollbar-track-piece:end {
      margin-right: 10px;
    }
  `}
`
export const Table = styled.table`
  width: 100%;
  table-layout: auto;

  tbody {
    tr:first-child {
      td {
        padding: 5px 0;
        padding-top: 10px;
      }
    }

    tr:nth-last-child(2) {
      td {
        border-bottom: none;
      }
    }

    tr {
      td:first-child {
        padding-left: 25px;
        position: relative;
      }

      td:last-child {
        padding-right: 15px;
      }
    }
  }
`
export const TableRow = styled.tr`
  position: relative;
  padding-bottom: 13px;
`
export const Cell = styled.span`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1.1px;
  color: #888888;
  text-transform: uppercase;
`
export const HeaderCell = styled.td`
  position: relative;
  line-break: normal;
  border-bottom: 0.5px solid ${props => props.theme.colors.mediumBrown};
  vertical-align: middle;
  padding-bottom: 5px;
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.mauve};
  text-transform: uppercase;
  ${props => props.withWidth && `width: ${props.withWidth}px`}
  ${props =>
    props.withRightPadding &&
    `
    padding-right: 10px;
  `}
`
export const RowCell = styled(HeaderCell)`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  position: relative;
  text-transform: unset;
  letter-spacing: unset;
  padding: ${props => props.isListView ? 0 : '5px'};
  border-bottom: 0.5px solid ${props => props.theme.colors.lightBrown};

  .profile-avatar_container {
    .overlay {
      width: unset !important;
      height: unset !important;

      .overlay-inner {
        transition: opacity 100ms linear;
        width: 81px;
        height: 81px;
      }
    }
  }

  .star-ratings {
    justify-content: flex-start !important;
  }
`
export const ProfileRow = styled.tr`
  height: ${props => props.isList ? '48px' : '100px'};
  padding-top: 14px;
  padding-bottom: 14px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.lightMauve};

    .profile-avatar_container {
      .overlay {
        .overlay-inner {
          opacity: 1;
        }
      }
    }
  }
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`
export const DropdownedProfileRow = styled(ProfileRow)`
  border: none;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  padding: ${props => (props.isOpened ? '14px 0px' : '0')};
  position: relative;
  height: unset;
  transition: all 0.5s ease;
  max-height: ${props => (props.isOpened ? '300000000px' : '0px')};
`
export const DropdownedProfileCell = styled(RowCell)`
  border: none;
  padding: 0 !important;
`
export const DropdownedProfileCellContent = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: unset;
  overflow: ${props => (props.isOpened ? 'unset' : 'hidden')};
  transition: all 0.3s ease;
  max-height: ${props => (props.isOpened ? '300000000px' : '0px')};
`
export const PayAttention = styled.div`
  color: ${props => props.theme.color.darkGrey};
`
export const TableTipWrapper = styled.div`
  & * {
    text-transform: initial;
    font-family: ${props => props.fontFamilyInter};
    letter-spacing: initial;
    line-height: initial;
  }
`
export const HeaderTextWrapper = styled.div`
  width: fit-content;
  position: relative;
`
export const Username = styled.div`
  font-weight: bold;
  position: relative;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  padding-right: 10px;
  color: ${props => props.theme.getColorPaletteForPlatform(props.userindex).main};

  ${props =>
    props.applymocked &&
    `
    display: block;    
    background: ${props.theme.color.midGrey};
    width: 60%;
    margin: 10px auto;
    height: 14px;
  `}

  @media screen and (max-width: 1200px) {
    width: 140px;
  }
`
export const DisplayName = styled.div`
  font-weight: bold;
  position: relative;
  color: ${props => props.theme.getColorPaletteForPlatform(props.userindex).main};
  text-decoration: none;

  ${props =>
    props.applymocked &&
    `
    display: block;    
    background: ${props.theme.color.midGrey};
    width: 60%;
    margin: 10px auto;
    height: 14px;
  `}
`
export const SmallText = styled.small`
  ${props =>
    props.applyMocked &&
    `
    display: block;    
    background: ${props.theme.color.midGrey};
    width: 60%;
    margin: 0 auto;
    height: 10px;
  `}
`
export const BoldText = styled.span`
  font-weight: 600;
  font-size: 15px;
  ${props =>
    props.asBlock &&
    `
    display: block;
    width: fit-content;
    position: relative;
  `}
  ${props =>
    props.applyMocked &&
    `
    display: block;    
    background: ${props.theme.color.midGrey};
    width: 60%;
    margin: 0 auto;
    height: 14px;
  `}
`
export const PayAttentionText = styled(BoldText)`
  color: ${props =>
    props.applyMocked
      ? props.theme.color.midGrey
      : props.theme.getColorPaletteForPlatform(props.userindex).main};
`
export const RowLink = styled(Link)`
  text-decoration: none;
  color: unset;
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
    color: unset;
  }
`
export const PrivateProfileWarnCell = styled.div`
  background: ${props => props.theme.pickColorDependOnPlatform(props.userindex).light};
  text-align: center;
  ${props => props.theme.flex.centerAll};
  font-size: 12px;
  width: max-content;
  max-width: 185px;
  padding: 2px 8px;
  margin-bottom: 5px;
  border-radius: 5px;
  font-family: ${props => props.theme.fontFamilyInter};
`
export const LinkContainer = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${props => props.over && '2'};
`
export const CheckboxWrapper = styled.div`
  position: relative;
`
export const YouTubeCreatorLabelWrapper = styled.div`
  display: flex;
  .profile-avatar_container {
    margin-left: 10px;
  }
  .yt-icon {
    display: block;
    width: 34px;
    height: 34px;
  }
`
export const YouTubeIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const TextWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  ${props =>
    props.hasBothValues &&
    `
    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
    `}
`
export const YTStatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
`
export const YTShortsIconWrapper = styled.div`
  position: absolute;
  left: -24px;
  ${props =>
    props.hasCheckbox &&
    `
    left: -28px;
  `}
`
export const AdditionalFieldDetailsOverlay = styled.div`
  position: absolute;
  bottom: 15px;
  left: 100px;
  width: 325px;
  background-color: ${props => props.theme.colors.offWhite};
  border: 2px solid ${props => props.theme.colors.mediumBrown};
  border-radius: 17px;
  padding: 0 5px
  z-index: 9999;
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const AdditionalFieldDetailsOverlayForDashboard = styled.div`
  position: absolute;
  bottom: -80px;
  left: -80px;
  width: 325px;
  background-color: ${props => props.theme.colors.offWhite};
  border: 2px solid ${props => props.theme.colors.mediumBrown};
  border-radius: 18px;
  padding: 0 5px
  z-index: 9999;
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  position: relative;
`
export const ProfileImagesWrapper = styled.div`
  display: flex;
  max-width: 310px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`
export const ProfileImgWrapper = styled.div`
  padding: 4px;
`
export const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 260px);
`

export const RowWrapper = styled.div`
  min-width: 145px;
  max-width: ${props => props.customMaxWidth ? '240px'  : '160px'};
  width: ${props => props.customWidth ? '100%' : 'max-content'};
  padding-right: ${props => props.lastEl ? '0' : props.largeGap ? '50px' : '25px'}
`
export const TableVerifiedWrapper = styled.div`
  position: relative;
`
export const IconVerifiedWrapper = styled.div`
  position: absolute;
  top: -6px;
  left: 6px;
`