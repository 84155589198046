import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { COLLECTIONS_ACCESS_TYPES_NEW } from '../../../constants/collections'
import { MAX_NAME_LIMIT, MAX_DESCRIPTION_LIMIT, USER_INDEXES } from '../../../constants/appSettings'
import { withReduxActions } from '../../../hocs/withRedux'
import { createNewCollection, editCollection } from '../../../redux/ducks/collectionsDucks'

import Input from '../../common/inputs/Input'
import Textarea from '../../common/textarea/Textarea'
import Spinner from '../../common/spinners/Spinner'
import { GradientButton } from '../../common/buttons/styledButtons'
import { EditCollectionModalWrapper, FlexRow } from './commonStyles'

import { Stack, Typography, Select, Box, FormControl, MenuItem } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { ModalComponent } from '../../../new-ui/components/modal/ModalComponent'
import { useSelector } from 'react-redux'
import { userCredsSelector } from '../../../redux/selectors'

const EditCollectionModal = ({
  collectionData = {},
  isNewCollection = false,
  platform = '',
  closeModal,
  createNewCollection,
  editCollection,
  isOpened = false,
  profile,
  source
}) => {
  const { labelStrings, errors } = useTranslation()

  const { organizationName } = useSelector(userCredsSelector)

  const accessValuesArr = [
    {
      value: COLLECTIONS_ACCESS_TYPES_NEW.organization,
      label: organizationName || COLLECTIONS_ACCESS_TYPES_NEW.organization,
    },
    {
      value: COLLECTIONS_ACCESS_TYPES_NEW.restricted,
      label: COLLECTIONS_ACCESS_TYPES_NEW.restricted,
    },
    {
      value: COLLECTIONS_ACCESS_TYPES_NEW.anyone,
      label: COLLECTIONS_ACCESS_TYPES_NEW.anyone,
    },
  ]

  const accessDescription = {
    [COLLECTIONS_ACCESS_TYPES_NEW.organization]:
      labelStrings.collectionsAccessDescriptions.organization,
    [COLLECTIONS_ACCESS_TYPES_NEW.restricted]:
      labelStrings.collectionsAccessDescriptions.restricted,
    [COLLECTIONS_ACCESS_TYPES_NEW.anyone]: labelStrings.collectionsAccessDescriptions.anyone,
  }

  const [collectionState, setCollectionState] = useState({
    collectionId: collectionData?.collectionId,
    name: collectionData?.name,
    description: collectionData?.description,
    access: COLLECTIONS_ACCESS_TYPES_NEW.organization,
  })
  const [collectionNameError, setCollectionNameError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onChangeCollectionName = event => {
    setCollectionNameError('')
    setCollectionState({ ...collectionState, name: event.target.value })
  }

  const onChangeCollectionDescription = event =>
    setCollectionState({ ...collectionState, description: event.target.value })

  const onChangeCollectionAccess = ({ target }) => {
    const newAccess = target.value
    setCollectionState({ ...collectionState, access: newAccess })
  }

  const onCloseModal = e => {
    setCollectionState({
      collectionId: '',
      name: '',
      description: '',
      access: COLLECTIONS_ACCESS_TYPES_NEW.organization,
    })
    setCollectionNameError('')
    setIsLoading(false)
    closeModal(e)
  }

  const onSaveCollection = async e => {
    if (!collectionState.name) {
      return setCollectionNameError(errors.fieldIsRequired)
    }

    const { collectionId, name, description, access } = collectionState
    if (
      !isNewCollection &&
      collectionData?.name === name &&
      collectionData?.description === description
    ) {
      return onCloseModal(e)
    }

    setIsLoading(true)
    const handler = isNewCollection
      ? () => createNewCollection({ name, description, access, profile, source })
      : () => editCollection({ collectionId, name, description })
    await handler()
    setIsLoading(false)
    onCloseModal(e)
  }

  const headerContent = isNewCollection
    ? labelStrings.createNewCollection
    : labelStrings.editCollectionTitle

  return (
    <ModalComponent
      title={headerContent}
      closeModal={onCloseModal}
      isOpenModal={isOpened}
      withoutScrollWrapper
      isCenterTitle
      contentWidth={`400px`}
      contentHeight={'auto'}
      smallPadding
      smallPaddingContent
      isGradientBg
      isFilledTitle
    >
      <EditCollectionModalWrapper>
        <Input
          id="collection-name"
          name="collection-name"
          label={labelStrings.collectionName}
          value={collectionState.name}
          maxLength={MAX_NAME_LIMIT}
          onChange={onChangeCollectionName}
          errorMessage={collectionNameError}
        />

        <Textarea
          id="collection-description"
          name="collection-description"
          value={collectionState.description}
          maxLength={MAX_DESCRIPTION_LIMIT}
          label={`${labelStrings.collectionDescription} (${labelStrings.optional})`}
          onChange={onChangeCollectionDescription}
        />

        {isNewCollection && (
          <Stack sx={{ mb: '24px' }} spacing={0.5}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                color: '#784E4E',
                fontSize: '13px',
                fontWeight: 600,
              }}
            >
              {labelStrings.generalAccess}
            </Typography>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                sx={{
                  backgroundColor: '#FFF1F1',
                  color: '#442424',
                  borderRadius: '50%',
                  height: '34px',
                  width: '34px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LockOutlinedIcon sx={{ fontSize: '18px' }} />
              </Box>
              <Stack>
                <FormControl variant="standard" sx={{ width: 'fit-content' }}>
                  <Select
                    size="small"
                    value={collectionState.access}
                    onChange={onChangeCollectionAccess}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontFamily: 'Inter',
                          color: '#442424',
                          '& .MuiMenuItem-root': {
                            fontSize: '13px',
                            textTransform: 'capitalize',
                          },
                        },
                      },
                    }}
                    sx={{
                      color: '#442424',
                      fontFamily: 'Inter',
                      fontWeight: 600,
                      fontSize: '12px',
                      '& .MuiInput-input:focus': {
                        backgroundColor: 'transparent !important',
                      },
                      '& svg': {
                        color: '#442424',
                      },
                      '&:hover': {
                        '&:before': {
                          borderBottom: '1px solid #442424 !important',
                        },
                      },
                      '&:before': {
                        borderBottom: 'unset',
                      },
                      '&:after': {
                        borderBottom: 'unset',
                      },
                    }}
                  >
                    {accessValuesArr.map(el => (
                      <MenuItem key={el.value} value={el.value}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    color: '#784E4E',
                    fontSize: '11px',
                  }}
                >
                  {accessDescription[collectionState.access]}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}

        <FlexRow>
          <GradientButton platform={platform} onClick={e => onSaveCollection(e)}>
            {isNewCollection ? labelStrings.createNewCollection : labelStrings.editCollection}
          </GradientButton>
        </FlexRow>

        {isLoading && <Spinner isOverlay />}
      </EditCollectionModalWrapper>
    </ModalComponent>
  )
}

EditCollectionModal.propTypes = {
  collectionData: PropTypes.shape({
    collectionId: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    count: PropTypes.number,
    access: PropTypes.string,
  }),
  isNewCollection: PropTypes.bool,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  closeModal: PropTypes.func,
  createNewCollection: PropTypes.func,
  editCollection: PropTypes.func,
  withoutPortal: PropTypes.bool,
  profile: PropTypes.object,
  source: PropTypes.string
}

export default withReduxActions({ createNewCollection, editCollection })(EditCollectionModal)
