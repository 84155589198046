import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import useTranslation from '../../localization/useTranslation'
import { NewHeartEmpty, NewHeartFull } from '../../components/common/icons'
import WithTip from '../../components/common/popups/WithTip'
import WithUpgradeTip from '../../components/common/popups/WithUpgradeTip'

const IconWrapper = styled.div`
  ${props => props.disabled && props.theme.disableBlock};
  position: relative;
`

export const CreatorsLikeBasedTools = ({
  isFav,
  removeFromFavs,
  addToFavs,
  userindex,
  canAddToFavs,
  enableFavorites,
  isUserAuthenticated,
  isQuickPreview,
}) => {
  const [favSelected, setFavSelected] = useState(isFav)
  const { labelStrings } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleToggleFavorite = async () => {
    if (isLoading || !enableFavorites) return

    const newFavState = !favSelected
    if (!canAddToFavs && !isFav) return

    setIsLoading(true)
    const success = await (newFavState ? addToFavs() : removeFromFavs())

    setIsLoading(false)

    if (success) {
      setFavSelected(newFavState)
    }
  }

  const renderIcon = () => (
    <IconWrapper disabled={!enableFavorites || isLoading}>
      {favSelected ? (
        <NewHeartFull userindex={userindex} />
      ) : (
        <NewHeartEmpty userindex={userindex} />
      )}
    </IconWrapper>
  )

  return (
    <Button
      onClick={handleToggleFavorite}
      disableFocusRipple
      disableRipple
      sx={{
        fontWeight: 400,
        width: 'fit-content',
        minWidth: '30px',
        cursor: isLoading ? 'wait' : 'pointer',
        '&:hover': {
          backgroundColor: 'transparent',
          span: {
            cursor: isLoading ? 'wait' : 'pointer',
            svg: {
              cursor: isLoading ? 'wait' : 'pointer',
            },
          },
        },
        svg: {
          opacity: isLoading ? 0.5 : 1,
        },
        p: 0,
      }}
    >
      {enableFavorites && canAddToFavs ? (
        <WithTip
          tipText={favSelected ? labelStrings.removeFromFavorites : labelStrings.addToFavorites}
          position={'left center'}
          arrow={!isQuickPreview}
          userindex={userindex}
        >
          {renderIcon()}
        </WithTip>
      ) : (
        <WithUpgradeTip
          tipText={!canAddToFavs ? labelStrings.addMoreUsers : labelStrings.addToFavorites}
          userindex={userindex}
          withSignup={!isUserAuthenticated}
          position={'left center'}
          arrow={!isQuickPreview}
        >
          {renderIcon()}
        </WithUpgradeTip>
      )}
    </Button>
  )
}
