import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import { stringifyNumToPretty } from '../../../utils'

const GrowthMainWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.color.grey};
`

const PeriodWrapper = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkBrown};
  margin-bottom: 2px;
`

const GrowthNumbersWrapper = styled.div`
  ${props => props.theme.flex.centerAll};
  font-size: 13px;
  line-height: 15px;
  color: ${props => {
    if (!props.growthDirection) return props.theme.color.grey
    return props.growthDirection > 0
      ? props.theme.colors.green
      : props.theme.colors.salmon
  }};
`

const ArrowWrapper = styled.span`
  transform: scale(1.5);
  margin-right: 10px;
  line-height: 16px;
`

const PercentWrapper = styled.span`
  position: relative;
  font-weight: bold;
`

const CountWrapper = styled.span`
  margin: 0 5px;
  font-weight: bold;
`

const DynamicArrowWrapper = styled.span`
  position: absolute;
  left: -26px;
  font-size: 25px;
  top: -12px;
  ${props =>
    props.isZeroDynamics &&
    `
    transform: rotateZ(45deg);
    top: -10px;
  `}
`

const ChartGrowthDynamics = ({ growthData = [], period = 'lastmonth'}) => {
  const { labelStrings } = useTranslation()
  const textsMap = {
    lastmonth: labelStrings.last30days,
  }
  const [count, percent] = growthData
  const renderGrowth = count !== undefined && percent !== undefined

  const growthDirection = (percent === 0)
    ? 0
    : (percent > 0) ? 1 : -1

  const createArrow = () => (!growthDirection || growthDirection > 0)
    ? <ArrowWrapper>&#8599;</ArrowWrapper>
    : <ArrowWrapper>&#8600;</ArrowWrapper>

  return (
    renderGrowth && count && (
      <GrowthMainWrapper>
        <PeriodWrapper>{textsMap[period]}</PeriodWrapper>
        <GrowthNumbersWrapper growthDirection={growthDirection}>
          {createArrow()}
          <PercentWrapper>
            <DynamicArrowWrapper isZeroDynamics={!growthDirection} />
            {percent}%
          </PercentWrapper>
          <CountWrapper>
            ( {count > 0 ? '+' : ''}
            {stringifyNumToPretty(count)} )
          </CountWrapper>
        </GrowthNumbersWrapper>
      </GrowthMainWrapper>
    )
  )
}

ChartGrowthDynamics.propTypes = {
  growthData: PropTypes.array,
  period: PropTypes.string,
}

export default ChartGrowthDynamics
