import React from 'react'
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  Button,
  Box,
} from '@mui/material'
import { MenuProps } from './utils'
import useTranslation from '../../../../localization/useTranslation'

export const MultipleSelect = props => {
  const {
    size,
    options,
    label,
    selected,
    setSelected,
    defaultValue,
    withConfirmAction,
    isSmall,
    ...other
  } = props

  const { labelStrings } = useTranslation()

  const isAllSelected = options?.length > 0 && selected?.length === options?.length

  const getAllOptionsValues = options?.map(option => option.value)

  const handleChange = event => {
    const value = event.target.value

    if (value[value.length - 1] === 'all') {
      setSelected(selected?.length === getAllOptionsValues?.length ? [] : getAllOptionsValues)
      return
    }
    setSelected(value)
  }

  return (
    <FormControl>
      <InputLabel size={size} id="mutiple-select-label">
        {label}
      </InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        disabled={!options?.length}
        value={selected}
        onChange={handleChange}
        renderValue={selectedVal => {
          if (selectedVal?.length === options?.length) return 'All'
          return `${selectedVal?.length} selected`
        }}
        MenuProps={{
          ...MenuProps,
          PaperProps: {
            style: {
              width: MenuProps.PaperProps.style.width,
              height: isSmall ? '390px' : MenuProps.PaperProps.style.maxHeight,
            },
            sx: {
              '& .MuiList-root': {
                py: 0,
              },
            },
          },
        }}
        size={size}
        label={label}
        defaultValue={[]}
        sx={{ position: 'relative' }}
        {...other}
      >
        <MenuItem value="all" dense={isSmall}>
          <ListItemIcon sx={{ marginRight: '5px' }}>
            <Checkbox
              checked={isAllSelected}
              indeterminate={selected.length > 0 && selected.length < options.length}
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {options?.map(option => (
          <MenuItem key={option.value} value={option.value} dense={isSmall}>
            <ListItemIcon sx={{ marginRight: '5px' }}>
              <Checkbox checked={selected.indexOf(option.value) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
        {withConfirmAction && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              px: 1,
              pt: 1,
              pb: 1,
              position: 'sticky',
              bottom: 0,
              backgroundColor: '#FFF',
              zIndex: 999,
            }}
          >
            <Button
              disabled={!selected?.length}
              variant="contained"
              sx={{ width: '100%' }}
              onClick={withConfirmAction}
            >
              {labelStrings.confirm}
            </Button>
          </Box>
        )}
      </Select>
    </FormControl>
  )
}
