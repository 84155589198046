import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { comparedProfilesSortingSelector } from '../../redux/selectors'
import { withReduxActions } from '../../hocs/withRedux'
import { changeComparedProfilesSorting } from '../../redux/ducks/compareProfilesDucks'

import useTranslation from '../../localization/useTranslation'
import { COMPARE_SORTING_TYPES } from '../../constants/sortings'

import SortingCommonTool from './SortingCommonTool'
import { SortingComparedProfilesWrapper } from '../common/styledWrappers'

const ComparedProfilesSortingTool = ({
  isDisabled = true,
  socialPlatformIndex = '',
  changeComparedProfilesSorting,
}) => {
  const { labelStrings } = useTranslation()

  const preparedSortingOptions = useMemo(() => {
    return Object.values(COMPARE_SORTING_TYPES).reduce((optionsArr, sortType) => {
      optionsArr.push({
        value: sortType.sortKey,
        label: labelStrings[sortType.localizedKey],
      })
      return optionsArr
    }, [])
  }, [])

  const currentSorting = useSelector(comparedProfilesSortingSelector)

  const findNewSortOption = _sortKey => {
    const sortedBy = preparedSortingOptions.find(item => item.value === _sortKey)
    return sortedBy || {}
  }

  const sortCompared = ({ sortKey, sortDir } = {}) => {
    changeComparedProfilesSorting({
      sortKey: sortKey || currentSorting.sortKey,
      sortDir: sortDir || currentSorting.sortDir,
    })
  }

  const changeSortingHandler = ({ target }) => {
    if (target?.value === currentSorting.sortKey) return
    const sortedBy = findNewSortOption(target.value)
    sortCompared({ sortKey: sortedBy.value })
  }

  const changeSortingDirectionHandler = sortDir => {
    if (sortDir === currentSorting.sortDir) return
    sortCompared({ sortDir })
  }

  const currentSortingValue = findNewSortOption(currentSorting.sortKey)
  return (
    <SortingComparedProfilesWrapper>
      <SortingCommonTool
        sortToolId="compared_profiles_sorting"
        sortingOptions={preparedSortingOptions}
        currentSortingValue={currentSortingValue}
        currentSortingDirection={currentSorting.sortDir}
        isDisabled={isDisabled}
        socialPlatformIndex={socialPlatformIndex}
        changeSortingHandler={changeSortingHandler}
        changeSortingDirectionHandler={changeSortingDirectionHandler}
        notTip
      />
    </SortingComparedProfilesWrapper>
  )
}

ComparedProfilesSortingTool.propTypes = {
  isDisabled: PropTypes.bool,
  socialPlatformIndex: PropTypes.string,
  changeComparedProfilesSorting: PropTypes.func,
}

export default withReduxActions({ changeComparedProfilesSorting })(ComparedProfilesSortingTool)
