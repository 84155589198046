import React from 'react'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { StackedColumnChart } from '../../components/chart/charts/StackedColumnChart'
import { BarChart } from '../../components/chart/charts/BarChart'
import { PieChart } from '../../components/chart/charts/PieChart'
import { UploadedContentTimeline } from './UploadedContentTimeline'
import { MapChart } from '../../components/chart/charts'
import useTranslation from '../../../localization/useTranslation'
import { pretifyBigNum } from '../../../utils'
import { shortsProfileCountryNames } from './utils'

const dataReachedAudience = data => {
  if (!Array.isArray(data)) return []
  return data?.map(el => {
    const { value, absoluteReach, ...other } = el
    return {
      label: Object.values(other),
      value: value < 10 ? Number(value?.toFixed(1)) : Math.floor(value),
      absoluteReach: absoluteReach,
    }
  })
}

const transformData = chartData => {
  if (!Array.isArray(chartData)) return []

  const result = [
    { name: 'Instagram Posts', data: [] },
    { name: 'Instagram Reels', data: [] },
    { name: 'Instagram Stories', data: [] },
    { name: 'TikTok Videos', data: [] },
    { name: 'YouTube Shorts', data: [] },
    { name: 'YouTube Videos', data: [] },
  ]

  chartData?.forEach(item => {
    item.instagram_post ? result[0].data.push(item.instagram_post) : result[0].data.push(0)
    item.instagram_reel ? result[1].data.push(item.instagram_reel) : result[1].data.push(0)
    item.instagram_story || item.instagram_story_group
      ? result[2].data.push((item.instagram_story || 0) + (item.instagram_story_group || 0))
      : result[2].data.push(0)
    item.tiktok_video ? result[3].data.push(item.tiktok_video) : result[3].data.push(0)
    item.youtube_short ? result[4].data.push(item.youtube_short) : result[4].data.push(0)
    item.youtube_video ? result[5].data.push(item.youtube_video) : result[5].data.push(0)
  })

  return result
}

export const ContentCharts = props => {
  const {
    reachedChartData = [],
    mostSuccessfulCreatorsByReachData = {},
    publishedByTimeAndFormatData = {},
    mostUsedKeywordBarData = {},
    uploadedContentNewsData = {},
    contentByReachBucketData = {},
    geolocationMapData = {},
  } = props || {}

  const { labelStrings } = useTranslation()

  if (
    !reachedChartData?.length &&
    !mostSuccessfulCreatorsByReachData?.graphId &&
    !publishedByTimeAndFormatData?.graphId &&
    !mostUsedKeywordBarData?.graphId &&
    !uploadedContentNewsData?.graphId &&
    !contentByReachBucketData?.graphId
  )
    return null

  /* Data for Published Content Pieces by Time and Format Graph */
  const dataPublishedByTimeAndFormat = transformData(publishedByTimeAndFormatData?.value)
  const timePublishedByTimeAndFormat = publishedByTimeAndFormatData?.value?.map(el => el.date)
  /* Data for Most Successful Creators by Reach Graph */
  const dataMostSuccessfulCreatorsByReach = transformData(mostSuccessfulCreatorsByReachData?.value)
  const timeMostSuccessfulCreatorsByReach = mostSuccessfulCreatorsByReachData?.value?.map(
    el => el.username
  )
  /* Data for Published Content Pieces by ReachGraph */
  const labelsContentByReachBucket = contentByReachBucketData?.dataArray?.map(el => el.bucket)
  const valuesContentByReachBucket = contentByReachBucketData?.dataArray?.map(el => el.content)
  /* Data for Most used keywords, links and mentions Graph */
  const dataMostUsedKeywordBar = mostUsedKeywordBarData?.value?.map(el => {
    return {
      label: el.value,
      value: el.count,
    }
  })

  return (
    <Grid container sx={{ py: 3 }} spacing={2}>
      {mostSuccessfulCreatorsByReachData?.graphId && (
        <Grid item xs={12} md={6}>
          <StackedColumnChart
            title="Most Successful Creators by Reach (Top 15)"
            helperText={`This graph represents the 'Top 15 Creators by Reach', ranked by their overall reach. Each creator's bar is divided by content format for a detailed view. Please note, only the highest performing 15 creators are displayed in this graph.`}
            chart={{
              labels: timeMostSuccessfulCreatorsByReach,
              colors: ['#CA5AFF', '#FFE700', '#2D99FF', '#FFBC00', '#FF6C40', '#2CD9C5'],
              series: [
                {
                  data: dataMostSuccessfulCreatorsByReach,
                },
              ],
            }}
          />
        </Grid>
      )}
      {publishedByTimeAndFormatData?.graphId && (
        <Grid item xs={12} md={6}>
          <StackedColumnChart
            title="Published Content Pieces by Time and Format"
            helperText={`This graph displays content pieces over time, categorized by format, providing a visual timeline of content distribution. To explore data for a specific creator, select the creator from the top menu.`}
            chart={{
              labels: timePublishedByTimeAndFormat,
              colors: ['#CA5AFF', '#FFE700', '#2D99FF', '#FFBC00', '#FF6C40', '#2CD9C5'],
              series: [
                {
                  data: dataPublishedByTimeAndFormat,
                },
              ],
            }}
          />
        </Grid>
      )}
      {contentByReachBucketData?.graphId && (
        <Grid item xs={12} md={6}>
          <Box sx={{ position: 'relative' }}>
            <Typography
              sx={{
                position: 'absolute',
                zIndex: 5,
                top: '50%',
                transform: `rotate(-90deg)`,
                left: '-22px',
                fontSize: '12px',
                color: '#97A3AF',
                fontWeight: 'bold',
              }}
            >
              Content Pieces
            </Typography>
            <StackedColumnChart
              title="Published Content Pieces by Reach"
              helperText={`This graph illustrates the reach distribution of various content pieces, enabling you to gauge which posts performed better or worse. To analyze data for a specific creator, please select their name from the top menu.`}
              chart={{
                labels: labelsContentByReachBucket,
                colors: ['#FFE4A9'],
                series: [
                  {
                    data: [{ name: '', data: valuesContentByReachBucket }],
                  },
                ],
              }}
            />
            <Typography
              sx={{
                zIndex: 5,
                position: 'absolute',
                right: '50%',
                bottom: '8px',
                fontSize: '12px',
                color: '#97A3AF',
                fontWeight: 'bold',
              }}
            >
              Reach
            </Typography>
          </Box>
        </Grid>
      )}

      {geolocationMapData?.graphId && !!geolocationMapData?.value?.length && (
        <Grid item xs={12} md={6}>
          <MapChart
            title={labelStrings.geolocationMapTitle}
            subheader={labelStrings.geolocationMapSubheader}
            chart={{
              colors: [
                '#76DB6B',
                '#FFBC00',
                '#e0e0e0',
                '#637381',
                '#DFE3E8',
                '#FF5D5A',
                '#15D9E8',
                '#EA3323',
              ],
              data: geolocationMapData.value,
            }}
          />
        </Grid>
      )}

      {mostUsedKeywordBarData?.graphId && (
        <Grid item xs={12} md={6}>
          <BarChart
            title="Most used keywords, links and mentions"
            helperText={`This data represents how often a certain keyword was used by creators in this dashboard.`}
            subheader=""
            chart={{
              series: dataMostUsedKeywordBar || [],
              colors: ['#FF5D5A'],
            }}
          />
        </Grid>
      )}

      {reachedChartData
        ?.sort((a, b) => {
          if (a.graphId.includes('audience_age') && !b.graphId.includes('audience_age')) {
            // If a is 'audience_age' and b is not, a should come after b
            return -1
          } else if (!a.graphId.includes('audience_age') && b.graphId.includes('audience_age')) {
            // If a is not 'audience_age' and b is, a should come before b
            return 1
          }
          // If both are 'audience_age' or both are not, keep the original order
          return 0
        })
        .map(reachedChart => {
          const title = {
            ['reached_audience_country']: labelStrings.top5AudiencesCountries,
            ['reached_audience_city']: labelStrings.top5AudiencesCities,
            ['reached_audience_gender']: labelStrings.gender,
            ['reached_audience_language']: labelStrings.top5AudiencesLanguages,
            ['reached_audience_age']: labelStrings.filedLabels.reached_audience_age,
          }
          const helperTexts = {
            ['reached_audience_country']:
              "This graph displays the audience countries reached by the dashboard. The data is calculated based on each creator's reach - a higher reach accounts for a larger part of the audience.\n\nThe audience data is provided by influData, and you can modify the creator's audience data in the 'Creator Overview' tab.",
            ['reached_audience_city']:
              "This graph shows the audience cities that were reached by the creators in this dashboard. The more reach a creator has, the larger their portion of the audience.\n\nYou can adjust the audience data for the creator in the 'Creator Overview' tab. This data is provided by influData.",
            ['reached_audience_gender']:
              "This graph indicates the gender distribution of the reached audience by creators in this dashboard. The proportions are calculated based on each creator's reach, meaning more reach corresponds to a larger audience segment.\n\nAdjust the creator's audience data in the 'Creator Overview' tab, as provided by influData.",
            ['reached_audience_language']:
              "This graph represents the languages of the audience reached by the creators in this dashboard. The data is calculated proportionally to the reach of each creator, so more reach translates into a larger audience share.\n\nThe audience data can be changed in the 'Creator Overview' tab and is provided by influData.",
            ['reached_audience_age']:
              "This graph illustrates the age distribution of the reached audience by the creators in this dashboard. The data, which is provided by influData, is proportional to the creator's reach, so a higher reach signifies a larger audience section.\n\nThe audience data for the creator can be adjusted in the 'Creator Overview' tab.",
          }

          if (reachedChart.graphId === 'reached_audience_age') {
            return (
              <Grid item xs={12} md={6} key={reachedChart.graphId}>
                <BarChart
                  title={title[reachedChart?.graphId]}
                  helperText={helperTexts[reachedChart?.graphId] || ''}
                  subheader={
                    <Typography sx={{ color: '#637381', fontWeight: '700', pl: 2 }}>
                      {labelStrings.averageAgeIs(reachedChart?.averageAge)}
                    </Typography>
                  }
                  chart={{
                    series: dataReachedAudience(reachedChart?.value) || [],
                    colors: ['#FF5D5A'],
                    options: {
                      plotOptions: {
                        bar: {
                          horizontal: false,
                          dataLabels: {
                            position: 'top',
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        formatter: function(val) {
                          return `${val}%`
                        },
                        offsetY: -20,
                        offsetX: 0,
                        style: {
                          fontSize: '12px',
                          colors: ['#FF695B'],
                        },
                      },
                      tooltip: {
                        enabled: true,
                        custom: function({ dataPointIndex }) {
                          const reachValue = reachedChart.value[dataPointIndex]?.absoluteReach
                          return renderToString(
                            <div
                              style={{
                                padding: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {labelStrings.reach}:
                              <span style={{ fontWeight: 'bold', marginLeft: '2px' }}>
                                {pretifyBigNum(reachValue)}
                              </span>
                            </div>
                          )
                        },
                      },
                      title: {
                        text: 'Monthly Inflation in Argentina, 2002',
                        floating: true,
                        offsetY: 380,
                        offsetX: 0,
                        align: 'center',
                        style: {
                          color: '#444',
                        },
                      },
                    },
                  }}
                />
              </Grid>
            )
          }

          const isBarChart =
            reachedChart.graphId === 'reached_audience_city' ||
            reachedChart.graphId === 'reached_audience_language' ||
            reachedChart.graphId === 'reached_audience_country'

          if (isBarChart) {
            const barColors = {
              ['reached_audience_country']: {
                default: '#5EC5FC',
                maxValue: '#048dd7',
              },
              ['reached_audience_city']: {
                default: '#61DB8D',
                maxValue: '#27ae58',
              },
              ['reached_audience_language']: {
                default: '#FFC565',
                maxValue: '#e58f00',
              },
            }

            const seriesData = dataReachedAudience(reachedChart?.value) || []
            const barMaxValue = Math.max(...seriesData?.map(item => item.value))

            return (
              <Grid item xs={6} md={3} key={reachedChart.graphId}>
                <BarChart
                  title={title[reachedChart?.graphId]}
                  helperText={helperTexts[reachedChart?.graphId] || ''}
                  subheader=""
                  chart={{
                    series: seriesData,
                    colors: [
                      function({ value, seriesIndex, w }) {
                        if (value === barMaxValue) {
                          return barColors[reachedChart?.graphId]?.maxValue
                        } else {
                          return barColors[reachedChart?.graphId]?.default
                        }
                      },
                    ],
                    options: {
                      plotOptions: {
                        bar: {
                          horizontal: true,
                          borderRadius: 5,
                          barHeight: '50%',
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        formatter: function(val, opts) {
                          const absoluteReach = seriesData[opts?.dataPointIndex]?.absoluteReach
                          return `${val < 10 ? val : pretifyBigNum(val)}% (${pretifyBigNum(
                            absoluteReach
                          )})`
                        },
                        style: {
                          fontSize: '10px',
                          colors: ['#343434'],
                        },
                      },

                      yaxis: {
                        labels: {
                          show: true,
                          offsetY: 4,
                          offsetX: 0,
                        },
                      },
                      xaxis: {
                        type: 'category',
                        categories: seriesData?.map(i => {
                          const labelToLowerCase = String(i.label)?.toLowerCase()
                          const label = shortsProfileCountryNames[labelToLowerCase]
                            ? shortsProfileCountryNames[labelToLowerCase]
                            : i.label
                          return label
                        }),
                        labels: {
                          formatter: function(val) {
                            return Number(val) ? pretifyBigNum(val, val < 10 ? 2 : 1) : val
                          },
                        },
                      },
                    },
                  }}
                />
              </Grid>
            )
          }
          return (
            <Grid item xs={6} md={3} key={reachedChart.graphId}>
              <PieChart
                title={title[reachedChart?.graphId]}
                helperText={helperTexts[reachedChart?.graphId] || ''}
                subheader={labelStrings.reachedAudience}
                chart={{
                  colors: ['#FF5D5A', '#3FC0FC', '#FFBC00', '#76DB6B'],
                  series:
                    (reachedChart.graphId === 'reached_audience_gender' &&
                    (reachedChart?.value?.length > 1 && reachedChart?.value[1]?.gender === 'male' &&
                    reachedChart?.value[1]?.value > 50))
                      ? dataReachedAudience(reachedChart?.value)?.reverse()
                      : dataReachedAudience(reachedChart?.value),
                }}
              />
            </Grid>
          )
        })}

      {uploadedContentNewsData?.graphId && (
        <Grid item xs={12} md={6}>
          <UploadedContentTimeline
            title="Uploaded Content (with keywords found)"
            list={uploadedContentNewsData?.value}
            helperText={``}
          />
        </Grid>
      )}
    </Grid>
  )
}

ContentCharts.propTypes = {
  reachedChartData: PropTypes.array,
  mostSuccessfulCreatorsByReachData: PropTypes.object,
  publishedByTimeAndFormatData: PropTypes.object,
  mostUsedKeywordBarData: PropTypes.object,
  uploadedContentNewsData: PropTypes.object,
  contentByReachBucketData: PropTypes.object,
  geolocationMapData: PropTypes.object,
}
