import React, { useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import { CompareProfilesSvgIcon } from '../../../components/common/icons'
import {
  comparedProfilesCountSelector,
  comparedProfilesPlatformSelector,
} from '../../../redux/selectors'

const ComparedNavItemWrapper = styled.div`
  transition: all 0.2s ease;
  position: absolute;
  top: 60px;
  z-index: 2;
  right: ${props => (!!props.count ? '8px' : `-${props.itemWidth}px`)};
  cursor: pointer;
  width: fit-content;
  height: 27px;
  background: ${(props) => props.theme.color.congoBrown};
  box-shadow: 0px 0px 20px #ff744129;
  border-radius: 7px;
  ${props => props.theme.flex.centerAll}
  &:hover {
    opacity: 0.8;
  }
  ${props =>
    !!props.count &&
    `
  
  ${props.theme.withBubbledCouter(
    props,
    `top: 68%; right: 0px; border: 1px solid ${props.theme.color.pink};`
  )}
  `}
`

const CommonFlex = styled.div`
  height: 100%;
  ${props => props.theme.flex.centerAll}
`

const LabelWrapper = styled(CommonFlex)`
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.serenade};
  text-align: center;
  line-height: 13px;
  padding: 0 8px;
`
const IconWrapper = styled(CommonFlex)`
  width: 50px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background: ${props => props.theme.pickColorDependOnPlatform(props.platform).primary};
`

const CompareProfilesDynamicNavItem = ({ isUserAuthorized, currentPagePath }) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()
  const comparedProfilesCount = useSelector(comparedProfilesCountSelector)
  const comparedProfilesPlatform = useSelector(comparedProfilesPlatformSelector)

  const itemRef = useRef(null)

  const { width: itemWidth = 205 } =
    itemRef && itemRef.current ? itemRef.current.getBoundingClientRect() : {}

  const isHiddenOnPage = useMemo(() => {
    const pagesWhereHidden = ['compare-profiles', 'campaigns', 'settings']
    const isHidden = pagesWhereHidden.some(page => currentPagePath.includes(page))
    return isHidden
  }, [currentPagePath])

  const goToCompared = () => history.push('/compare-profiles')

  const renderNav = isUserAuthorized && !isHiddenOnPage

  return (
    renderNav &&
    comparedProfilesCount > 0 && (
      <ComparedNavItemWrapper
        onClick={goToCompared}
        count={comparedProfilesCount}
        itemWidth={itemWidth}
      >
        <IconWrapper platform={comparedProfilesPlatform}>
          <CompareProfilesSvgIcon height="25px" width="25px" />
        </IconWrapper>

        <LabelWrapper>{labelStrings.compareProfiles}</LabelWrapper>
      </ComparedNavItemWrapper>
    )
  )
}

export default CompareProfilesDynamicNavItem
