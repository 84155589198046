import React, { useState, useEffect } from "react"
import { Box, Slider, Stack, Typography } from "@mui/material"
import useTranslation from "../../../../../localization/useTranslation"
import { infludataThemes } from "../../../../../themes/infludataThemes"

const allowedAgeValues = [18, 25, 34, 45, 65, 99]
const defaultAgeRange = [18, 99]

export const AgeGroupRangeFilter = (props) => {
	const { selectedPlatform, query, handlersMap } = props || {}
	const { labelStrings } = useTranslation()

	// Parse query.ageGroup if it's a comma separated string, otherwise use default
	const parsedQueryAgeRange = query?.ageGroup
		? typeof query.ageGroup === "string"
			? query.ageGroup.split(",").map(n => parseInt(n, 10))
			: query.ageGroup
		: defaultAgeRange

	const [localSliderValue, setLocalSliderValue] = useState(parsedQueryAgeRange)

	const handleChange = (event, newValue) => {
		setLocalSliderValue(newValue)
	}

	const onChangeCommitted = (event, newValue) => {
		// If the slider is at the default range, send nothing (null)
		if (newValue[0] === defaultAgeRange[0] && newValue[1] === defaultAgeRange[1]) {
			handlersMap["ageGroup"](null)
		} else {
			handlersMap["ageGroup"](newValue.join(","))
		}
	}

	useEffect(() => {
		if (query?.ageGroup) {
			const newValue =
				typeof query.ageGroup === "string"
					? query.ageGroup.split(",").map(n => parseInt(n, 10))
					: query.ageGroup
			setLocalSliderValue(newValue)
		} else {
			setLocalSliderValue(defaultAgeRange)
		}
	}, [query?.ageGroup])

	return (
		<Box sx={{ height: "70px", minWidth: "350px" }}>
			<Stack sx={{ px: 2 }}>
				<Typography
					sx={{
						color: "#784E4E",
						fontWeight: 700,
						fontSize: "11px",
						textTransform: "uppercase",
						fontFamily: "Inter",
					}}
				>
					{labelStrings.ageGroup} (beta feature)
				</Typography>
				<Slider
					sx={{
						height: "5px",
						borderRadius: "8px",
						color: infludataThemes[selectedPlatform]?.platformColor,
						"& .MuiSlider-markLabel": {
							fontSize: "10px",
							color: infludataThemes[selectedPlatform]?.platformColor,
							fontWeight: "bold",
						},
					}}
					value={localSliderValue}
					onChange={handleChange}
					onChangeCommitted={onChangeCommitted}
					valueLabelDisplay="off"
					step={null}
					marks={allowedAgeValues.map(value => ({ value, label: String(value) }))}
					min={allowedAgeValues[0]}
					max={allowedAgeValues[allowedAgeValues.length - 1]}
				/>
			</Stack>
		</Box>
	)
}
