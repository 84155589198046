import React, { memo, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import useQuery from '../hooks/useQuery'
import useTranslation from '../localization/useTranslation'
import {
  getCollectionUsers,
  changeUserInOpenedCollection,
  setOpenCollectionUsersSelected,
  changeCollectionView,
  addProfilesToOverlap,
  changeOpenedCollectionOverlapModal,
  changeOpenedCollectionOverlapConfirmModal,
  getAllCollections,
} from '../redux/ducks/collectionsDucks'
import { setSaveOpenedProfileId } from '../redux/ducks/profileSelectedDucks'
import {
  isLoadingCollectionSelector,
  collectionsSelector,
  openedCollectionSelector,
  userCredsLoadProgressSelector,
  userGrantSelector,
  showCollectionAccessSelector,
  openedCollectionFromListDynamicSelector,
  isGetOpenedProfileIdSelector,
  noRerenderEqFn,
  userAdditionalFieldsSelector,
  isUserAuthenticatedSelector,
  userCredsSelector,
} from '../redux/selectors'
import {
  comparePlatform,
  countUnlockedOn,
  filterSelectedUsersFromUsersList,
  findKeyOrValueInObjList,
} from '../utils'
import { pagesBeforeProfileRedirect } from '../constants/pagesBeforeProfileRedirect'
import {
  UNLOCKED_COLLECTION_NAME,
  PAGINATION_ITEMS_PER_PAGE,
  FAV_COLLECTION_NAME,
  ARROW_SORT_POSITION,
  COLLECTION_YOUR_OVERLAPS_PATH,
} from '../constants/collections'

import SidebarContainer from '../components/sidebar/SidebarContainer'
import CollectionsBulkActionsContainer from '../components/profileListBulkActions/collectionsBulkActions/CollectionsBulkActionsContainer'
import ProfileListFullTableContainer from '../components/profileCommon/profileListTable/ProfileListFullTableContainer'
import CollectionAudience from '../components/collections/CollectionAudience/CollectionAudience'
import CollectionOverlap from '../components/collections/CollectionOverlap/CollectionOverlap'
import CollectionProfileListHeader from '../components/profileListHeaderSections/CollectionProfileListHeader'
import CollectionPageTips from '../components/pagesTips/CollectionPageTips'

import LoadingSpinner from '../components/common/spinners/LoadingSpinner'
import Pagination from '../components/pagination/Pagination'
import Tabs from '../components/common/tabs/Tabs'
import {
  Layout,
  MainContainer,
  ListHeaderSection,
  ListWrapper,
} from '../components/common/styledWrappers'
import { InfoCircleIcon } from '../components/common/icons/icons'
import * as Styled from './collectionsPage.styles.js'
import { changeAdditionalFieldValue } from '../redux/ducks/additionalFieldsDucks'
import CollectionsEditFields from '../components/collections/CollectionEditFields'
import { CollectionSearch } from '../components/collections/CollectionSearch'
import {
  COLLECTION_VIEW_MODE,
  COLLECTIONS_TABS_TYPE,
  BLACKLIST,
  COLLECTIONS_ACCESS_TYPES_NEW,
} from '../constants/collections'
import { CollectionSavedContent } from '../components/collections/CollectionSavedContent'
import { Box, Button, Stack } from '@mui/material'
import { CollectionInitialOverlapModal } from '../components/collections/CollectionOverlap/YourOverlaps/CollectionInitialOverlapModal'
import { ConfirmWhenUnlockedUsersModal } from '../components/collections/CollectionOverlap/YourOverlaps/ConfirmWhenUnlockedUsersModal'
import { YourOverlaps } from '../components/collections/CollectionOverlap/YourOverlaps/YourOverlaps'
import { startUpGetCollectionAction } from '../redux/ducks/startupActions'
import { setUserStartUpDataPresent } from '../redux/ducks/userDucks'
import { CollectionAirTable } from '../components/collections/CollectionAirTable/CollectionAirTable'
import { TypeCollectionView } from '../components/collections/TypeCollectionView/TypeCollectionView'
import { NAVBAR_FOOTER_SUM_HEIGHT } from '../constants/appSettings'
import { PlanInactiveModal } from '../new-ui/components/no-plan-modal/PlanInactiveModal'
import { NoPlanModal } from '../new-ui/components/no-plan-modal/NoPlanModal'

const filteredByUserId = ({ array = [], userId = '' }) => array?.filter(user => user._id !== userId)

const CollectionsPage = ({ collectionId = '' }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const { labelStrings } = useTranslation()

  const collectionPage = +query.get('collectionPage') || 1
  const isYourOverlapsPage = location.pathname?.includes(COLLECTION_YOUR_OVERLAPS_PATH)

  const openedCollection = useSelector(openedCollectionSelector, shallowEqual)
  const additionalFields = useSelector(userAdditionalFieldsSelector, shallowEqual)
  const { metadata } = openedCollection.users || {}
  const airTableLink = metadata?.airtableLink
  const isOwnerCollection = metadata?.isOwner

  const {
    enable_collection_exports,
    enable_collections,
    no_plan,
    enable_collection_statistics,
    is_self_service,
    creator_audience_overlaps,
    audience_tokens_monthly
  } = useSelector(userGrantSelector)
  const { showNoPlanModal } = useSelector(userCredsSelector) || {}
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const collectionsViews = openedCollection?.users?.metadata?.collectionViews

  const [detailedOrList, setIsDetailedOrList] = useState(COLLECTION_VIEW_MODE.detailed)
  const toggleSwitchState = detailedOrList === COLLECTION_VIEW_MODE.detailed ? false : true

  const [typeCollectionView, setTypeCollectionView] = useState(COLLECTION_VIEW_MODE.airtable)

  const [searchValue, setSearchValue] = useState('')

  const defaultSortDirValue =
    collectionsViews?.sorting?.dir === 'asc' ? ARROW_SORT_POSITION.asc : ARROW_SORT_POSITION.desc
  const defaultSortKeyValue = collectionsViews?.sorting?.field
  const defaultHiddenFields = collectionsViews?.fields

  const [sortDirection, setSortDirection] = useState('')
  const [currentFieldsState, setCurrFieldsState] = useState({})

  useEffect(() => {
    if (
      collectionsViews?.collectionId?.startsWith(FAV_COLLECTION_NAME) &&
      !collectionsViews?.view
    ) {
      setIsDetailedOrList(COLLECTION_VIEW_MODE.detailed)
      setTypeCollectionView(COLLECTION_VIEW_MODE.detailed)
    }

    if (collectionsViews?.view === COLLECTION_VIEW_MODE.list) {
      setIsDetailedOrList(COLLECTION_VIEW_MODE.list)
      setTypeCollectionView(COLLECTION_VIEW_MODE.list)
    }
    if (collectionsViews?.view === COLLECTION_VIEW_MODE.detailed) {
      setIsDetailedOrList(COLLECTION_VIEW_MODE.detailed)
      setTypeCollectionView(COLLECTION_VIEW_MODE.detailed)
    }
    if (collectionsViews?.view === COLLECTION_VIEW_MODE.airtable) {
      setTypeCollectionView(COLLECTION_VIEW_MODE.airtable)
    }
  }, [collectionsViews])

  useEffect(() => {
    if (!sortDirection) {
      setSortDirection(defaultSortDirValue)
    }
  }, [defaultSortDirValue])

  const onChangeHiddenFields = ({ hiddenArrFields, viewMode, isUpdateCollection }) => {
    onSortTable({ column: null, hiddenArrFields, viewMode, isUpdateCollection })
  }

  const onSortTable = async ({ column, hiddenArrFields, viewMode, isUpdateCollection = true }) => {
    const payload = {
      collectionId: collectionsViews?.collectionId,
      sorting: { field: column?.sortKey || defaultSortKeyValue, dir: sortDirection },
      view: viewMode ? viewMode : detailedOrList,
      fields: hiddenArrFields ? hiddenArrFields : defaultHiddenFields,
    }
    // @ts-ignore
    await dispatch(changeCollectionView(payload))

    if (isUpdateCollection) {
      // @ts-ignore
      await dispatch(getCollectionUsers(openedCollection.id, currentPage, true))
    }
  }

  const tableHeaderProps = {
    onSortTable,
    sortDirection,
    setSortDirection,
  }

  const filteredSearchCollectionsList = useMemo(() => {
    return findKeyOrValueInObjList(openedCollection?.users?.userdata, searchValue)
  }, [openedCollection, searchValue])

  const [filteredSearchCollectionsBlackList, setFilteredSearchCollectionsBlackList] = useState([])

  const isBlackListCollection = openedCollection?.id === BLACKLIST
  const blackListCollection = isBlackListCollection && {
    collectionId: BLACKLIST,
    name: 'Blacklist',
    access: COLLECTIONS_ACCESS_TYPES_NEW.restricted,
    count: !!filteredSearchCollectionsBlackList?.length
      ? filteredSearchCollectionsBlackList?.length
      : openedCollection?.users?.userdata?.length,
    description: labelStrings.blockCollectionDecription,
  }

  const isSavedContent = !!openedCollection?.users?.contentData?.length
    ? {
        id: 'savedContent',
        content: `saved content`,
        isDisabled: enable_collection_statistics,
      }
    : {}

  const tabs = openedCollection?.id?.startsWith(FAV_COLLECTION_NAME)
    ? [
        {
          id: 'audience',
          content: labelStrings.audienceCollection,
          isDisabled: is_self_service,
        },
        isSavedContent,
      ]
    : [
        {
          id: 'audience',
          content: labelStrings.audienceCollection,
          isDisabled: is_self_service,
        },
        // {
        //   id: 'overlap',
        //   content: labelStrings.overlapCollection,
        //   isDisabled: enable_collection_statistics,
        //   overlapDisabled:
        //     !!metadata.audienceReport?.followerOverlap?.tiktokOverlap === false &&
        //     !!metadata.audienceReport?.followerOverlap?.instaOverlap === false,
        // },
        isSavedContent,
      ]

  const [currentPage, setCurrentPage] = useState(collectionPage)
  const [activeTabId, setActiveTabId] = useState('list')
  const [isLocalLoading, setIsLocalLoading] = useState(false)

  const switchTab = id => setActiveTabId(id)
  const isActiveFirstTab = activeTabId === COLLECTION_VIEW_MODE.list

  const onHandleOpenViewType = () => {
    switchTab(COLLECTION_VIEW_MODE.list)
  }

  useEffect(() => {
    setCurrentPage(collectionPage)
  }, [collectionPage])

  useEffect(() => {
    if (collectionId && collectionPage && isBlackListCollection) {
      setFilteredSearchCollectionsBlackList(openedCollection?.users?.userdata)
      dispatch(setUserStartUpDataPresent())
    }
  }, [collectionId, isBlackListCollection, openedCollection?.users?.userdata?.length])

  useEffect(() => {
    if (!isBlackListCollection && !collectionId) {
      setIsLocalLoading(true)
      // @ts-ignore
      dispatch([getAllCollections(true, setIsLocalLoading)])
    }
  }, [])

  const collectionDataArr = useSelector(collectionsSelector)
  const isCollectionLoading = useSelector(isLoadingCollectionSelector)
  const isUserLoading = useSelector(userCredsLoadProgressSelector)
  const showCollectionAccess = useSelector(showCollectionAccessSelector)
  const getOpenedFromCollectionsList = useSelector(
    openedCollectionFromListDynamicSelector,
    noRerenderEqFn
  )
  const openedProfileId = useSelector(isGetOpenedProfileIdSelector, shallowEqual)

  const isUnlockedCollectionOpened = openedCollection.id === UNLOCKED_COLLECTION_NAME

  const selectedUsersInOpenedCollection = filterSelectedUsersFromUsersList(
    openedCollection.users.userdata
  )

  const selectedUsersOfSinglePlatformCollection = comparePlatform(selectedUsersInOpenedCollection)
  const selectedUsersCountNotUnlocked = countUnlockedOn(selectedUsersInOpenedCollection)
  const isValidCountSelectedUsersForOverlap =
    selectedUsersInOpenedCollection?.length >= 2 && selectedUsersInOpenedCollection?.length <= 10
  const isDisabledCreateOverlap =
    !selectedUsersOfSinglePlatformCollection || !isValidCountSelectedUsersForOverlap || !creator_audience_overlaps
  const areAllSelectedInOpenedCollection =
    openedCollection?.users?.userdata?.length === selectedUsersInOpenedCollection?.length

  const handlersMap = {
    modifyUser: (idToUpdate, changeField, newValue) =>
      dispatch(changeUserInOpenedCollection(idToUpdate, changeField, newValue)),
    selectAllUsers: selectAll => dispatch(setOpenCollectionUsersSelected({ selectAll })),
    selectOne: userId => dispatch(setOpenCollectionUsersSelected({ userId })),
  }

  const everythingHasLoaded = !isCollectionLoading && !isUserLoading && !isLocalLoading
  const isNoData = !openedCollection?.users?.userdata?.length || !enable_collections
  const isNoContentData = !openedCollection?.users?.contentData?.length
  const isData = !isNoContentData ? isNoContentData : isNoData
  const openedCollectionFromList = useMemo(
    () => getOpenedFromCollectionsList(collectionId || openedCollection?.id),
    [collectionId, collectionDataArr, openedCollection?.id]
  )

  const isUnlockedReportsCollection = openedCollection.id === UNLOCKED_COLLECTION_NAME
  const hasPagination = !!currentPage && openedCollectionFromList.count > PAGINATION_ITEMS_PER_PAGE
  const maxPagesDisplayCount = Math.ceil(openedCollectionFromList.count / PAGINATION_ITEMS_PER_PAGE)

  const handlePageChange = async page => {
    const nextPage = page.selected + 1
    const areUsersFetched = await dispatch(getCollectionUsers(collectionId, nextPage))

    if (areUsersFetched) {
      history.push(
        queryString.stringifyUrl({
          url: `/collections/${collectionId}`,
          query: { collectionPage: nextPage },
        })
      )
      setCurrentPage(nextPage)
    }
  }

  const PaginationComponent = (
    <Pagination
      currentPage={currentPage - 1}
      maxPagesDisplayCount={maxPagesDisplayCount}
      handlePageChange={handlePageChange}
      isMaxPagesLimitExceded={false}
      disableInitialCallback
    />
  )

  useEffect(() => {
    const element = document.getElementById(openedProfileId)
    if (element) {
      element.scrollIntoView()
      dispatch(setSaveOpenedProfileId(null))
    }
  }, [openedProfileId])

  const currentCollectionsLength = openedCollection?.users?.userdata?.length

  const onBlockUnblockUser = async ({ profileId, isBlack }) => {
    const filteredBlackListCollection = filteredByUserId({
      array: !!filteredSearchCollectionsBlackList.length
        ? filteredSearchCollectionsBlackList
        : openedCollection?.users?.userdata,
      userId: profileId,
    })

    setFilteredSearchCollectionsBlackList(filteredBlackListCollection)

    await dispatch(
      // @ts-ignore
      changeAdditionalFieldValue({
        profileId: profileId,
        changeField: 'isBlack',
        valueObj: `${!isBlack}`,
      })
    )
    // @ts-ignore
    dispatch(startUpGetCollectionAction({ collectionId, collectionPage }))
  }

  const OnAddProfilesToOverlap = () => {
    const profileSelectedIds = selectedUsersInOpenedCollection?.map(profile => profile._id)
    dispatch(
      setOpenCollectionUsersSelected({
        selectAll: false,
      })
    )

    // @ts-ignore
    dispatch(addProfilesToOverlap(profileSelectedIds))
  }

  const onCloseOverlapInitialModal = () => {
    dispatch(changeOpenedCollectionOverlapModal(false))
  }
  const onCloseOverlapConfirmModal = () => {
    dispatch(changeOpenedCollectionOverlapConfirmModal(false))
  }
  const onOpenOverlapConfirmModal = () => {
    dispatch(changeOpenedCollectionOverlapConfirmModal(true))
  }

  const onChangeTypeViewCollection = ({ target }) => {
    const value = target.value
    setTypeCollectionView(value)
    switchTab(COLLECTION_VIEW_MODE.list)
    setIsDetailedOrList(value)
    onChangeHiddenFields({ hiddenArrFields: null, viewMode: value, isUpdateCollection: false })
  }

  useEffect(() => {
    if (isCollectionLoading) {
      setIsLocalLoading(true)
    } else {
      setIsLocalLoading(false)
    }
  }, [isCollectionLoading])

  useEffect(() => {
    switchTab(COLLECTION_VIEW_MODE.list)
    if (
      !filteredSearchCollectionsList?.length &&
      openedCollection?.users?.contentData?.length &&
      currentPage === 1
    ) {
      switchTab(COLLECTIONS_TABS_TYPE.savedContent)
    }
    setIsLocalLoading(false)
  }, [filteredSearchCollectionsList, openedCollection?.users?.contentData, currentPage])

  if (no_plan && isUserAuthenticated) {
    return showNoPlanModal ? (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }} />
    ) : (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }}>
        {is_self_service ? <NoPlanModal /> : <PlanInactiveModal />}
      </Box>
    )
  }

  return (
    <Layout>
      {(isCollectionLoading || isLocalLoading) && (
        <LoadingSpinner showOnFullPage isTransparent isInsidePageLoad />
      )}

      <SidebarContainer withCollections onHandleOpenViewType={onHandleOpenViewType} />

      <MainContainer id="collection_page" isCollections>
        <ListHeaderSection>
          <CollectionProfileListHeader
            collection={isBlackListCollection ? blackListCollection : openedCollectionFromList}
            openedCollection={openedCollection}
            enableExports={enable_collection_exports}
            showCollectionAccess={showCollectionAccess}
            showSortingTools={!hasPagination}
            isOwnerCollection={isOwnerCollection}
          />

          {!isBlackListCollection && (
            <CollectionsBulkActionsContainer collectionId={collectionId} />
          )}
        </ListHeaderSection>

        {!isCollectionLoading && (
          <>
            {!isUnlockedReportsCollection && !isData && !isBlackListCollection && (
              <Styled.TabsWrapper>
                <Stack direction="row" alignItems="center">
                  <TypeCollectionView
                    typeCollectionView={typeCollectionView}
                    onChangeTypeViewCollection={onChangeTypeViewCollection}
                    onHandleOpenViewType={onHandleOpenViewType}
                    isActiveFirstTab={isActiveFirstTab}
                    airTableLink={airTableLink}
                  />
                  <Tabs tabs={tabs} activeTabId={activeTabId} switchTab={switchTab} />

                  <Styled.LightTooltip
                    title={
                      !creator_audience_overlaps 
                      ? labelStrings.collectionOverlap.btnTooltipFeatureNotIncluded
                      : !selectedUsersInOpenedCollection?.length
                      ? labelStrings.collectionOverlap.btnTooltipTextDefault
                      : isDisabledCreateOverlap
                      ? labelStrings.collectionOverlap.btnTooltipTextDontMeetCriteria
                      : null
                    }
                  >
                    <Box>
                      <Button
                        disabled={isDisabledCreateOverlap}
                        onClick={
                          selectedUsersCountNotUnlocked > 0 && audience_tokens_monthly >= 0 
                            ? onOpenOverlapConfirmModal
                            : OnAddProfilesToOverlap
                        }
                        variant="outlined"
                        sx={{
                          p: '7px 15px',
                          border: '1px solid #CBB3B3',
                          marginLeft: 2,
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          color: '#BC9797',
                          fontSize: '15px',
                          lineHeight: '19px',
                          fontFamily: `Inter,sans-serif !important`,
                          '&:hover': {
                            background: `linear-gradient(90deg,#FFBC00 0.35%,#FF5D5A 100%)`,
                            color: 'white',
                            border: 'none',
                            p: '8px 16px',
                          },
                        }}
                      >
                        {labelStrings.collectionOverlap.btnText(
                          selectedUsersInOpenedCollection.length
                        )}
                      </Button>
                    </Box>
                  </Styled.LightTooltip>
                </Stack>

                {activeTabId === COLLECTIONS_TABS_TYPE.listTab && (
                  <div className="tabs-end">
                    {typeCollectionView !== COLLECTION_VIEW_MODE.airtable && (
                      <CollectionSearch
                        value={searchValue}
                        setValue={setSearchValue}
                        collectionViewMode={toggleSwitchState}
                      />
                    )}
                    {toggleSwitchState && typeCollectionView !== COLLECTION_VIEW_MODE.airtable && (
                      <CollectionsEditFields
                        onChangeHiddenFields={onChangeHiddenFields}
                        hiddenFields={defaultHiddenFields}
                        additionalFields={additionalFields}
                        setCurrFieldsState={setCurrFieldsState}
                      />
                    )}
                  </div>
                )}
              </Styled.TabsWrapper>
            )}

            {currentCollectionsLength >= 50 && activeTabId === COLLECTIONS_TABS_TYPE.overlapTab && (
              <Styled.UnlockedInfluencers>
                <Styled.IconWrapper>
                  <InfoCircleIcon isGradient />
                </Styled.IconWrapper>
                <Styled.UnlockedInfluencersText>
                  {labelStrings.overlapUpTo50Influencers}
                </Styled.UnlockedInfluencersText>
              </Styled.UnlockedInfluencers>
            )}

            {isYourOverlapsPage ? (
              <Styled.CollectionUsersResultsWrapper>
                <YourOverlaps />
              </Styled.CollectionUsersResultsWrapper>
            ) : (
              (() => {
                switch (activeTabId) {
                  case COLLECTIONS_TABS_TYPE.listTab:
                    return (
                      <>
                        {typeCollectionView === COLLECTION_VIEW_MODE.list ||
                        typeCollectionView === COLLECTION_VIEW_MODE.detailed ? (
                          <Styled.CollectionUsersWrapper>
                            {hasPagination && PaginationComponent}

                            <Styled.CollectionUsersResultsWrapper hasPagination={hasPagination}>
                              <ListWrapper hasagination={hasPagination}>
                                <ProfileListFullTableContainer
                                  users={
                                    isBlackListCollection &&
                                    !!filteredSearchCollectionsBlackList?.length
                                      ? filteredSearchCollectionsBlackList
                                      : filteredSearchCollectionsList
                                  }
                                  isBlackListCollection={isBlackListCollection}
                                  isCollectionView
                                  isUnlockedCollectionOpened={isUnlockedCollectionOpened}
                                  beforeRedirectPage={pagesBeforeProfileRedirect.COLLECTION}
                                  areAllSelected={areAllSelectedInOpenedCollection}
                                  actionsHandlers={handlersMap}
                                  onBlockUnblockUser={onBlockUnblockUser}
                                  isListMode={toggleSwitchState}
                                  tableHeaderProps={tableHeaderProps}
                                  currentFieldsState={currentFieldsState}
                                  additionalFields={additionalFields}
                                />
                              </ListWrapper>
                              {/* this  */}
                              {everythingHasLoaded && isNoData && (
                                <CollectionPageTips
                                  disabledCollections={!enable_collections}
                                  noPlan={no_plan}
                                />
                              )}
                            </Styled.CollectionUsersResultsWrapper>

                            {hasPagination && PaginationComponent}
                          </Styled.CollectionUsersWrapper>
                        ) : (
                          <Box
                            sx={{
                              my: 2,
                              backgroundColor: !airTableLink ? 'inherit' : '#FFF9F9',
                              borderRadius: '13px',
                            }}
                          >
                            <CollectionAirTable airTableLink={airTableLink} everythingHasLoaded={everythingHasLoaded}/>
                          </Box>
                        )}
                      </>
                    )
                  case COLLECTIONS_TABS_TYPE.audienceTab:
                    return <CollectionAudience />
                  case COLLECTIONS_TABS_TYPE.overlapTab:
                    return <CollectionOverlap />
                  case COLLECTIONS_TABS_TYPE.savedContent:
                    return (
                      <CollectionSavedContent
                        collectionData={openedCollection?.users?.contentData}
                        openedCollection={openedCollection}
                      />
                    )
                }
              })()
            )}
          </>
        )}
      </MainContainer>
      <ConfirmWhenUnlockedUsersModal
        isOpenModal={openedCollection.overlap.confirmOverlapModal}
        closeModal={onCloseOverlapConfirmModal}
        notUnlockedUsers={selectedUsersCountNotUnlocked}
        onReject={onCloseOverlapConfirmModal}
        onResolve={OnAddProfilesToOverlap}
      />
      <CollectionInitialOverlapModal
        isOpenModal={openedCollection.overlap.initialOverlapModal}
        closeModal={onCloseOverlapInitialModal}
      />
    </Layout>
  )
}

CollectionsPage.propTypes = {
  collectionId: PropTypes.string,
}

export default memo(CollectionsPage)
