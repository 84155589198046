// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useTranslation from '../../../../localization/useTranslation'
import MentionedProfiles from './mentionedProfiles/MentionedProfiles'
import SponsoredMediaScores from './sponsoredMediaScores/SponsoredMediaScores'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../../commonProfilePageStyles'
import { RatingSectionContainer } from '../commonStyles'
import { Box, Stack, Typography, Button } from '@mui/material'
import { typographyStyles } from './commonStyle'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { CreatorMentionCards } from './CreatorMentionCards'
import { CreatorMentionCharts } from './CreatorMentionCharts'
import { BrandedContent } from './brandedContent/BrandedContent'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeUserProfileType,
  getContentProfile,
  setBrandCollaborations,
} from '../../../../redux/ducks/profileSelectedDucks'
import { profileBrandCollaborationsData } from '../../../../redux/selectors'
import { ContainerBrandValuePerformancePdf } from './BrandValuePerformancePdf/BrandValuePerformancePdfContainer'
import { BrandValuePerformancePdfView } from './BrandValuePerformancePdf/BrandValuePerformancePdfView'
import { updateSearch } from '../../../../redux/ducks/searchDucks'

const BrandValue = ({
  platform,
  profileScores,
  brandCollaborations,
  creatorCollaborations,
  username,
  profile,
  isUnauthenticatedAccess,
}) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { data, isLoading } = useSelector(profileBrandCollaborationsData) || {}
  const [currencyFromStorage] = useLocalStorage('currency')

  const pdfExportRef = useRef(null)

  const { mentionAccountsAndTimeline, metadata } =
    creatorCollaborations || brandCollaborations || {}

  const isBrandAccount = creatorCollaborations && Object.keys(creatorCollaborations)?.length

  const [brandedCollectionSortType, setBrandedCollectionSortType] = useState('reach')

  const firstUsername =
    brandCollaborations?.mentionAccountsAndTimeline[0]?.username ||
    creatorCollaborations?.mentionAccountsAndTimeline[0]?.username

  const payloadForGetContent = brandCollaborations
    ? {
        keywords: `@${firstUsername}`,
        username: username,
      }
    : {
        keywords: `@${username}`,
        username: firstUsername,
      }

  useEffect(() => {
    if (!isUnauthenticatedAccess) {
      dispatch(getContentProfile(payloadForGetContent))
    }
  }, [])

  const onSortedBrandedContent = filterParam => {
    if (!isUnauthenticatedAccess) {
      setBrandedCollectionSortType(filterParam)
      dispatch([
        setBrandCollaborations({}),
        getContentProfile({
          ...payloadForGetContent,
          sorting: filterParam,
        }),
      ])
    }
  }

  const onChangeBrandProfileType = () => {
    dispatch(
      changeUserProfileType({
        profileId: profile?._id,
        switchBrandCreator: true,
      })
    )
  }

  const onGetSimilarContentPieces = contentId => {
    history.push('/search?fromContentSearch=true')
    dispatch(
      updateSearch({
        newValuesObj: {
          contentId: contentId,
        },
      })
    )
  }

  return (
    <>
      <RatingSectionContainer>
        <SectionTitleWrapper>
          <SectionTitleName platform={platform}>{labelStrings.brandValue}</SectionTitleName>
          <SectionTitleBorder />
          {isBrandAccount && (
            <ContainerBrandValuePerformancePdf
              platform={platform}
              username={username}
              pdfRef={pdfExportRef?.current}
            />
          )}
        </SectionTitleWrapper>

        <Stack>
          {!brandCollaborations && mentionAccountsAndTimeline?.length > 0 ? (
            <Stack direction="row" justifyContent="space-between" spacing={10}>
              <Stack sx={{ width: '45%' }} direction="column" spacing={2}>
                <Box
                  sx={{
                    width: 'fit-content',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontFamily: 'Inter',
                      color: '#784E4E',
                    }}
                  >
                    {`${labelStrings.notABrandAccount}`}{' '}
                    <Typography
                      display="inline"
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: '10px',
                        fontFamily: 'Inter',
                        color: '#784E4E',
                        cursor: 'pointer',
                        '&:hover': { opacity: 0.7 },
                      }}
                      onClick={onChangeBrandProfileType}
                    >
                      {labelStrings.clickHere},
                    </Typography>
                    {` ${labelStrings.toChangeBrandAccount}`}
                  </Typography>
                </Box>

                <Typography sx={typographyStyles}>
                  {labelStrings.brandsTimelineText.creatorsThatMentionedTheBrand}
                </Typography>

                <CreatorMentionCards
                  username={username}
                  currency={currencyFromStorage}
                  mentionAccountsAndTimeline={mentionAccountsAndTimeline}
                  metadata={metadata}
                />
              </Stack>
              <CreatorMentionCharts
                metadata={metadata}
                platform={platform}
                creatorsCount={mentionAccountsAndTimeline?.length}
              />
            </Stack>
          ) : (
            <Box sx={{ mt: 5 }}>
              <SponsoredMediaScores profileScores={profileScores} />
            </Box>
          )}
          <Box>
            <MentionedProfiles
              mentionAccountsAndTimeline={mentionAccountsAndTimeline}
              metadata={metadata}
              mentionedProfiles={mentionAccountsAndTimeline || []}
              mentionTimeline={brandCollaborations?.mentionsArrTimeline}
              brandCollaborations={brandCollaborations}
              profileUsername={username}
              onChangeBrandProfileType={onChangeBrandProfileType}
            />
          </Box>
          {!isUnauthenticatedAccess && (
            <Box id="branded-content">
              <BrandedContent
                allContentPieces={data?.content}
                onSortedBrandedContent={onSortedBrandedContent}
                brandedCollectionSortType={brandedCollectionSortType}
                isLoading={isLoading}
                onGetSimilarContentPieces={onGetSimilarContentPieces}
              />
            </Box>
          )}
        </Stack>
      </RatingSectionContainer>

      {isBrandAccount && (
        <BrandValuePerformancePdfView
          ref={pdfExportRef}
          profile={profile}
          currency={currencyFromStorage === 'usd' ? '$' : currencyFromStorage === 'eur' ? '€' : '£'}
        />
      )}
    </>
  )
}

export default BrandValue
