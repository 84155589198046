import React from 'react'
import { Avatar, Box, InputAdornment, Typography, Tooltip, IconButton, Stack } from '@mui/material'
import { StyledButton, StyledBox } from './styles'
import InfoIcon from '@mui/icons-material/Info'
import { NewInstagramIcon, NewTikTokIcon, PlusIcon } from '../../../assets/icons/navbar'
import Label from '../../../components/label'
import { RHFSwitch, RHFTextField } from '../../../components/hook-form'
import Iconify from '../../../components/iconify'
import { NewYouTubePlatformIcon } from '../../../../components/common/icons'
import useTranslation from '../../../../localization/useTranslation'

export const LeftSideFields = ({
  setDynamicFields,
  dynamicFields,
  editItem,
  handleOpenPopover,
  resetField,
  editFields,
  setValue,
  watch,
}) => {
  const { labelStrings, currentLang } = useTranslation()

  const shouldContactCreator = watch('shouldContactCreator')

  const removeField = (field, value) => {
    resetField(`${field}`)
    setDynamicFields({ ...dynamicFields, [field]: value })
  }

  const goToProfile = () => {
    window.open(`/profile/${editItem?.id}`, '_blank').focus()
  }

  return (
    <Box
      sx={{
        paddingBottom: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Label
        variant="soft"
        color={
          editItem.status === 'active'
            ? 'success'
            : editItem.status === 'archived'
            ? 'error'
            : 'warning'
        }
        sx={{ textTransform: 'capitalize' }}
      >
        {editItem.status}
      </Label>
      <Avatar
        alt="profile avatar"
        src={editItem.avatarUrl}
        sx={{ width: 133, height: 133, marginTop: 1, marginBottom: 2 }}
      />

      <StyledBox>
        {editItem?.platform === 'instagram' && (
          <StyledButton
            onClick={goToProfile}
            size="small"
            variant="outlined"
            startIcon={<NewInstagramIcon />}
          >
            Instagram
          </StyledButton>
        )}
        {editItem?.platform === 'tiktok' && (
          <StyledButton
            onClick={goToProfile}
            size="small"
            variant="outlined"
            startIcon={<NewTikTokIcon />}
          >
            TikTok
          </StyledButton>
        )}
        {editItem?.platform === 'youtube' && (
          <StyledButton
            onClick={goToProfile}
            size="small"
            variant="outlined"
            startIcon={<NewYouTubePlatformIcon isActiveIcon />}
          >
            YouTube
          </StyledButton>
        )}
        <StyledButton
          size="small"
          variant="outlined"
          startIcon={<PlusIcon />}
          onClick={handleOpenPopover}
          sx={{ py: currentLang === 'de' ? 3 : 2 }}
          disabled
        >
          {labelStrings?.creatorOverview?.mergeOtherSocialMedia}
        </StyledButton>
      </StyledBox>

      <Stack spacing={3} sx={{ my: 2 }}>
        <Stack direction="row" alignItems="center">
          <RHFSwitch name="status" labelPlacement="start" label="" sx={{ m: 0 }} />
          <Stack sx={{ maxWidth: '215px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
              {labelStrings?.creatorOverview?.setCreatorAsArchived}
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              {labelStrings?.creatorOverview?.setCreatorAsArchivedSubtitle}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center">
          <RHFSwitch
            name="shouldContactCreator"
            labelPlacement="start"
            label=""
            sx={{ m: 0 }}
            disabled
          />
          <Stack direction="row" alignItems="center" sx={{ maxWidth: '215px' }}>
            <Typography
              sx={{ fontSize: '14px', fontWeight: '600', color: 'rgba(145, 158, 171, 0.8)' }}
            >
              {labelStrings?.creatorOverview?.requestMetrics}
            </Typography>
            <Tooltip title={labelStrings?.creatorOverview?.requestMetricsSubtitle} placement="top">
              <IconButton size="small" sx={{ ml: 1 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>

      {shouldContactCreator && (
        <StyledBox>
          {!!dynamicFields.phone && (
            <RHFTextField
              size="small"
              name="phone"
              label="Number"
              sx={{ marginBottom: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{ cursor: 'pointer' }}
                    position="end"
                    onClick={() => removeField('phone', '')}
                  >
                    <Iconify icon="lucide:trash-2" />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <StyledButton
            size="small"
            variant="outlined"
            startIcon={<PlusIcon />}
            onClick={() => {
              setDynamicFields({ ...dynamicFields, phone: '+' })
              setValue('phone', '+', { shouldValidate: false })
            }}
            disabled={!!dynamicFields.phone}
          >
            Add What’sApp Number
          </StyledButton>
          {!!dynamicFields.email && (
            <RHFTextField
              size="small"
              name="email"
              label="Email"
              sx={{ marginBottom: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{ cursor: 'pointer' }}
                    position="end"
                    onClick={() => removeField('email', '')}
                  >
                    <Iconify icon="lucide:trash-2" />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <StyledButton
            size="small"
            variant="outlined"
            startIcon={<PlusIcon />}
            onClick={() => {
              setDynamicFields({ ...dynamicFields, email: ' ' })
              setValue('email', '', { shouldValidate: false })
            }}
            disabled={!!dynamicFields.email}
          >
            Add Email
          </StyledButton>
        </StyledBox>
      )}

      <Stack spacing={3} sx={{ my: 2 }}>
        <Stack direction="row" alignItems="center">
          <RHFSwitch
            name="overwriteAudience"
            labelPlacement="start"
            label=""
            value={editFields?.overwriteAudience || false}
            sx={{ m: 0 }}
          />
          <Stack sx={{ maxWidth: '215px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
              {labelStrings?.creatorOverview?.audienceDemographics}
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              {labelStrings?.creatorOverview?.audienceDemographicsSubtitle}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <RHFSwitch
            name="enablePricing"
            labelPlacement="start"
            label=""
            value={editFields?.enablePricing || false}
            sx={{ m: 0 }}
          />
          <Box sx={{ maxWidth: '215px' }}>
            <Stack sx={{ maxWidth: '215px' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                {labelStrings?.creatorOverview?.enablePricingAndCosts}
              </Typography>
              <Typography sx={{ fontSize: '12px' }}>
                {labelStrings?.creatorOverview?.enablePricingAndCostsSubtitle}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <StyledButton
        size="small"
        variant="outlined"
        sx={{ width: '100%', py: currentLang === 'de' ? 3 : 2 }}
        startIcon={<PlusIcon />}
        disabled
      >
        {labelStrings?.creatorOverview?.createBitLinkForThisCreator}
      </StyledButton>
    </Box>
  )
}
