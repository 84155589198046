import React from 'react'
import { useTheme, CardHeader, Box } from '@mui/material'
import { fNumber } from '../../../../utils/formatNumber'
import Chart, { useChart } from '../../index'
import { fPercent } from '../../../../utils/formatNumber'
import { fShortenNumber } from '../../../../utils/formatNumber'

const findByLabel = ({ findKey, arr }) => arr?.find(el => el.label === findKey)
const findByValue = ({ findKey, arr }) => arr?.find(el => el.value === Number(findKey))

export const PieChartExport = ({
  title,
  subheader = '',
  chart,
  height = '480px',
  isPossibleDisplayOtherValue = false,
  ...other
}) => {
  const theme = useTheme()

  const { colors, series, options } = chart

  const chartSeries = series?.map(i => i.value)
  const maxValueItem = series?.sort((a, b) => b.value - a.value)[0]
  const labels = series?.map(i => i.label)
  const otherIndex = labels?.indexOf('Other')

  if (otherIndex !== -1 && isPossibleDisplayOtherValue) {
    labels?.splice(otherIndex, 1)
    labels?.push('Other')
  }

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors,
    labels: labels,
    stroke: { colors: [theme.palette.background.paper], width: 0 },
    legend: {
      floating: true,
      position: 'bottom',
      offsetY: 0,
      horizontalAlign: 'center',
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: value => `${fNumber(value)}%`,
        title: {
          formatter: seriesName => `${seriesName}`,
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            name: {
              formatter: function(label) {
                const item = findByLabel({ arr: series, findKey: label })
                return `${label} ${item ? `${item.value}%` : ``}`
              },
            },
            value: {
              formatter: function(value) {
                const item = findByValue({ arr: series, findKey: value })
                if (!item?.absoluteReach) return
                return `${fShortenNumber(item.absoluteReach)} reached`
              },
            },
            total: {
              label: `${maxValueItem?.label} ${fPercent(maxValueItem?.value)}`,
              fontSize: '16px',
              showAlways: false,
              formatter: w => {
                if (!maxValueItem.absoluteReach) return
                return `${fShortenNumber(maxValueItem.absoluteReach)} reached`
              },
            },
          },
        },
      },
    },
    ...options,
  })

  return (
    <Box
      {...other}
      sx={{
        height,
        border: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      <CardHeader title={title} subheader={subheader} />
      <Box
        sx={{
          mt: 3,
          '& .apexcharts-legend': {
            width: '100%',
            pt: '50px',
            overflowY: 'hidden',
          },
          '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
            overflow: 'hidden',
          },
        }}
      >
        <Chart type="donut" series={chartSeries} options={chartOptions} height={240} />
      </Box>
    </Box>
  )
}
