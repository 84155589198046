// @ts-nocheck
import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import LoadingSpinner from '../../common/spinners/LoadingSpinner'
import { MarkedTextWrapper, NoUsersWrapper, TipWrapper } from '../../pagesTips/CollectionPageTips'
import useTranslation from '../../../localization/useTranslation'

export const CollectionAirTable = props => {
  const { airTableLink, everythingHasLoaded } = props || {}
  const { labelStrings } = useTranslation()

  const [iframeLoading, setIframeLoading] = useState(true)

  if (!airTableLink && everythingHasLoaded)
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ px: 3, py: 3, width: '100%', height: '500px' }}
      >
        <NoUsersWrapper>{labelStrings.noInfluencersInCollection}</NoUsersWrapper>
        <TipWrapper>
          {labelStrings.toAddInfluencersGoTo}{' '}
          <MarkedTextWrapper>{labelStrings.searchView}</MarkedTextWrapper>{' '}
          {labelStrings.andAddInfluencers}. {labelStrings.canCreateCollectionByClicking}{' '}
          <MarkedTextWrapper>{labelStrings.createNewCollection}</MarkedTextWrapper>{' '}
          {labelStrings.inLeftMenu}.
        </TipWrapper>
      </Stack>
    )

  const link = airTableLink?.replace('https://airtable.com/', 'https://airtable.com/embed/')

  return (
    <Stack sx={{ px: 3, py: 3 }}>
      {iframeLoading && (
        <LoadingSpinner isInsidePageLoad showOnFullPage height={'100%'} width={'100%'} />
      )}
      <Box
        sx={{
          width: iframeLoading ? 0 : '100%',
          height: iframeLoading ? 0 : '100%',
          overflow: 'hidden',
        }}
      >
        {airTableLink && (
          <iframe
            className="airtable-embed"
            src={link}
            frameBorder="0"
            onLoad={() => setIframeLoading(false)}
            width={iframeLoading ? 0 : '100%'}
            height={iframeLoading ? 0 : '100%'}
            style={{
              background: 'transparent',
              border: '1px solid #ccc',
              borderRadius: '8px',
              height: '75vh',
            }}
          />
        )}
      </Box>
    </Stack>
  )
}
