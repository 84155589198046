import React, { useEffect, useMemo, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
  Slider,
  Typography,
} from '@mui/material'
import { COMMA_SPLITTER, UNDERSCORE_SPLITTER } from '../../../../../constants/search'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import { getUniqueId } from '../utils'
import { findCountryIsoCode, getPngCountryFlags } from '../../../../../utils'
import { simulateResize } from '../../../../pages/Search/utils'

const formControlStyles = {
  m: 1,
  width: 300,
  '& .MuiOutlinedInput-root': {
    outline: 'unset',
    '& fieldset': {
      borderColor: '#BC9797',
    },
    '&:hover fieldset': {
      borderColor: '#784E4E',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#784E4E',
    },
  },
}
const sliderStyles = {
  mt: '-8px',
  width: '100%',
  height: '5px',
  borderRadius: '8px',
  color: '#FF5C00',
  '& .MuiSlider-valueLabelOpen': {
    fontWeight: 'bold',
    color: '#FF5500',
  },
  '& .MuiSlider-valueLabel': {
    background: 'unset',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, 100%) rotate(45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -40%) rotate(-225deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(-135deg)',
    },
  },
}
const inputLabelStyles = {
  color: '#784E4E',
  fontWeight: 'bold',
  '&.Mui-focused': {
    color: '#784E4E',
  },
}
const selectLabelStyles = {
  color: '#784E4E',
  fontWeight: 500,
  fontSize: '11px',
  textTransform: 'uppercase',
  fontFamily: 'Inter',
}

const filterObjectByKey = (originalObject, keyToRemove) => {
  const filteredObject = {}
  for (const key in originalObject) {
    if (key !== keyToRemove) {
      filteredObject[key] = originalObject[key]
    }
  }
  return filteredObject
}

export const AudienceCountryFilter = ({ query, handlersMap, isModalOpen }) => {
  const { countryOptions } = useTranslation()

  const audienceCountries = query.audienceCountries || ''

  const countries = useMemo(() => {
    return audienceCountries
      ? audienceCountries.split(COMMA_SPLITTER).map(c => {
          const [country, range] = c.split(UNDERSCORE_SPLITTER)
          return { country, range: parseInt(range, 10) }
        })
      : []
  }, [audienceCountries])

  const [selectCount, setSelectCount] = useState([])
  const [localSliderValue, setLocalSliderValue] = useState({})
  const [currHeight, setCurrHeight] = useState(125)

  const handleChange = (newValue, id) => {
    setLocalSliderValue(prev => ({
      // @ts-ignore
      ...prev,
      [id]: newValue,
    }))
  }

  const updateCountries = updatedCountries => {
    if (!audienceCountries && !updatedCountries.length) return

    const newAudienceCountries = updatedCountries
      ?.map(({ country, range }) => `${country}${UNDERSCORE_SPLITTER}${range}`)
      ?.join(COMMA_SPLITTER)

    handlersMap.audience({
      ...query,
      audienceCountries: newAudienceCountries,
    })
  }

  const onChangeQueryValues = (value, country, id) => {
    if (!country) return

    const updated = value
      ? countries.map(c => (c.country === country ? { ...c, range: value } : c))
      : countries.filter(c => c.country !== country)

    updateCountries(updated)
  }

  const onChangeAudienceCountries = prevCountry => (newCountryObj, rangeValue) => {
    const newCountryValue = newCountryObj?.value || newCountryObj

    if (countries?.some(el => el?.country === newCountryValue)) return

    if (!newCountryObj || newCountryValue === 'clear') {
      updateCountries(countries.filter(c => c.country !== prevCountry))
      return
    }

    if (prevCountry === newCountryValue) return

    const updatedCountries = countries.some(c => c.country === prevCountry)
      ? countries.map(c => (c.country === prevCountry ? { ...c, country: newCountryValue } : c))
      : [...countries, { country: newCountryValue, range: 10 }]
    updateCountries(updatedCountries)

    setLocalSliderValue(prev => ({ ...prev, [selectCount[selectCount?.length - 1]]: 10 }))
  }

  const handleAddComponent = () => {
    if (selectCount.length < 3) {
      const newId = getUniqueId()
      setSelectCount(prevSelectCount => [...prevSelectCount, newId])
      setLocalSliderValue(prevValues => ({ ...prevValues, [newId]: 0 }))
    }
  }

  const handleRemoveComponent = index => {
    const getSliderValueByIndex = Object.keys(localSliderValue)[index]
    const updatedSelectCounts = selectCount?.slice(index, 1)
    const updatedSliderValues = filterObjectByKey(localSliderValue, getSliderValueByIndex)

    setSelectCount(updatedSelectCounts)
    setLocalSliderValue(updatedSliderValues)
    const countryToRemove = countries[index]?.country
    if (countryToRemove) {
      onChangeAudienceCountries(countryToRemove)(null)
    }
  }

  const initialSetup = useMemo(() => {
    if (audienceCountries) {
      const countries = audienceCountries.split(COMMA_SPLITTER)
      const ids = countries.map(() => getUniqueId())
      const values = countries.map(el => parseInt(el.split(UNDERSCORE_SPLITTER)[1], 10))

      const sliderValues = ids.reduce((obj, id, idx) => {
        obj[id] = values[idx]
        return obj
      }, {})

      return { ids, sliderValues }
    }
    return { ids: [], sliderValues: {} }
  }, [audienceCountries])

  useEffect(() => {
    if (!audienceCountries) {
      const initialId = getUniqueId()
      setSelectCount([initialId])
      setLocalSliderValue({ [initialId]: 0 })
    } else {
      setSelectCount(initialSetup.ids)
      setLocalSliderValue(initialSetup.sliderValues)
    }
  }, [audienceCountries, initialSetup])

  useEffect(() => {
    setCurrHeight(120 * selectCount?.length)
    simulateResize()
  }, [selectCount?.length])

  return (
    <Stack spacing={3} sx={{ minHeight: '125px', height: `${currHeight}px` }}>
      {selectCount?.map((id, idx) => {
        const isLastOne = idx === selectCount?.length - 1
        const selected = countries[idx] || {}
        const currentCountry = countryOptions.find(el => el.value === selected.country)?.value || ''
        const currentSliderValue = localSliderValue[id]
        return (
          <CurrentSelect
            key={`${id}-${currentCountry}-${selected.range}`}
            currentCountry={currentCountry}
            onChangeAudienceCountries={onChangeAudienceCountries}
            selected={selected}
            countryOptions={countryOptions}
            selectCount={selectCount}
            currentSliderValue={currentSliderValue}
            isLastOne={isLastOne}
            handleAddComponent={handleAddComponent}
            handleRemoveComponent={handleRemoveComponent}
            handleChange={handleChange}
            onChangeQueryValues={onChangeQueryValues}
            countries={countries}
            id={id}
            idx={idx}
          />
        )
      })}
    </Stack>
  )
}

const CurrentSelect = props => {
  const {
    currentCountry,
    onChangeAudienceCountries,
    selected,
    countryOptions,
    selectCount,
    currentSliderValue,
    isLastOne,
    handleAddComponent,
    handleRemoveComponent,
    handleChange,
    onChangeQueryValues,
    id,
    idx,
    countries,
  } = props || {}

  const { labelStrings } = useTranslation()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (isModalOpen && idx === 0) {
  //       setOpen(true)
  //     } else {
  //       setOpen(false)
  //     }
  //   }, 200)

  //   return () => clearTimeout(timer)
  // }, [isModalOpen])

  return (
    <Stack spacing={0}>
      <Stack direction="row" alignItems="center" sx={{ width: '280px' }}>
        <FormControl sx={formControlStyles}>
          <InputLabel sx={inputLabelStyles} size="small">
            {labelStrings.pleaseSelectCountry}
          </InputLabel>
          <Select
            size="small"
            open={open}
            onClose={handleClose}
            onOpen={() => setOpen(true)}
            value={currentCountry}
            onChange={e => onChangeAudienceCountries(selected.country)(e.target.value)}
            input={<OutlinedInput label={labelStrings.pleaseSelectCountry} />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                  color: '#442424',
                },
              },
            }}
          >
            {countryOptions.map(country => (
              <MenuItem
                key={country.isoCode}
                value={country.value}
                disabled={countries?.some(el => el?.country === country.value)}
                sx={{
                  fontSize: '12px',
                  fontWeight: 700,
                  fontFamily: 'Inter',
                }}
              >
                <Stack direction="row" alignItems="center">
                  {getPngCountryFlags(findCountryIsoCode(countryOptions, country.value))}{' '}
                  {country?.value}
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {idx < 2 && (
          <IconButton
            onClick={isLastOne ? handleAddComponent : () => handleRemoveComponent(idx)}
            disabled={isLastOne && selectCount.length >= 3}
          >
            {isLastOne ? (
              <AddRoundedIcon
                sx={{
                  fill: selectCount.length >= 2 ? '#637381' : '#784E4E',
                }}
              />
            ) : (
              <RemoveRoundedIcon sx={{ fill: '#784E4E' }} />
            )}
          </IconButton>
        )}
      </Stack>
      <Stack sx={{ px: 2 }}>
        <Typography sx={selectLabelStyles}>{labelStrings.minAudienceFromCountry}</Typography>
        <Slider
          disabled={!currentCountry}
          sx={sliderStyles}
          value={currentSliderValue}
          onChange={(e, value) => handleChange(value, id)}
          onChangeCommitted={(e, value) => onChangeQueryValues(value, currentCountry, id)}
          valueLabelDisplay="on"
          valueLabelFormat={val => (val === 0 ? 'Off' : `≥ ${Math.abs(val)}%`)}
          min={0}
          max={90}
        />
      </Stack>
    </Stack>
  )
}
