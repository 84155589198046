import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useTranslation from '../../../../../localization/useTranslation'
import WithUpgradeTip from '../../../../../components/common/popups/WithUpgradeTip'
import { BlockUserIcon } from '../../../../../components/common/icons'
import { Button, Stack, Tooltip, Typography } from '@mui/material'

const IconWrapper = styled.div`
  ${props => props.disabled && props.theme.disableBlock};
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
  ._icon-container {
    margin: 7.5px;
  }
`
const UpgradeLinkWrapper = styled(Link)`
  color: ${props => props.theme.color.black};
  text-decoration: underline;
  font-family: Inter;
  color: #442424;
  &:hover {
    color: ${props => props.theme.color.black};
    opacity: 0.9;
  }
`

const ProfileBlackListTool = ({
  isBlack = false,
  userindex = '',
  userId = '',
  onBlockUnblockUser,
  isUserAuthenticated = false,
  enableBlackList = false,
  isQuickPreview,
}) => {
  const { labelStrings, currentLang } = useTranslation()

  const TipComponent = useMemo(
    () => (
      <>
        {enableBlackList ? (
          <Stack>
            <UpgradeLinkWrapper to="/settings">{labelStrings.contactSales}</UpgradeLinkWrapper>{' '}
            <Typography sx={{ fontSize: '14px', fontFamily: 'Inter', lineHeight: 'initial' }}>
              {labelStrings.to} {labelStrings.addUserToBlackListTip}
            </Typography>
          </Stack>
        ) : isBlack ? (
          <Typography sx={{ fontSize: '14px', fontFamily: 'Inter', lineHeight: 'initial' }}>
            {labelStrings.removeFromBlackList}
          </Typography>
        ) : (
          <Stack spacing={0}>
            <Typography sx={{ fontSize: '14px', fontFamily: 'Inter', lineHeight: 'initial' }}>
              {labelStrings.blockUserTipsFirstPart}
            </Typography>
            <Link to="/collections/blacklist">
              <u>{labelStrings.blockUserTipsLastPart}</u>
            </Link>
          </Stack>
        )}
      </>
    ),
    [currentLang, isBlack, enableBlackList]
  )

  const onHandleClick = (e) => {
    if(enableBlackList || !isUserAuthenticated) return
    onBlockUnblockUser({ e, profileId: userId, isBlack })
  }

  return (
    <Button
      onClick={onHandleClick}
      disableFocusRipple
      disableRipple
      size="small"
      sx={{
        fontWeight: 400,
        width: 'fit-content',
        minWidth: '30px',
        p: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiTooltip-tooltip': {
          boxShadow: 'none !important',
        },
      }}
    >
      {!isUserAuthenticated ? (
        <WithUpgradeTip
          tipText={labelStrings.addMoreUsers}
          userindex={userindex}
          withSignup={!isUserAuthenticated}
          position={'left center'}
          arrow={isQuickPreview ? false : true}
        >
          <IconWrapper disabled={enableBlackList}>
            <BlockUserIcon userindex={userindex} isActiveIcon={isBlack} />
          </IconWrapper>
        </WithUpgradeTip>
      ) : (
        <Tooltip
          sx={{ boxShadow: 'none !important' }}
          title={TipComponent}
          arrow={isQuickPreview ? false : true}
        >
          <IconWrapper disabled={enableBlackList}>
            <BlockUserIcon userindex={userindex} isActiveIcon={isBlack} />
          </IconWrapper>
        </Tooltip>
      )}
    </Button>
  )
}

export default ProfileBlackListTool
