import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Box, Card, CardHeader, IconButton, Menu, MenuItem, Portal } from '@mui/material'
import Chart, { useChart } from '../index'
import { TooltipComponent } from '../../tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { pretifyBigNum } from '../../../../utils'
import html2canvas from 'html2canvas'
import pptxgen from 'pptxgenjs'
import DownloadIcon from '@mui/icons-material/Download'
import { BarChartExport } from './Export/BarChartExport'

export const BarChart = ({
  title,
  subheader,
  chart,
  height = '464px',
  helperText = '',
  isForPdf,
  ...other
}) => {
  const { colors, series, options } = chart
  const chartSeries = series.map(i => i.value)

  const chartRef = useRef(null)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const exportElement = chartRef.current

    const cardImage = await html2canvas(exportElement, { scale: 2 })
    const cardDataUrl = cardImage.toDataURL('image/jpeg', 1.0)

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const cardAspectRatio = exportElement.offsetWidth / exportElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / cardAspectRatio

    slide.addImage({
      data: cardDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: slideHeight,
      type: 'contain',
    })
    pptx.writeFile('Chart.pptx').then(() => {
      handleDownloadClose()
    })
  }

  const exportToImage = async () => {
    const exportElement = chartRef.current
    const options = {
      scale: 2,
      useCORS: true,
      logging: true,
    }

    const cardImage = await html2canvas(exportElement, options)
    const cardDataUrl = cardImage.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = cardDataUrl
    link.download = 'Chart.png'
    link.click()
    handleDownloadClose()
  }

  const chartOptions = useChart({
    colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: value => pretifyBigNum(value),
        title: {
          formatter: () => '',
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: series.map(i => i.label),
      labels: {
        show: !chartSeries?.length ? false : true,
      },
      ...options?.xaxis,
    },
    yaxis: {
      labels: {
        show: !chartSeries?.length ? false : true,
      },
      ...options?.yaxis,
    },
    ...options,
  })

  return (
    <>
      <Card {...other} sx={{ height }}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <>
              <>
                <IconButton aria-label="Download" onClick={handleDownloadClick}>
                  <DownloadIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
                  <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
                  <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
                </Menu>
              </>
              {helperText?.length && (
                <IconButton aria-label="Helper-Text">
                  <TooltipComponent text={helperText}>
                    <InfoOutlinedIcon />
                  </TooltipComponent>
                </IconButton>
              )}
            </>
          }
        />

        <Box sx={{ mx: 3 }} dir="ltr">
          <Chart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
        </Box>
      </Card>
      <Portal>
        <Box
          ref={chartRef}
          sx={{ position: `fixed`, top: `-9999px`, left: `-9999px`, zIndex: `-9999` }}
        >
          <BarChartExport
            title={title}
            subheader={subheader}
            chart={chart}
            height={height}
            other={other}
          />
        </Box>
      </Portal>
    </>
  )
}

BarChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.string,
  isForPdf: PropTypes.bool,
}
