import { styled } from '@mui/material'

const PIE_CHART_HEIGHT = 360
const PIE_LEGEND_HEIGHT = 72

export const StyledPieChart = styled('div')(({ theme }) => ({
  height: PIE_CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': {
    height: PIE_CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    width: '100%',
    height: PIE_LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${PIE_CHART_HEIGHT - PIE_LEGEND_HEIGHT}px) !important`,
    justifyContent: 'space-around !important',
    overflowY: 'hidden'
  },
}))

export const StyledStackedColumnChart = styled('div')({
  '& .apexcharts-legend': {
    maxWidth: '480px',
  },
})
