import React from 'react'
import PropTypes from 'prop-types'
import { Stack, TableRow, TableCell, Typography, Button } from '@mui/material'
import { fShortenNumber } from '../../../../utils/formatNumber'
import useTranslation from '../../../../../localization/useTranslation'
import { formatDistanceToNow } from 'date-fns'

export const OverviewRows = ({ row, setIsHideFields, isForPdf }) => {
  const { clicks, clicksAverage, label, lastClick, topCountry, topReferral } = row

  const { labelStrings } = useTranslation()

  const isBoldCell = label?.startsWith('total')

  return (
    <TableRow
      hover
      sx={{
        borderTop:
          label !== 'total' && isBoldCell ? '1px solid rgba(145, 158, 171, 0.24)' : 'inherit',
        borderBottom: isBoldCell ? '1px solid rgba(145, 158, 171, 0.24)' : 'inherit',
      }}
    >
      <TableCell
        align={label === 'total' ? 'center' : 'left'}
        sx={{ borderRight: '2px solid rgba(145, 158, 171, 0.24)', pt: isForPdf ? 1 : 0 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={label === 'total' ? 'center' : 'start'}
          spacing={2}
        >
          {label !== 'total' ? (
            <Typography
              noWrap
              variant={isBoldCell ? 'subtitle2' : 'inherit'}
              sx={{ textTransform: 'capitalize', minWidth: '75px', pb: isForPdf ? 1 : 0 }}
            >
              {label || labelStrings.campaignsMetricsTable.tabsLabels.overview}
            </Typography>
          ) : (
            <Typography
              textAlign="center"
              noWrap
              variant={'subtitle2'}
              sx={{ textTransform: 'capitalize', pb: isForPdf ? 1 : 0 }}
            >
              {label}
            </Typography>
          )}
        </Stack>
        {label === 'total' && !isForPdf && (
          <Stack sx={{ mt: 2, borderTop: '1px solid rgba(145, 158, 171, 0.24)' }}>
            <Button sx={{ mt: 1 }} onClick={() => setIsHideFields(prev => !prev)}>
              {labelStrings.showHide}
            </Button>
          </Stack>
        )}
      </TableCell>

      <TableCell
        align="center"
        sx={{ textTransform: 'capitalize', verticalAlign: isBoldCell ? 'top' : 'center' }}
      >
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {clicks ? fShortenNumber(clicks) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ verticalAlign: isBoldCell ? 'top' : 'center' }}>
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {clicksAverage ? fShortenNumber(clicksAverage) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ verticalAlign: isBoldCell ? 'top' : 'center' }}>
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {lastClick ? formatDistanceToNow(new Date(lastClick), { addSuffix: true }) : ''}
        </Typography>
      </TableCell>

      <TableCell
        align="center"
        sx={{ textTransform: 'capitalize', verticalAlign: isBoldCell ? 'top' : 'center' }}
      >
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {topCountry ? topCountry : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ verticalAlign: isBoldCell ? 'top' : 'center' }}>
        <Typography
          noWrap
          variant={isBoldCell ? 'subtitle2' : 'inherit'}
          sx={{ pb: isForPdf ? 1 : 0 }}
        >
          {topReferral ? topReferral : ''}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

OverviewRows.propTypes = {
  row: PropTypes.object,
}
