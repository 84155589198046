import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, InputAdornment, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import FormProvider, { RHFTextField } from '../../../components/hook-form'
import { PopoverItemMenu } from './PopoverItemMenu'
import { PricingFields } from './PricingFIelds'
import { AudienceDemographicFields } from './AudienceDemographicFields'
import { LeftSideFields } from './LeftSideFields'
// import ConfirmDialog from '../../../components/confirm-dialog'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import useTranslation from '../../../../localization/useTranslation'

export const EditTextFields = props => {
  const {
    onSubmit,
    editItem,
    handleSubmit,
    methods,
    isSubmitting,
    resetField,
    editFields,
    setValue,
    watch,
    getValues,
    currentCurrency,
  } = props

  const { labelStrings } = useTranslation()

  const [openPopover, setOpenPopover] = useState(null)
  // const [openConfirm, setOpenConfirm] = useState(false)

  const [dynamicFields, setDynamicFields] = useState({
    email: '',
    createLink: false,
    phone: '',
  })

  const enablePricing = watch('enablePricing')
  const overwriteAudience = watch('overwriteAudience')

  useEffect(() => {
    setDynamicFields({
      ...dynamicFields,
      phone: editFields?.phone,
      email: editFields?.email,
    })
  }, [editFields])

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const onCopy = copyText => {
    return navigator.clipboard.writeText(copyText ? copyText : '')
  }

  // const handleCloseConfirm = () => {
  //   setOpenConfirm(false)
  // }

  // const handleOpenConfirm = () => {
  //   setOpenConfirm(true)
  // }

  if (!editItem) return

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ paddingLeft: '0 !important' }}>
            <LeftSideFields
              editFields={editFields}
              editItem={editItem}
              dynamicFields={dynamicFields}
              setDynamicFields={setDynamicFields}
              handleOpenPopover={handleOpenPopover}
              resetField={resetField}
              setValue={setValue}
              watch={watch}
            />
          </Grid>

          <Grid item xs={12} md={9}>
            <Box sx={{ marginLeft: 2, marginTop: 1, width: '95%' }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField
                  name="username"
                  label={labelStrings?.creatorOverview?.creatorHandle}
                  value={`@${editItem?.name}`}
                />
                <RHFTextField
                  name="overwriteName"
                  label={labelStrings?.creatorOverview?.internalName}
                />
                {editItem?.fromCampaignData?.bitlyLinks?.map((el, idx) => {
                  return (
                    <RHFTextField
                      key={el.customFieldId}
                      name={`bitlyLink_${idx}`}
                      label={el.longUrl}
                      sx={{
                        '& .MuiInputBase-input': {
                          color: '#637381',
                        },
                      }}
                      value={el.shortenedUrl ? el.shortenedUrl : ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => onCopy(el.shortenedUrl)}>
                              <ContentCopyIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                })}
              </Box>

              {overwriteAudience && (
                <AudienceDemographicFields
                  isSubmitting={isSubmitting}
                  getValues={getValues}
                  watch={watch}
                />
              )}
              {enablePricing && <PricingFields watch={watch} currentCurrency={currentCurrency} />}
            </Box>
            <Stack
              direction="row"
              spacing={1.5}
              alignItems="flex-center"
              justifyContent="flex-end"
              sx={{ my: 4, marginTop: 7 }}
            >
              {/*<StyledButton
                variant="outlined"
                sx={{ fontWeight: 'bold' }}
                onClick={handleOpenConfirm}
              >
                Delete Creator
              </StyledButton>*/}

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {labelStrings?.creatorOverview?.saveChanges}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
      <PopoverItemMenu openPopover={openPopover} handleClosePopover={handleClosePopover} />
      {/* <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete the Creator?"
        content={
          <>
            The creator will be removed from your campaign and all data that was collected, will be
            deleted. If you plan to work with this creator in the future or you want to include
            metrics from this creator in your reports, please consider archiving the creator.
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleCloseConfirm()
            }}
          >
            Delete the Creator
          </Button>
        }
      /> */}
    </>
  )
}
