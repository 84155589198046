import React, { useMemo, useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Divider, Paper, Skeleton, Stack, Typography, Avatar, Tooltip } from '@mui/material'
import Carousel from 'react-multi-carousel'
import { format, parseISO } from 'date-fns'
import { RunningCampaignCardSkeleton } from './RunningCampaignCardSkeleton'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { currentLookalikesListDataLoading } from '../../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../localization/useTranslation'
import { LookalikeAiModal } from '../pages/Explore/LookalikeAi/LookalikeAiModal'
import {
  buildLookalike,
  changeAdditionalData,
  getSuggestedUsersById,
} from '../../redux/ducks/exploreDucks'
import { cancelAllFetches } from '../components/search-sidebar/searchSidebar/searchbarTipsFetches'
import {
  changeSuggestionsData,
  resetSearchResults,
  updateSearchTypeStatus,
} from '../../redux/ducks/searchDucks'
import { searchContentTypes } from '../../constants/search'
import urlQueryService from '../../services/urlQueryService'
import { defaultSearchQuery, simulateResize } from '../pages/Search/utils'
import { delayFunc } from '../../utils'

const GradientNewCampaignWithIcon = () => (
  <>
    <svg width={0} height={0} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset="1.15%" stop-color="#FFB904" />
          <stop offset="30.19%" stop-color="#FF6B00" />
          <stop offset="57.11%" stop-color="#FF403B" />
          <stop offset="100%" stop-color="#FF3B99" />
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#customGradient)" />
    </svg>

    <AddCircleRoundedIcon sx={{ fill: 'url(#linearColors)', fontSize: '30px' }} />
  </>
)

const staticStyles = {
  carouselArrow: {
    background: '#FAEBEB',
    boxShadow: `0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)`,
    '&:hover': {
      filter: 'brightness(0.95)',
      background: '#FAEBEB',
    },
    '&:before': {
      color: '#784E4E',
    },
  },
  campaignBox: {
    borderRadius: '18px',
    backgroundColor: 'rgba(255, 245, 245, 0.50)',
    width: '167px',
  },
  campaignTypography: {
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#6B4545',
    fontWeight: 700,
    p: 1,
    px: 2,
    backgroundColor: '#FFF',
    borderRadius: '18px 18px 0 0',
  },
}

export const YourLookalikeLists = ({ lookalikeListsData, currentQuery, tempBrandMentionsData }) => {
  const containerRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { labelStrings } = useTranslation()

  const isLoadingLookalikeList = useSelector(currentLookalikesListDataLoading)

  const [openModalLookalikeAi, setOpenModalLookalikeAi] = useState(false)
  const [dataFromLookalike, setDataFromLookalike] = useState({
    userList: [],
    platform: '',
  })

  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth - 1)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    delayFunc(() => simulateResize(), 500)
  }, [])

  const countSlideToShow = useMemo(
    () =>
      Math.floor((containerWidth > 0 ? containerWidth : containerRef.current?.offsetWidth) / 170) ||
      1,
    [containerWidth]
  )

  const showSkeletonSlides = useMemo(
    () => Array.from({ length: countSlideToShow }, (_, idx) => idx),
    [countSlideToShow]
  )

  const updatedArray = [
    { id: 'new', lookalikeName: labelStrings.buildLookalike?.btnText },
    ...lookalikeListsData,
  ]

  const openLookalike = data => {
    cancelAllFetches()
    // @ts-ignore
    dispatch([
      updateSearchTypeStatus(searchContentTypes.CREATOR),
      resetSearchResults(),
      changeSuggestionsData({}),
      changeAdditionalData(data),
      getSuggestedUsersById({ queryId: data?.id }),
    ])
    localStorage?.removeItem('searchQuery')
    urlQueryService.setNewSearchQuery({
      latestQuery: defaultSearchQuery,
    })
    history.replace(`/search?action=lookalike&lookalikeId=${data?.id}`)
  }

  const toggleIsCreateLookalikeOpen = () => {
    setOpenModalLookalikeAi(!openModalLookalikeAi)
  }

  const onHandleConfirmLookalike = ({ label }) => {
    dispatch(
      // @ts-ignore
      buildLookalike({
        ...dataFromLookalike,
        label,
        userList: [...new Set(dataFromLookalike.userList)],
        withoutFetchLookalikeList: true,
      })
    )
    toggleIsCreateLookalikeOpen()
  }

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          width: '100%',
          '& .react-multiple-carousel__arrow': staticStyles.carouselArrow,
          '& .react-multi-carousel-track': {
            pb: 1,
          },
          '& .react-multi-carousel-list': {
            overflow: 'visible',
          },
          pb: 3,
        }}
      >
        {isLoadingLookalikeList ? (
          <Skeleton
            sx={{ backgroundColor: '#EBD0D0', mb: 1 }}
            width="260px"
            height="20px"
            variant="text"
          />
        ) : (
          <Typography
            sx={{ fontFamily: 'Inter', fontSize: '20px', color: '#442424', fontWeight: 700, mb: 1 }}
          >
            {labelStrings?.buildLookalike?.yourLookalikeList}
          </Typography>
        )}
        {isLoadingLookalikeList ? (
          <Stack direction="row" justifyContent="space-between">
            {showSkeletonSlides?.map(idx => (
              <div style={{ width: 'fit-content' }} key={idx}>
                <RunningCampaignCardSkeleton />
              </div>
            ))}
          </Stack>
        ) : (
          <Carousel
            arrows={!isLoadingLookalikeList}
            renderButtonGroupOutside
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            showDots={false}
            slidesToSlide={countSlideToShow - 0.2}
            swipeable={!isLoadingLookalikeList}
            responsive={{
              desktop: {
                breakpoint: { max: 5000, min: 1024 },
                items: countSlideToShow - 0.2,
                partialVisibilityGutter: 40,
              },
              mobile: { breakpoint: { max: 464, min: 0 }, items: 1, partialVisibilityGutter: 30 },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 4,
                partialVisibilityGutter: 30,
              },
            }}
          >
            {updatedArray?.map(lookalike => {
              if (lookalike.id === 'new') {
                return (
                  <Box
                    onClick={toggleIsCreateLookalikeOpen}
                    sx={{
                      boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                      width: '167px',
                      minHeight: '160px',
                      backgroundColor: '#FFF',
                      borderRadius: '18px',
                      p: 2,

                      cursor: 'pointer',
                      '&:hover': {
                        filter: 'brightness(0.95)',
                      },
                    }}
                  >
                    <Stack spacing={1} alignItems="center">
                      <GradientNewCampaignWithIcon />
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '14px',
                          color: '#6B4545',
                          textAlign: 'center',
                        }}
                      >
                        {lookalike?.lookalikeName}
                      </Typography>
                    </Stack>
                  </Box>
                )
              }

              const updated = lookalike?.updated
                ? format(parseISO(lookalike?.updated), 'MMMM dd')
                : ''
              const isNotUpdatedLookalike =
                lookalike?.reference === 'lookalike' &&
                (lookalike?.updated === null || lookalike?.updated === 'null')

              return (
                <Paper
                  sx={{
                    width: 'fit-content',
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255, 245, 245, 0.50)',
                    boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                    borderRadius: '18px',
                    '&:hover': {
                      opacity: 0.7,
                    },
                  }}
                  key={lookalike?.id}
                  onClick={!isNotUpdatedLookalike ? () => openLookalike(lookalike) : undefined}
                >
                  <Box sx={staticStyles.campaignBox}>
                    <Typography noWrap sx={{ ...staticStyles.campaignTypography, height: '40px' }}>
                      {lookalike?.formattedString}
                    </Typography>
                    {lookalike?.insertedProfilePicsArray && lookalike.insertedProfilePicsArray.length > 0 && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 1 }}>
                        <Stack 
                          direction="row" 
                          sx={{ 
                            maxWidth: '140px',
                            overflow: 'hidden' 
                          }}
                        >
                          {lookalike.insertedProfilePicsArray
                            .slice(0, Math.min(5, lookalike.insertedProfilePicsArray.length))
                            .map((profile, idx) => (
                              <Tooltip key={idx} title={profile.username || 'Profile'}>
                                <Avatar
                                  src={profile.profilePicURL}
                                  alt={profile.username || 'Profile'}
                                  sx={{
                                    width: 26,
                                    height: 26,
                                    marginLeft: idx > 0 ? '-8px' : 0,
                                    border: '1px solid #fff',
                                    fontSize: '0.75rem',
                                    zIndex: 10 - idx,
                                    bgcolor: profile.profilePicURL ? undefined : '#FF5C00'
                                  }}
                                >
                                  {!profile.profilePicURL && profile.username ? profile.username.charAt(0).toUpperCase() : ''}
                                </Avatar>
                              </Tooltip>
                          ))}
                          {lookalike.insertedProfilePicsArray.length > 5 && (
                            <Tooltip title={`${lookalike.insertedProfilePicsArray.length - 5} more profiles`}>
                              <Avatar
                                sx={{
                                  width: 26,
                                  height: 26,
                                  marginLeft: '-8px',
                                  border: '1px solid #fff',
                                  fontSize: '0.75rem',
                                  bgcolor: '#FF5C00',
                                  color: '#FFF',
                                  zIndex: 5,
                                  fontWeight: 'bold'
                                }}
                              >
                                +{lookalike.insertedProfilePicsArray.length - 5}
                              </Avatar>
                            </Tooltip>
                          )}
                        </Stack>
                      </Box>
                    )}
                    <Divider sx={{ borderColor: '#FFF1F1' }} />
                    <Stack spacing={0} sx={{ p: 1, px: 2 }}>
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Typography
                          sx={{
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            color: '#6B4545',
                            lineHeight: '120%',
                            fontWeight: 700,
                          }}
                        >
                          {labelStrings?.status}:
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            color: '#6B4545',
                            lineHeight: '120%',
                          }}
                        >
                          {isNotUpdatedLookalike
                            ? labelStrings.buildLookalike.inProgress
                            : labelStrings.buildLookalike.finished}
                        </Typography>
                      </Stack>

                      {updated && (
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: '11px',
                              fontFamily: 'Inter',
                              color: '#6B4545',
                              lineHeight: '120%',
                              fontWeight: 700,
                            }}
                          >
                            Updated:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '11px',
                              fontFamily: 'Inter',
                              color: '#6B4545',
                              lineHeight: '120%',
                            }}
                          >
                            {updated}
                          </Typography>
                        </Stack>
                      )}

                      {!isNotUpdatedLookalike && (
                        <Button
                          sx={{
                            color: '#6B4545',
                            '&:hover': {
                              background: 'transparent',
                            },
                          }}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          variant="text"
                        >
                          Open
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </Paper>
              )
            })}
          </Carousel>
        )}
      </Box>
      {openModalLookalikeAi && (
        <LookalikeAiModal
          openModalLookalikeAi={openModalLookalikeAi}
          onCloseModalLookalikeAi={toggleIsCreateLookalikeOpen}
          onHandleConfirmLookalike={onHandleConfirmLookalike}
          setDataFromLookalike={setDataFromLookalike}
          currentQuery={currentQuery}
          tempBrandMentionsData={tempBrandMentionsData}
        />
      )}
    </>
  )
}
