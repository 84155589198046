import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FAV_COLLECTION_NAME } from '../../../../../constants/collections'
import { Box, Stack } from '@mui/material'
import ProfileEmailTool from './ProfileEmailTool'
import ProfileFavsTool from './ProfileFavsTool'
import ProfileBlackListTool from './ProfileBlackListTool'
import ProfileCampaignTool from './ProfileCampaignTool'
import ProfileCompareTool from './ProfileCompareTool'
import { manageProfileInCompareList } from '../../../../../redux/ducks/compareProfilesDucks'
import { collectionsSelector } from '../../../../../redux/selectors'

const ProfileToolsContainer = ({
  user,
  addElementToCollection,
  removeElementFromCollection,
  userGrant,
  canAddToFavs,
  // showCollectionsTool,
  checkIsProfileAddedToCompare,
  limitAccess,
  stopEventBubbling,
  isUserAuthenticated,
  isExplore,
  isQuickPreview,
  currentComparedPlatform,
  comparedProfilesCount,
  resetComparedProfiles,
  onBlockUnblockUser,
  setIsOpenPipelineModal,
  handleClose,
  // isAddToCollectionOpened,
  // isCreateCollectionOpened,
  // setIsAddToCollectionOpened,
  // setIsCreateCollectionOpened,
  source
}) => {
  const toolRef = useRef()
  const location = useLocation()
  const dispatch = useDispatch()

  const collectionsList = useSelector(collectionsSelector)
  const findFavCollectionId = collectionsList?.filter(collection =>
    collection.collectionId?.startsWith(FAV_COLLECTION_NAME)
  )[0]?.collectionId
  const favCollectionId = findFavCollectionId ? findFavCollectionId : 'fav'

  const isPublicUserAccount = !user.isPrivate
  const isBlackListPage = location?.pathname === '/collections/blacklist'
  const isCorrectPlatformForCompare =
    !currentComparedPlatform || (user._index ? currentComparedPlatform === user._index : false)
  const enableCompareProfiles = !userGrant.package_free
  const isAlreadyCompared = enableCompareProfiles && checkIsProfileAddedToCompare(user._id)
  const isForbiddenPlatform = enableCompareProfiles ? !isCorrectPlatformForCompare : false

  const addToFavs = () => addElementToCollection(user._id, favCollectionId, null, source)
  const removeFromFavs = () => removeElementFromCollection(user._id, favCollectionId, '', true)

  const onToolContainerClick = e => {
    e?.preventDefault()
    e?.stopPropagation()

    if (toolRef && toolRef.current) {
      if (toolRef.current.contains(e.target)) {
        stopEventBubbling(e)
      }
    }
  }

  const manageCompareProfile = (e, toAdd) => {
    e?.preventDefault()
    e?.stopPropagation()

    dispatch(
      manageProfileInCompareList({
        profileId: user._id,
        toAdd,
        platform: user._index,
      })
    )
  }

  const onOpenPipelineModal = (e, value) => {
    e?.preventDefault()
    e?.stopPropagation()

    setIsOpenPipelineModal(value)
    handleClose({ isPipelineModal: true })
  }

  const onBlackList = ({ e, profileId, isBlack }) => {
    e?.preventDefault()
    e?.stopPropagation()

    handleClose && handleClose({ isPipelineModal: true })
    onBlockUnblockUser && onBlockUnblockUser({ profileId, isBlack })
  }

  return (
    <Stack
      direction="row"
      spacing={0.2}
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100%',
        width: isQuickPreview ? '100%' : '120px',
        flexWrap: 'wrap',
      }}
      ref={toolRef}
      onClick={onToolContainerClick}
      onMouseDown={onToolContainerClick}
    >
      {!isBlackListPage && (
        <Box>
          <ProfileFavsTool
            isFav={user.isFav}
            userindex={user._index}
            removeFromFavs={removeFromFavs}
            addToFavs={addToFavs}
            enableFavorites={userGrant.enable_collections}
            canAddToFavs={canAddToFavs}
            isUserAuthenticated={isUserAuthenticated}
            isQuickPreview={isQuickPreview}
          />
        </Box>
      )}

      {!isBlackListPage && !isExplore && (
        <Box>
          <ProfileCompareTool
            userindex={user._index}
            enableCompareProfiles={enableCompareProfiles}
            isUserAuthenticated={isUserAuthenticated}
            isAlreadyCompared={isAlreadyCompared}
            manageCompareProfile={manageCompareProfile}
            isForbiddenPlatform={isForbiddenPlatform}
            isInCollections={false}
            comparedProfilesCount={comparedProfilesCount}
            resetComparedProfiles={resetComparedProfiles}
            isQuickPreview={isQuickPreview}
          />
        </Box>
      )}

      {!isBlackListPage && (
        <Box>
          <ProfileBlackListTool
            userId={user?._id}
            isBlack={user.isBlack}
            userindex={user._index}
            onBlockUnblockUser={onBlackList}
            isUserAuthenticated={isUserAuthenticated}
            enableBlackList={!userGrant.enable_collections}
            isQuickPreview={isQuickPreview}
          />
        </Box>
      )}

      {isPublicUserAccount && (
        <>
          {/* {((showCollectionsTool && !isBlackListPage) || isExplore) && (
            <Box>
              <ProfileCollectionsTool
                user={user}
                enableCollections={userGrant.enable_collections}
                isUserAuthenticated={isUserAuthenticated}
                isQuickPreview={isQuickPreview}
                isAddToCollectionOpened={isAddToCollectionOpened}
                isCreateCollectionOpened={isCreateCollectionOpened}
                setIsAddToCollectionOpened={setIsAddToCollectionOpened}
                setIsCreateCollectionOpened={setIsCreateCollectionOpened}
              />
            </Box>
          )} */}

          {!isBlackListPage && isQuickPreview && (
            <>
              <Box>
                <ProfileEmailTool
                  userHasEmail={user.hasMail}
                  userId={user._id}
                  userindex={user._index}
                  limitAccess={limitAccess}
                  isQuickPreview={isQuickPreview}
                />
              </Box>
              <Box>
                <ProfileCampaignTool
                  user={user}
                  enableCampaigns={!!userGrant.enable_tracking}
                  isUserAuthenticated={isUserAuthenticated}
                  setIsOpenPipelineModal={onOpenPipelineModal}
                  isQuickPreview={isQuickPreview}
                />
              </Box>
            </>
          )}
        </>
      )}
    </Stack>
  )
}

export default ProfileToolsContainer
