import React from 'react'
import { Box, Card, CardContent, CardHeader } from '@mui/material'
import { UsersList } from '.././UsersList'

export const TableViewGraphExport = ({ data, subheader, currency, duplicateFilteredStatus }) => {
  const { engagement, overview, cpm, cpe, emv } = data || {}

  return (
    <Card>
      <CardHeader title={'Campaign Metrics'} subheader={subheader} />

      <CardContent
        sx={{
          p: 2,
          pb: `16px !important`,
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Box>
          <UsersList
            overviewRowData={overview}
            engagementRowData={engagement}
            cpmRowData={cpm}
            cpeRowData={cpe}
            emvRowData={emv}
            currency={currency}
            duplicateFilteredStatus={duplicateFilteredStatus}
            isForPdf
          />
        </Box>
      </CardContent>
    </Card>
  )
}
