import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box,  IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel'
import { CreatorsLikeBasedSkeleton } from './CreatorsLikeBasedSkeleton'
import { CreatorLikesBasedItem } from './CreatorLikesBasedItem'
import useTranslation from '../../localization/useTranslation'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import {
  changeSearchDashboardData,
  removeSuggestedUserFromList,
} from '../../redux/ducks/exploreDucks'
import { useDispatch } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { simulateResize } from '../pages/Search/utils'
import { delayFunc } from '../../utils'

const staticStyles = {
  carouselArrow: {
    background: '#FAEBEB',
    boxShadow: `0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)`,
    '&:hover': {
      filter: 'brightness(0.95)',
      background: '#FAEBEB',
    },
    '&:before': {
      color: '#784E4E',
    },
  },
  campaignBox: {
    borderRadius: '18px',
    backgroundColor: 'rgba(255, 245, 245, 0.50)',
    boxShadow: '0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)',
    width: '167px',
  },
}

export const CreatorsLikeBased = props => {
  const {
    suggestionDashboardData,
    isLoadingSuggestion,
    organizationCurrency,
    isUserAuthenticated,
    canAddToFavs,
    enable_collections,
    collectionsList,
    onLoadMoreCreatorsYouMightLike,
    onRenewCreatorsList,
  } = props || {}
  const dispatch = useDispatch()

  const { userdata, reference, label, type, hasMore, id: listId } = suggestionDashboardData || {}
  const { labelStrings } = useTranslation()

  const containerRef = useRef(null)

  const [containerWidth, setContainerWidth] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  const [currPage, setCurrPage] = useState(1)

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth - 1)
      }
    }

    // Initial calculation (run immediately)
    handleResize()

    // Run again after a short delay to ensure DOM is fully rendered
    const timer = setTimeout(() => {
      handleResize()
    }, 100)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    delayFunc(() => simulateResize(), 500)
  }, [])

  const countSlideToShow = useMemo(() => {
    // Make sure to handle the case when width might still be 0 or undefined
    const width =
      containerWidth > 0
        ? containerWidth
        : containerRef.current?.offsetWidth || window.innerWidth * 0.7
    // Add a small buffer to avoid edge cases where items might overlap
    return Math.max(2, Math.floor((width - 30) / 180))
  }, [containerWidth])

  const showSkeletonSlides = useMemo(
    () => Array.from({ length: countSlideToShow }, (_, idx) => idx),
    [countSlideToShow]
  )

  const onLoadMore = () => {
    if (!hasMore) return

    setIsLoading(true)
    const updatedPage = currPage + 1
    setCurrPage(updatedPage)
    onLoadMoreCreatorsYouMightLike(updatedPage, setIsLoading)
  }

  const removeCreator = (e, creatorId) => {
    e.preventDefault()
    e.stopPropagation()
    // @ts-ignore
    dispatch(removeSuggestedUserFromList({ userId: creatorId, listId }))
  }

  const onReloadList = () => {
    setCurrPage(1)
    onRenewCreatorsList(1, listId)
  }

  const updateCreators = updatedCreators => {
    dispatch(
      changeSearchDashboardData({
        ...suggestionDashboardData,
        userdata: updatedCreators,
      })
    )
  }

  if (!userdata?.length && !isLoadingSuggestion) {
    return (
      <Stack sx={{ width: '100%', height: '100%', py: 2 }} spacing={1}>
        <Typography
          sx={{ fontFamily: 'Inter', fontSize: '20px', color: '#442424', fontWeight: 700, mb: 1 }}
        >
          {`${labelStrings.creatorsYouMightLike} ${label ? `'${label}'` : ''}`}
        </Typography>
        <Typography
          textAlign="start"
          sx={{ color: '#6B4545', fontFamily: 'Inter', fontSize: '15px', py: 2 }}
        >
          {labelStrings?.searchDashboard?.sorryNoSuggestionsCurrently}
        </Typography>
      </Stack>
    )
  }

  const updatedUserdata = [
    ...(userdata && userdata?.length ? userdata : []),
    {
      _id: 'loadMore',
    },
  ]

  return (
    <Box
      ref={containerRef}
      sx={{
        mb: 6,
        mt: 3,
        width: '100%',
        zIndex: 100,
        '& .react-multiple-carousel__arrow': staticStyles.carouselArrow,
        '& .react-multi-carousel-track': {
          pb: 1,
        },
        '& .react-multi-carousel-list': {
          overflow: 'visible',
        },
      }}
    >
      {isLoadingSuggestion ? (
        <Skeleton
          sx={{ backgroundColor: '#EBD0D0', mb: 1 }}
          width="520px"
          height="20px"
          variant="text"
        />
      ) : (
        <>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '20px',
                color: '#442424',
                fontWeight: 700,
                mb: 1,
              }}
            >
              {`${labelStrings.creatorsYouMightLike} ${
                reference === 'collection' ? 'Collection: ' : ''
              }'${label === 'unlocked' ? 'Audience Reports' : label}'`}
            </Typography>
            <IconButton size="small" onClick={onReloadList} sx={{ color: '#6B4545' }}>
              <AutorenewRoundedIcon />
            </IconButton>
          </Stack>

          <Typography
            sx={{ fontFamily: 'Inter', fontSize: '14px', color: '#442424', fontWeight: 400, mb: 1 }}
          >
            {`${
              reference === 'collection' && type === 'new'
                ? labelStrings.newDashboard
                : reference === 'collection' && type === 'similar'
                ? labelStrings.similarDashboard
                : labelStrings.generalDashboard
            }`}
          </Typography>
        </>
      )}

      {isLoadingSuggestion ? (
        <Stack direction="row" justifyContent="space-between">
          {showSkeletonSlides?.map(idx => (
            <div style={{ width: 'fit-content' }} key={idx}>
              <CreatorsLikeBasedSkeleton />
            </div>
          ))}
        </Stack>
      ) : (
        <Carousel
          arrows={!isLoadingSuggestion}
          renderButtonGroupOutside
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          showDots={false}
          slidesToSlide={countSlideToShow - 0.2}
          swipeable={!isLoadingSuggestion}
          responsive={{
            desktop: {
              breakpoint: { max: 5000, min: 1024 },
              items: countSlideToShow - 0.2,
              partialVisibilityGutter: 40,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: Math.min(4, countSlideToShow),
              partialVisibilityGutter: 20,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              partialVisibilityGutter: 20,
            },
          }}
        >
          {updatedUserdata?.map(creator => {
            if (creator?._id === 'loadMore' && !hasMore) return

            if (creator?._id === 'loadMore' && hasMore) {
              return (
                <Paper
                  key={creator?._id}
                  onClick={isLoading ? undefined : onLoadMore}
                  sx={{
                    boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                    height: '232px',
                    p: 1.5,
                    borderRadius: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: isLoading ? 'wait' : 'pointer',
                    opacity: isLoading ? 0.8 : 1,
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                >
                  <LoadingButton
                    size="small"
                    loading={isLoading}
                    loadingIndicator={labelStrings?.loading}
                    sx={{
                      fontFamily: 'Inter',
                      color: '#6B4545',
                      fontWeight: 700,
                      fontSize: '14px',
                      '&:hover': {
                        background: 'transparent',
                      },
                    }}
                  >
                    {labelStrings?.searchDashboard?.loadMore}
                  </LoadingButton>
                </Paper>
              )
            }

            return (
              <CreatorLikesBasedItem
                creator={creator}
                canAddToFavs={canAddToFavs}
                collectionsList={collectionsList}
                enable_collections={enable_collections}
                isUserAuthenticated={isUserAuthenticated}
                organizationCurrency={organizationCurrency}
                removeCreator={removeCreator}
                userdata={userdata}
                updateCreators={updateCreators}
              />
            )
          })}
        </Carousel>
      )}
    </Box>
  )
}
