import React, { useRef } from 'react'
import PropTypes from 'prop-types'
// import { FAV_COLLECTION_NAME } from '../../../constants/collections'

import ProfileEmailTool from './ProfileEmailTool'
import ProfileCollectionsTool from './ProfileCollectionsTool'
import ProfileFavsTool from './ProfileFavsTool'
import ProfileCampaignTool from './ProfileCampaignTool'
import ProfileCompareTool from './ProfileCompareTool'
import { RoundedToolsWrapper, ToolContainer } from '../../common/styledWrappers'
import ProfileBlackListTool from './ProfileBlackListTool'
import { useLocation } from 'react-router-dom'
// import { collectionsSelector } from '../../../redux/selectors'
// import { useSelector } from 'react-redux'

const ProfileToolsContainer = ({
  user,
  addElementToCollection,
  // createCollection,
  removeElementFromCollection,
  userGrant,
  biggerIcons = true,
  // fullScreenModals,
  canAddToFavs,
  showCollectionsTool,
  showCampaignsTool,
  // deleteElementFromCampaign,
  // addElementToCampaign,
  // createCampaign,
  limitAccess,
  stopEventBubbling,
  isSelectedProfileView,
  isCollectionView,
  isUserAuthenticated,
  checkIsProfileAddedToCompare,
  currentComparedPlaform = '',
  manageProfileInCompareList,
  resetComparedProfiles,
  comparedProfilesCount = 0,
  onBlockUnblockUser,
  isBlackListCollection,
  setIsOpenPipelineModal,
  isExplore,
  isComparePage,
}) => {
  const toolRef = useRef()
  const location = useLocation()

  // const collectionsList = useSelector(collectionsSelector)
  // const findFavCollectionId = collectionsList?.filter(collection => collection.collectionId?.startsWith(FAV_COLLECTION_NAME))[0]?.collectionId
  const favCollectionId = 'fav'

  const isPublicUserAccount = !user.isPrivate
  const enableCompareProfiles = !userGrant.package_free
  const isCorrectPlatformForCompare =
    !currentComparedPlaform || (user._index ? currentComparedPlaform === user._index : false)

  const isAlreadyCompared = enableCompareProfiles && checkIsProfileAddedToCompare(user._id)
  const isForbiddenPlaform = enableCompareProfiles ? !isCorrectPlatformForCompare : false

  const isBlackListPage = location?.pathname === '/collections/blacklist'
  const isSearchPage = location?.pathname === '/search'

  const addToFavs = () => addElementToCollection(user._id, favCollectionId)
  const removeFromFavs = () => removeElementFromCollection(user._id, favCollectionId)
  const manageCompareProfile = toAdd =>
    manageProfileInCompareList({
      profileId: user._id,
      toAdd,
      platform: user._index,
    })

  const onToolContainerClick = e => {
    if (toolRef && toolRef.current) {
      if (toolRef.current.contains(e.target)) {
        stopEventBubbling(e)
      }
    }
  }

  return (
    <ToolContainer
      ref={toolRef}
      biggerIcons={biggerIcons}
      onClick={onToolContainerClick}
      onMouseDown={onToolContainerClick}
      isSelectedProfileView={isSelectedProfileView}
      isBlackListCollection={isBlackListCollection}
      isSearchPage={isSearchPage}
      isComparePage={isComparePage}
    >
      {!isBlackListPage && !isExplore && (
        <RoundedToolsWrapper>
          <ProfileEmailTool
            userHasEmail={user.hasMail}
            userId={user._id}
            userindex={user._index}
            limitAccess={limitAccess}
          />
        </RoundedToolsWrapper>
      )}
      {isPublicUserAccount && (
        <>
          {((showCollectionsTool && !isBlackListPage) || isExplore || isComparePage) && (
            <RoundedToolsWrapper>
              <ProfileCollectionsTool
                user={user}
                enableCollections={userGrant.enable_collections}
                isUserAuthenticated={isUserAuthenticated}
              />
            </RoundedToolsWrapper>
          )}

          {!isBlackListPage && (
            <RoundedToolsWrapper>
              <ProfileFavsTool
                isFav={user.isFav}
                userindex={user._index}
                removeFromFavs={removeFromFavs}
                addToFavs={addToFavs}
                enableFavorites={userGrant.enable_collections}
                canAddToFavs={canAddToFavs}
                isUserAuthenticated={isUserAuthenticated}
              />
            </RoundedToolsWrapper>
          )}

          {!isBlackListPage && (
            <RoundedToolsWrapper>
              <ProfileCampaignTool
                user={user}
                enableCampaigns={!!userGrant.enable_tracking}
                isUserAuthenticated={isUserAuthenticated}
                setIsOpenPipelineModal={setIsOpenPipelineModal}
              />
            </RoundedToolsWrapper>
          )}

          {!isBlackListPage && !isComparePage && (
            <RoundedToolsWrapper>
              <ProfileCompareTool
                userindex={user._index}
                enableCompareProfiles={enableCompareProfiles}
                isUserAuthenticated={isUserAuthenticated}
                isAlreadyCompared={isAlreadyCompared}
                manageCompareProfile={manageCompareProfile}
                isForbiddenPlatform={isForbiddenPlaform}
                isInCollections={isCollectionView}
                comparedProfilesCount={comparedProfilesCount}
                resetComparedProfiles={resetComparedProfiles}
              />
            </RoundedToolsWrapper>
          )}

          {(isSearchPage || isBlackListPage) && (
            <RoundedToolsWrapper>
              <ProfileBlackListTool
                userId={user?._id}
                isBlack={user.isBlack}
                userindex={user._index}
                onBlockUnblockUser={onBlockUnblockUser}
                isUserAuthenticated={isUserAuthenticated}
                enableBlackList={!userGrant.enable_collections}
              />
            </RoundedToolsWrapper>
          )}
        </>
      )}
    </ToolContainer>
  )
}

ProfileToolsContainer.propTypes = {
  user: PropTypes.object.isRequired,
  addElementToCollection: PropTypes.func,
  createCollection: PropTypes.func,
  removeElementFromCollection: PropTypes.func,
  userGrant: PropTypes.object.isRequired,
  biggerIcons: PropTypes.bool,
  fullScreenModals: PropTypes.bool,
  canAddToFavs: PropTypes.bool,
  showCollectionsTool: PropTypes.bool,
  showCampaignsTool: PropTypes.bool,
  deleteElementFromCampaign: PropTypes.func,
  addElementToCampaign: PropTypes.func,
  createCampaign: PropTypes.func,
  limitAccess: PropTypes.func,
  stopEventBubbling: PropTypes.func,
  isSelectedProfileView: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool,
  checkIsProfileAddedToCompare: PropTypes.func,
  manageProfileInCompareList: PropTypes.func,
  currentComparedPlaform: PropTypes.string,
  isCollectionView: PropTypes.bool,
  resetComparedProfiles: PropTypes.func,
  comparedProfilesCount: PropTypes.number,
  onBlockUnblockUser: PropTypes.func,
  isBlackListCollection: PropTypes.bool,
  setIsOpenPipelineModal: PropTypes.func,
  isExplore: PropTypes.bool,
  isComparePage: PropTypes.bool,
}

export default ProfileToolsContainer
