import { SOCIAL_PLATFORMS_NAMES } from "../../../constants/appSettings"
import { SEARCH_SLIDERS_RANGE } from "../../../constants/search"

export const transformObjectToArray = (obj) => {
  const result = []

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]

      if (typeof value === "string") {
        const values = value.split(",")
        values.forEach((val) => {
          const newObj = {}
          newObj[key] = val.trim()
          result.push(newObj)
        })
      } else {
        const newObj = {}
        newObj[key] = value
        result.push(newObj)
      }
    }
  }

  return result
}

export const splitMultipleValues = data => {
  return data?.flatMap(item => {
    if(item.placesId || item.location) return
    return item.value?.split(', ')?.map(val => {
      if (val) {
        return {
          ...item,
          value: `${val}`
        };
      }
      return undefined
    })
  }).filter(Boolean) 
}

export const getValidValues = (obj) => {
  const filteredObj = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      
      const isMinOrMax = key.endsWith("Min") || key.endsWith("Max")
      const isExcludedValue = value === 2000 || value === Infinity

      if (
        key !== "skipCount" &&
        key !== "sorting" &&
        key !== "additionalSkipsForBlacklisting" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value !== Infinity &&
        value !== -Infinity &&
        value !== false &&
        value !== "false" &&
        value !== true &&
        value !== "true" &&
        value !== 0 &&
        !(isMinOrMax && isExcludedValue)
      ) {
        filteredObj[key] = value
      }
    }
  }
  return filteredObj
}

export const defaultAmountQueryValues = {
  followerMin: SEARCH_SLIDERS_RANGE.min,
  followerMax: SEARCH_SLIDERS_RANGE.max,
  viewsMin: SEARCH_SLIDERS_RANGE.min,
  viewsMax: SEARCH_SLIDERS_RANGE.max,
  playsMin: SEARCH_SLIDERS_RANGE.min,
  playsMax: SEARCH_SLIDERS_RANGE.max,
  reelPlaysMin: SEARCH_SLIDERS_RANGE.min,
  reelPlaysMax: SEARCH_SLIDERS_RANGE.max,
  shortsPlaysMin: SEARCH_SLIDERS_RANGE.min,
  shortsPlaysMax: SEARCH_SLIDERS_RANGE.max,
}

export const defaultSearchQuery = {
  city: "",
  country: null,
  language: null,
  followerMin: SEARCH_SLIDERS_RANGE.min,
  followerMax: SEARCH_SLIDERS_RANGE.max,
  gender: null,
  skipCount: 0,
  additionalSkipsForBlacklisting: 0,
  keywords: "",
  brandMentions: "",
  categories: "",
  matchAnyKeyword: false,
  mustIncludeEmail: false,
  appearsOn: "",
  index: SOCIAL_PLATFORMS_NAMES.instagram.index,
  sorting: "",
  growthRate: null,
  engagementRate: null,
  audienceCountries: "",
  audienceGender: "",
  audienceLanguages: "",
  audienceRealPeople: null,
  audienceAgeRanges: "",
  isShortsCreator: "",
  contentTypes: "",
  uploadedFrom: "",
  uploadedTo: "",
  placesId: "",
  radiusKM: "",
  hideCreatorsInCollection: false,
  isSimilarContentPieces: false,
  businessSearch: false,
  fromContentSearch: false,
  promptSuggestion: ''
}

export const searchParamsName = {
  ["index"]: "Platform",
  ["followerMin"]: "Followers",
  ["followerMax"]: "Followers",
}

export const transformListToDict = (lst) => {
  const result = {}
  lst.forEach((item) => {
    result[item.key] = item.value
  })
  return result
}

export const findItemsByKeyValue = (obj, key, value) => {
  const result = []
  for (const [objKey, objValue] of Object.entries(obj)) {
    if (objKey === key && objValue === value) {
      result.push({ [objKey]: objValue })
    }
  }
  return !!result?.length
}

export const capitalizeFirstLetter = (string) => {
  if(!string?.length) return
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}

export const simulateResize = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  window.innerWidth = width;
  window.innerHeight = height;
  window.dispatchEvent(new Event('resize'));
};