import React from 'react'
import { Box, CardHeader } from '@mui/material'
import Chart, { useChart } from '../../index'
import { pretifyBigNum } from '../../../../../utils'

export const BarChartExport = ({
  title,
  subheader,
  chart,
  height = '464px',
  ...other
}) => {
  const { colors, series, options } = chart
  const chartSeries = series.map(i => i.value)

  const chartOptions = useChart({
    colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: value => pretifyBigNum(value),
        title: {
          formatter: () => '',
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: series.map(i => i.label),
      labels: {
        show: !chartSeries?.length ? false : true,
      },
      ...options?.xaxis,
    },
    yaxis: {
      labels: {
        show: !chartSeries?.length ? false : true,
      },
      ...options?.yaxis,
    },
    ...options,
  })

  return (
    <Box {...other} sx={{ height }}>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ mx: 3 }} dir="ltr">
        <Chart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
      </Box>
    </Box>
  )
}
