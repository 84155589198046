import React from 'react'
import { Box, Typography } from '@mui/material'
import { RHFTextField, RHFRadioGroup } from '../../../components/hook-form'
import useTranslation from '../../../../localization/useTranslation'

export const PricingFields = ({ watch, currentCurrency }) => {
  const { labelStrings } = useTranslation()

  const pricingTrack = watch('pricingTrack')

  const disabledTrack = value => {
    return pricingTrack === value
  }

  return (
    <Box sx={{ my: 2, marginTop: 4 }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          marginBottom: 1,
        }}
      >
        {labelStrings.pricing}
      </Typography>
      <Typography sx={{ fontSize: '14px', lineHeight: '22px', marginBottom: 1 }}>
        {labelStrings.creatorOverview.pricingSubtitle}
      </Typography>

      <Box>
        <RHFRadioGroup
          name="pricingTrack"
          label=""
          sx={{ marginLeft: 1 }}
          options={[
            {
              value: 'trackEntireCollaboration',
              label: labelStrings?.creatorOverview.trackEntireCollaboration,
            },
            {
              value: 'trackEachContentPiece',
              label: labelStrings?.creatorOverview.trackEachContentPiece,
            },
          ]}
        />
      </Box>

      <Box sx={{ width: '44.1%', display: 'flex', alignItems: 'center', my: 3 }}>
        <RHFTextField
          name="pricing.collaborationPrice"
          label={labelStrings?.creatorOverview.priceForEntireCollaboration}
          disabled={disabledTrack('trackEachContentPiece')}
          type="number"
        />{' '}
        <Typography sx={{ marginLeft: 1 }}>{currentCurrency}</Typography>
      </Box>

      <Typography sx={{ fontSize: '14px', lineHeight: '22px', marginBottom: 2 }}>
        {labelStrings.creatorOverview.pricingRecommended}
      </Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '90%' }}>
          <RHFTextField
            name="pricing.priceForReel"
            label={labelStrings.creatorOverview.pricingForReel}
            disabled={disabledTrack('trackEntireCollaboration')}
            type="number"
          />{' '}
          <Typography sx={{ marginLeft: 1 }}>{currentCurrency}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '90%' }}>
          <RHFTextField
            name="pricing.priceForStory"
            label={labelStrings.creatorOverview.pricingForStory}
            disabled={disabledTrack('trackEntireCollaboration')}
            type="number"
          />{' '}
          <Typography sx={{ marginLeft: 1 }}>{currentCurrency}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '90%' }}>
          <RHFTextField
            name="pricing.priceForPost"
            label={labelStrings.creatorOverview.pricingForPost}
            disabled={disabledTrack('trackEntireCollaboration')}
            type="number"
          />{' '}
          <Typography sx={{ marginLeft: 1 }}>{currentCurrency}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '90%' }}>
          <RHFTextField
            name="pricing.priceForTikTok"
            label={labelStrings.creatorOverview.pricingForTikTok}
            disabled={disabledTrack('trackEntireCollaboration')}
            type="number"
          />{' '}
          <Typography sx={{ marginLeft: 1 }}>{currentCurrency}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', width: '90%' }}>
          <RHFTextField
            name="pricing.priceForYTVideo"
            label={labelStrings.creatorOverview.pricingForYtVideo}
            disabled={disabledTrack('trackEntireCollaboration')}
            type="number"
          />{' '}
          <Typography sx={{ marginLeft: 1 }}>{currentCurrency}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', width: '90%' }}>
          <RHFTextField
            name="pricing.priceForYTShort"
            label={labelStrings.creatorOverview.pricingForYtShorts}
            disabled={disabledTrack('trackEntireCollaboration')}
            type="number"
          />{' '}
          <Typography sx={{ marginLeft: 1 }}>{currentCurrency}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '90%' }}>
          <RHFTextField
            name="pricing.additionalCosts"
            label={labelStrings.creatorOverview.additionalPayments}
            disabled={disabledTrack('trackEntireCollaboration')}
            type="number"
          />{' '}
          <Typography sx={{ marginLeft: 1 }}>{currentCurrency}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
