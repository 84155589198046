import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../../localization/useTranslation'
import { pretifyBigNum } from '../../../../utils'

import {
  StyledSmartMediaValueItem,
  SmartMediaValueItemTitleWrapper,
  SmartMediaValueItemTitleBorder,
  SmartMediaValueItemTitle,
  PerStoryWrapper,
  PerStoryRange,
  PerStoryLabel,
  PerViewsWrapper,
  PerViewsRange,
  PerViewsLabel,
} from './styles'

const exchangeRates = {
  'eur': 1, 
  'usd': 1.048, 
  'gbp': 0.832
}

const SmartMediaValueItem = ({ perMediaLabel = '', cpm, isForPdf = '', currency = '', platform = '' }) => {
  const { labelStrings } = useTranslation()
  const currencyTitle = useMemo(() => labelStrings.inCurrency(currency), [currency])

  const currencyRate = useMemo(() => exchangeRates[currency] || 1, [currency]);

  const cpmPerContentFrom = useMemo(() => Math.round(cpm.perContent.from * currencyRate), [currencyRate, cpm]);
  const cpmPerContentTo = useMemo(() => Math.round(cpm.perContent.to * currencyRate), [currencyRate, cpm]);
  const cpmPerMillFrom = useMemo(() => Math.round(cpm.perMill.from * currencyRate), [currencyRate, cpm]);
  const cpmPerMillTo = useMemo(() => Math.round(cpm.perMill.to * currencyRate), [currencyRate, cpm]);
  
  return (
    <StyledSmartMediaValueItem>
      <SmartMediaValueItemTitleWrapper isForPdf={isForPdf}>
        {!isForPdf && <SmartMediaValueItemTitleBorder />}
        <SmartMediaValueItemTitle>{perMediaLabel}</SmartMediaValueItemTitle>
      </SmartMediaValueItemTitleWrapper>

      <PerStoryWrapper isForPdf={isForPdf}>
        <PerStoryRange isForPdf={isForPdf} platform={platform}>
          {`${pretifyBigNum(cpmPerContentFrom)} - ${pretifyBigNum(cpmPerContentTo)}`}
        </PerStoryRange>
        <PerStoryLabel isForPdf={isForPdf} platform={platform}>{`${perMediaLabel} ${currencyTitle}`}</PerStoryLabel>
      </PerStoryWrapper>

      <PerViewsWrapper isForPdf={isForPdf}>
        <PerViewsRange isForPdf={isForPdf}>{`${cpmPerMillFrom} - ${cpmPerMillTo}`}</PerViewsRange>
        <PerViewsLabel>{`${labelStrings.per1000Views} ${currencyTitle}`}</PerViewsLabel>
      </PerViewsWrapper>
    </StyledSmartMediaValueItem>
  )
}

SmartMediaValueItem.propTypes = {
  perMediaLabel: PropTypes.string,
  cpm: PropTypes.shape({
    perContent: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    perMill: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
  }).isRequired,
  isForPdf: PropTypes.bool,
  currency: PropTypes.string,
  platform: PropTypes.string
}

export default SmartMediaValueItem
