import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { PlayVideoIcon } from '../../../assets/icons/navbar'

export const PlayingVideo = props => {
  const {
    multipleVideo = [],
    singleVideo = '',
    onHandlePlayVideo,
    singleVideoPreview,
    multipleVideoPreview,
    contentImg,
    startVideo,
    setStartVideo,
    videoIndexFromCarousel,
    setCurrSlide,
    handleReady,
    handleError
  } = props

  const [playIndex, setPlayIndex] = useState(0)

  const handleNextVideo = () => {
    setPlayIndex(playIndex + 1)
  }

  useEffect(() => {
    if (playIndex === multipleVideo?.length && !videoIndexFromCarousel && setCurrSlide) {
      setStartVideo(false)
      !videoIndexFromCarousel && setPlayIndex(0)
      setCurrSlide(0)
    }

    if (playIndex === multipleVideo?.length && videoIndexFromCarousel && setCurrSlide) {
      setStartVideo(false)
      setPlayIndex(videoIndexFromCarousel)
      setCurrSlide(videoIndexFromCarousel)
    }

    if (setCurrSlide && playIndex > 0 && playIndex !== multipleVideo?.length) {
      setCurrSlide(playIndex)
    }
  }, [playIndex])

  useEffect(() => {
    if (videoIndexFromCarousel) {
      setPlayIndex(videoIndexFromCarousel)
    }
  }, [videoIndexFromCarousel])

  return (
    <ReactPlayer
      playing={startVideo}
      url={multipleVideo?.length ? multipleVideo[playIndex] : singleVideo}
      width="100%"
      height="100%"
      className="react-player campaign-video-preview"
      controls
      onEnded={handleNextVideo}
      onReady={handleReady}
      onError={handleError}
      light={
        singleVideoPreview
          ? singleVideoPreview
          : multipleVideoPreview?.length
          ? multipleVideoPreview[playIndex]
          : contentImg
      }
      playIcon={
        <div onClick={onHandlePlayVideo}>
          <PlayVideoIcon />
        </div>
      }
    />
  )
}
