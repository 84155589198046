import React from 'react'
import { alpha, useTheme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { bgGradient } from '../../../utils/cssStyles'
import { fPercent, fShortenNumber } from '../../../utils/formatNumber'
import { Icon } from '@iconify/react'

export const BigCardWidgetExport = ({
	title,
	subtitle,
	total,
	totalPercentage,
	subTotal,
	icon,
	color = 'success',
	metricName,
	sx = {},
	compareDifference,
	compareRate,
	...other
}) => {
	const theme = useTheme()

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				py: 2,
				boxShadow: 0,
				textAlign: 'center',
				color: color === 'success' ? 'black' : theme.palette[color].darker,
				bgcolor: '#fff',//theme.palette[color].lighter,
				...sx,
				minHeight: '232px',
				position: 'relative',
			}}
			{...other}
		>
			<Box
				sx={{
					mb: 1,
					p: 2,
					width: 64,
					height: 64,
					borderRadius: '50%',
					color: color === 'success' ? 'black' : theme.palette[color].dark,
					...bgGradient({
						direction: '135deg',
						startColor: `${alpha(theme.palette[color].dark, 0)} 0%`,
						endColor: `${alpha(theme.palette[color].dark, 0.24)} 100%`,
					}),
					margin: '0 auto',
				}}
			>
				{<Icon icon={icon} fontSize={26} />}
			</Box>

			{totalPercentage ? (
				<Typography variant="h4" sx={{ mt: 1, lineHeight: subTotal ? '24px' : 'inherit' }}>
					{totalPercentage ? fPercent(totalPercentage) : fShortenNumber(total)}
				</Typography>
			) : (
				<Typography variant="h4" sx={{ mt: 1, lineHeight: subTotal ? '24px' : 'inherit' }}>
					{fShortenNumber(total)}
				</Typography>
			)}

			{subTotal && (
				<Typography variant="h6">
					{typeof subTotal === 'string' ? subTotal : fShortenNumber(subTotal)}
				</Typography>
			)}

			{metricName && (
				<Typography variant="h6" sx={{ lineHeight: '20px' }}>
					{metricName}
				</Typography>
			)}

			<Typography variant="subtitle2" sx={{ opacity: 0.64, px: 1, lineHeight: '20px' }}>
				{title}
			</Typography>

			{subtitle && (
				<Typography
					variant="subtitle2"
					sx={{ opacity: 0.8, px: 1, lineHeight: '16px', fontSize: '14px' }}
				>
					{subtitle}
				</Typography>
			)}

			<div style={{ flexGrow: 1 }} />

			{compareRate && compareDifference && (
				<Typography
					variant="subtitle2"
					sx={{
						opacity: 0.8,
						px: 1,
						lineHeight: '16px',
						fontSize: '12px',
						color: compareRate < 0 || compareDifference < 0 ? 'red' : 'inherit',
						mt: 2,
					}}
				>
					{`${compareRate >= 0 ? `+${Math.floor(compareRate * 100)}` : Math.floor(compareRate * 100)}% (${
						compareDifference >= 0 ? `+${fShortenNumber(compareDifference)}` : fShortenNumber(compareDifference)
					})`}
				</Typography>
			)}
		</Box>
	)
}
