import React from 'react'
import { Box, Stack, Typography, alpha, useTheme } from '@mui/material'
import { fPercent } from '../../../utils/formatNumber'
import { pretifyBigNum } from '../../../../utils'
import Chart, { useChart } from '../../chart'
import Iconify from '../../iconify'
import useTranslation from '../../../../localization/useTranslation'

export const ChartCardWidgetExport = ({
  title,
  subtitle,
  helperText,
  percent,
  total,
  chart,
  sx,
  ...other
}) => {
  const theme = useTheme()

  const { labelStrings } = useTranslation()

  const {
    colors = [theme.palette.primary.light, theme.palette.primary.main],
    series,
    options,
  } = chart

  const chartOptions = useChart({
    colors: percent >= 0 ? ['#36B37E', '#36B37E'] : colors,
    chart: {
      animations: {
        enabled: true,
      },
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      ...options.tooltip,
      x: {
        show: true,
      },
      y: {
        labels: {
          formatter: value => {
            return value
          },
        },
        title: {
          formatter: () => '',
        },
      },
      marker: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    ...options,
  })

  const renderTrending = (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Box sx={{ pt: 1 }}>
        <Iconify
          icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'}
          sx={{
            mr: 1,
            width: 24,
            height: 24,
            borderRadius: '50%',
            color: 'success.main',
            bgcolor: alpha(theme.palette.success.main, 0.16),
            ...(percent < 0 && {
              color: 'error.main',
              bgcolor: alpha(theme.palette.error.main, 0.16),
            }),
          }}
        />
      </Box>

      <Typography variant="subtitle2" component="div" sx={{ color: '#FFFFFF', pb: 1 }} noWrap>
        {percent > 0 && '+'}

        {fPercent(percent)}

        <Box
          component="span"
          sx={{ color: '#D6D6D6', typography: 'body2', whiteSpace: 'pre-line' }}
        >
          {` ${labelStrings?.thanDashboardStart}`}
        </Box>
      </Typography>
    </Stack>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        backgroundColor: '#505050',
        boxShadow: 0,
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack direction="row" sx={{ width: '100%', mb: 1 }}>
        <Box
          sx={{
            flexGrow: 1,
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle2" sx={{ color: '#FFFFFF' }}>
            {title}
          </Typography>
          <Typography
            noWrap
            variant="subtitle2"
            sx={{ color: '#D6D6D6', maxWidth: '130px', pb: 1 }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Stack>

      <Stack
        spacing={1}
        sx={{
          width: '100%',
          minHeight: '110px',
        }}
        alignItems="center"
      >
        <Typography variant="h3" sx={{ color: '#FFFFFF' }}>
          {pretifyBigNum(total)}
        </Typography>
        <Chart
          dir="ltr"
          type="line"
          series={[{ data: series }]}
          options={chartOptions}
          width={96}
          height={64}
        />
      </Stack>
      <Box>{renderTrending}</Box>
    </Box>
  )
}
