import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme, Card, CardHeader, IconButton, Box, Menu, MenuItem, Portal } from '@mui/material'
import { fNumber } from '../../../utils/formatNumber'
import Chart, { useChart } from '../index'
import { StyledPieChart } from './styles.js'
import { fPercent } from '../../../utils/formatNumber'
import { fShortenNumber } from '../../../utils/formatNumber'
import { TooltipComponent } from '../../tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import pptxgen from 'pptxgenjs'
import DownloadIcon from '@mui/icons-material/Download'
import html2canvas from 'html2canvas'
import { PieChartExport } from './Export/PieChartExport'

const findByLabel = ({ findKey, arr }) => arr?.find(el => el.label === findKey)
const findByValue = ({ findKey, arr }) => arr?.find(el => el.value === Number(findKey))

export const PieChart = ({
  title,
  subheader = '',
  chart,
  height = '480px',
  helperText = '',
  isPossibleDisplayOtherValue = false,
  isPdf,
  ...other
}) => {
  const theme = useTheme()

  const { colors, series, options } = chart

  const chartRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const chartSeries = series?.map(i => i.value)

  const maxValueItem = series?.sort((a, b) => b.value - a.value)[0]

  const labels = series?.map(i => i.label)

  const otherIndex = labels?.indexOf('Other')

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const exportElement = chartRef.current

    const cardImage = await html2canvas(exportElement, { scale: 2 })
    const cardDataUrl = cardImage.toDataURL('image/jpeg', 1.0)

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const cardAspectRatio = exportElement.offsetWidth / exportElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / cardAspectRatio

    slide.addImage({
      data: cardDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: slideHeight,
      type: 'contain',
    })
    pptx.writeFile('Chart.pptx').then(() => {
      handleDownloadClose()
    })
  }

  const exportToImage = async () => {
    const cardElement = chartRef.current
    const options = {
      scale: 2,
      useCORS: true,
      logging: true,
    }

    const cardImage = await html2canvas(cardElement, options)
    const cardDataUrl = cardImage.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = cardDataUrl
    link.download = 'Chart.png'
    link.click()
    handleDownloadClose()
  }

  if (otherIndex !== -1 && isPossibleDisplayOtherValue) {
    labels?.splice(otherIndex, 1)
    labels?.push('Other')
  }

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors,
    labels: labels,
    stroke: { colors: [theme.palette.background.paper], width: 0 },
    legend: {
      floating: true,
      position: 'bottom',
      offsetY: 0,
      horizontalAlign: 'center',
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: value => `${fNumber(value)}%`,
        title: {
          formatter: seriesName => `${seriesName}`,
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            name: {
              formatter: function(label) {
                const item = findByLabel({ arr: series, findKey: label })
                return `${label} ${item ? `${item.value}%` : ``}`
              },
            },
            value: {
              formatter: function(value) {
                const item = findByValue({ arr: series, findKey: value })
                if (!item?.absoluteReach) return
                return `${fShortenNumber(item.absoluteReach)} reached`
              },
            },
            total: {
              label: `${maxValueItem?.label} ${fPercent(maxValueItem?.value)}`,
              fontSize: '16px',
              showAlways: false,
              formatter: w => {
                if (!maxValueItem.absoluteReach) return
                return `${fShortenNumber(maxValueItem.absoluteReach)} reached`
              },
            },
          },
        },
      },
    },
    ...options,
  })

  return (
    <>
      <Card
        {...other}
        sx={{
          height,
          maxWidth: isPdf ? '380px' : 'initial',
          border: isPdf ? '1px solid rgba(145, 158, 171, 0.24)' : 'none',
        }}
      >
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <>
              {helperText?.length > 0 && (
                <IconButton aria-label="Helper-Text">
                  <TooltipComponent text={helperText}>
                    <InfoOutlinedIcon />
                  </TooltipComponent>
                </IconButton>
              )}
              <IconButton aria-label="Download" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
                <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
                <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
              </Menu>
            </>
          }
        />

        {isPdf ? (
          <Box
            sx={{
              mt: 3,
              '& .apexcharts-legend': {
                width: '100%',
                pt: '10px',
                overflowY: 'hidden',
              },
              '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
                overflow: 'visible',
                height: '300px',
              },
            }}
          >
            <Chart type="donut" series={chartSeries} options={chartOptions} height={240} />
          </Box>
        ) : (
          <StyledPieChart dir="ltr">
            <Chart type="donut" series={chartSeries} options={chartOptions} height={240} />
          </StyledPieChart>
        )}
      </Card>
      <Portal>
        <Box
          ref={chartRef}
          sx={{ position: `fixed`, top: `-9999px`, left: `-9999px`, zIndex: `-9999` }}
        >
          <PieChartExport
            title={title}
            subheader={subheader}
            chart={chart}
            height={height}
            isPossibleDisplayOtherValue={isPossibleDisplayOtherValue}
            other={other}
          />
        </Box>
      </Portal>
    </>
  )
}

PieChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  helperText: PropTypes.string,
  isPdf: PropTypes.bool,
  isPossibleDisplayOtherValue: PropTypes.bool,
}
