import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { USER_INDEXES } from '../../../constants/appSettings'
import {
  pretifyBigNum,
  copyToClipboard,
  constructLinkToWECFileServer,
  cutStringToMaxLength,
  checkIsUrlWithHttp,
  convertValueToPercentage,
  isEmptyObjectProperty,
  getPngCountryFlags,
  findCountryIsoCode,
} from '../../../utils'
import { themeCommonStyles } from '../../../themes/infludataThemes'
import ProfileAvatar from '../../profileCommon/profileAvatar'
import SocialPlatformTabs from '../../common/tabs/SocialPlatformTabs'
import ProfileOverviewTools from './ProfileOverviewTools'
import GoBackToResults from './GoBackToResults'
import SocialMedia from './SocialMedia'
import { CopyIcon, VerifiedProfileIcon } from '../../common/icons/icons'
import * as Styled from './styles'
import { useLastLocation } from 'react-router-last-location'
import { ProfileOverviewVerified } from './ProfileOverviewVerified'
import { Box, Stack } from '@mui/material'

const ProfileOverview = ({ profile }) => {
  const { _index: platform, isPrivate, website, isInfluDataVerified, collaborationInfo, isInactive } =
    profile || {}
  const { labelStrings, countryOptions } = useTranslation()
  const lastLocation = useLastLocation()

  const isProfileVerified = !!isInfluDataVerified
  const isCollabInfo = useMemo(() => isEmptyObjectProperty(collaborationInfo), [collaborationInfo])

  const isYTPlatform = platform === USER_INDEXES.youtube

  let personalWebsiteHref = website
  if (!checkIsUrlWithHttp(website)) {
    personalWebsiteHref = `//${website}`
  }

  const onEmailClick = () => {
    if (profile.publicEmail) {
      copyToClipboard(profile.publicEmail)
    }
  }

  const avatarSrc = constructLinkToWECFileServer(profile.profilePicURL)

  let profileScore
  if (!isPrivate) {
    switch (platform) {
      case USER_INDEXES.instagram:
        profileScore = profile.profileScore.overallScore
        break
      case USER_INDEXES.tiktok:
        profileScore = profile.profileScore.overallScore
        break
      case USER_INDEXES.youtube:
        profileScore = profile.profileScore.overallScore
    }
  } else {
    profileScore = 0
  }

  let statisticsNote
  if (platform === USER_INDEXES.instagram) {
    statisticsNote = `* ${labelStrings.valuesBasedOnEstimations}`
  }
  if (platform === USER_INDEXES.youtube) {
    statisticsNote = `* ${labelStrings.valuesBasedOnSubscribers}`
  }

  return (
    <Styled.ProfileOverviewContainer
      isPrivate={isPrivate}
      imgSrc={avatarSrc}
      isProfileVerified={isCollabInfo}
    >
      <SocialPlatformTabs
        currentPlatform={platform}
        profilesRefs={{
          [USER_INDEXES.instagram]: profile.socialHandles?.instagram,
          [USER_INDEXES.tiktok]: profile.socialHandles?.tiktok,
          [USER_INDEXES.youtube]: profile.socialHandles?.youtube,
        }}
      />

      {isPrivate && (
        <Styled.PrivateAccountMessage>
          {labelStrings.profileBecamePrivate}
        </Styled.PrivateAccountMessage>
      )}
      {(isInactive || profile.profileScore?.suspiciousActivities?.isInactive) && (
        <Styled.PrivateAccountMessage>
          {labelStrings.profileIsInactiveWarning}
        </Styled.PrivateAccountMessage>
      )}

      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Styled.ProfileOverview isProfileVerified={isCollabInfo}>
          {lastLocation?.pathname && <GoBackToResults platform={platform} />}

          <Styled.InfoContainer>
            <Styled.AvatarContainer>
              <Styled.AvatarWrapper>
                <ProfileAvatar
                  score={profileScore}
                  profilePicUrl={avatarSrc}
                  userindex={platform}
                  withRating
                  withGradient
                  withShadow={false}
                  animated
                  unfilledAreaBgColor={themeCommonStyles.getColorPaletteForPlatform(platform).dark}
                  size={190}
                />
              </Styled.AvatarWrapper>
              {isProfileVerified && (
                <Styled.AvatarVerifiedIcon>
                  <VerifiedProfileIcon />
                </Styled.AvatarVerifiedIcon>
              )}
            </Styled.AvatarContainer>

            <Styled.InfoColumn width="500px" darkVersion>
              <Styled.InfoGroup>
                {!isYTPlatform && (
                  <Styled.InfoGroupName>{labelStrings.username}</Styled.InfoGroupName>
                )}
                <Styled.Username platform={platform}>{profile.username}</Styled.Username>
              </Styled.InfoGroup>

              <Styled.InfoGroup>
                <Styled.InfoGroupName>
                  {isYTPlatform ? labelStrings.description : labelStrings.nameNBio}
                </Styled.InfoGroupName>
                <Styled.DisplayName platform={platform}>{profile.displayName}</Styled.DisplayName>
                <Styled.Location platform={platform}>
                  {getPngCountryFlags(findCountryIsoCode(countryOptions, profile.country)) ? (
                    <Stack direction="row" alignItems="center">
                      {getPngCountryFlags(findCountryIsoCode(countryOptions, profile.country))}{' '}
                      {profile.city ? `${profile.country}, ${profile.city}` : profile.country}
                    </Stack>
                  ) : (
                    profile.country
                  )}{' '}
                </Styled.Location>
                {profile.description ? (
                  <Styled.Biography platform={platform}>
                    {profile.description?.length > 200
                      ? cutStringToMaxLength({ str: profile.description, maxLength: 200 })
                      : profile.description}
                  </Styled.Biography>
                ) : (
                  <Styled.EmptyValue>-</Styled.EmptyValue>
                )}
              </Styled.InfoGroup>

              <Styled.InfoGroup>
                <Styled.InfoGroupName>{labelStrings.personalWebsite}</Styled.InfoGroupName>
                <Styled.PersonalWebsite
                  href={
                    personalWebsiteHref === '//null' ? 'javascript:void(0)' : personalWebsiteHref
                  }
                  target={personalWebsiteHref === '//null' ? '_self' : '_blank'}
                  platform={platform}
                  isDisabled={!profile.website}
                  style={{
                    cursor: personalWebsiteHref === '//null' ? 'default' : 'pointer',
                  }}
                >
                  {profile.website
                    ? cutStringToMaxLength({ str: profile.website, maxLength: 70 })
                    : '-'}
                </Styled.PersonalWebsite>
              </Styled.InfoGroup>

              <Styled.ContactWrapper>
                <Styled.InfoGroup>
                  <Styled.InfoGroupName>{labelStrings.contact}</Styled.InfoGroupName>
                  <Styled.CopyIconWrapper
                    platform={platform}
                    onClick={onEmailClick}
                    isDisabled={!profile.publicEmail}
                  >
                    {profile.publicEmail ? (
                      <>
                        <Styled.Email>{profile.publicEmail}</Styled.Email>
                        <CopyIcon />
                      </>
                    ) : (
                      '-'
                    )}
                  </Styled.CopyIconWrapper>
                </Styled.InfoGroup>

                <Styled.InfoGroup>
                  <Styled.InfoGroupName>{labelStrings.socialMedia}</Styled.InfoGroupName>
                  <SocialMedia profile={profile} />
                </Styled.InfoGroup>
              </Styled.ContactWrapper>
            </Styled.InfoColumn>

            <Styled.InfoColumn>
              <Styled.InfoGroup>
                <Styled.InfoGroupName>{labelStrings.profileData}</Styled.InfoGroupName>

                <Styled.Table>
                  {isYTPlatform ? (
                    <Styled.Tbody>
                      <Styled.Tr>
                        <Styled.Td>
                          <Styled.ProfileDataFieldName platform={platform}>
                            {labelStrings.subscribers}:
                          </Styled.ProfileDataFieldName>
                        </Styled.Td>
                        <Styled.Td>
                          <Styled.ProfileDataValue platform={platform}>
                            {pretifyBigNum(profile.followers)}
                          </Styled.ProfileDataValue>
                        </Styled.Td>
                      </Styled.Tr>

                      {/* <Styled.Tr>
                      <Styled.Td>
                        <Styled.ProfileDataFieldName platform={platform}>
                          {labelStrings.videos}:
                        </Styled.ProfileDataFieldName>
                      </Styled.Td>
                      <Styled.Td>
                        <Styled.ProfileDataValue platform={platform}>
                          {profile.posts ? pretifyBigNum(profile.posts) : '-'}
                        </Styled.ProfileDataValue>
                      </Styled.Td>
                    </Styled.Tr> */}
                    </Styled.Tbody>
                  ) : (
                    <Styled.Tbody>
                      <Styled.Tr>
                        <Styled.Td>
                          <Styled.ProfileDataFieldName platform={platform}>
                            {labelStrings.followers}:
                          </Styled.ProfileDataFieldName>
                        </Styled.Td>
                        <Styled.Td>
                          <Styled.ProfileDataValue platform={platform}>
                            {pretifyBigNum(profile.followers)}
                          </Styled.ProfileDataValue>
                        </Styled.Td>
                      </Styled.Tr>

                      <Styled.Tr>
                        <Styled.Td>
                          <Styled.ProfileDataFieldName platform={platform}>
                            {labelStrings.following}:
                          </Styled.ProfileDataFieldName>
                        </Styled.Td>
                        <Styled.Td>
                          <Styled.ProfileDataValue platform={platform}>
                            {pretifyBigNum(profile.following)}
                          </Styled.ProfileDataValue>
                        </Styled.Td>
                      </Styled.Tr>

                      <Styled.Tr>
                        <Styled.Td>
                          <Styled.ProfileDataFieldName platform={platform}>
                            {labelStrings.posts}:
                          </Styled.ProfileDataFieldName>
                        </Styled.Td>
                        <Styled.Td>
                          <Styled.ProfileDataValue platform={platform}>
                            {profile.posts}
                          </Styled.ProfileDataValue>
                        </Styled.Td>
                      </Styled.Tr>
                    </Styled.Tbody>
                  )}
                </Styled.Table>
              </Styled.InfoGroup>

              {isYTPlatform ? (
                <>
                  <Styled.InfoGroup>
                    <Styled.InfoGroupName>{labelStrings.statisticsShorts}</Styled.InfoGroupName>
                    <Styled.Table>
                      <Styled.Tbody>
                        <Styled.Tr>
                          <Styled.Td>
                            <Styled.ProfileDataFieldName platform={platform}>
                              {labelStrings.engagement}*:
                            </Styled.ProfileDataFieldName>
                          </Styled.Td>
                          <Styled.Td>
                            <Styled.ProfileDataValue platform={platform}>
                              {profile.engagementRateShorts
                                ? convertValueToPercentage(profile.engagementRateShorts)
                                : '-'}
                            </Styled.ProfileDataValue>
                          </Styled.Td>
                        </Styled.Tr>
                        <Styled.Tr>
                          <Styled.Td>
                            <Styled.ProfileDataFieldName platform={platform}>
                              {labelStrings.viewsMedian}:
                            </Styled.ProfileDataFieldName>
                          </Styled.Td>
                          <Styled.Td>
                            <Styled.ProfileDataValue platform={platform}>
                              {profile.medianViewCountShorts
                                ? pretifyBigNum(profile.medianViewCountShorts)
                                : '-'}
                            </Styled.ProfileDataValue>
                          </Styled.Td>
                        </Styled.Tr>
                      </Styled.Tbody>
                    </Styled.Table>
                  </Styled.InfoGroup>

                  <Styled.InfoGroup note={statisticsNote}>
                    <Styled.InfoGroupName>{labelStrings.statisticsVideos}</Styled.InfoGroupName>
                    <Styled.Table>
                      <Styled.Tbody>
                        <Styled.Tr>
                          <Styled.Td>
                            <Styled.ProfileDataFieldName platform={platform}>
                              {labelStrings.engagement}*:
                            </Styled.ProfileDataFieldName>
                          </Styled.Td>
                          <Styled.Td>
                            <Styled.ProfileDataValue platform={platform}>
                              {profile.engagementRatePosts
                                ? convertValueToPercentage(profile.engagementRatePosts)
                                : '-'}
                            </Styled.ProfileDataValue>
                          </Styled.Td>
                        </Styled.Tr>
                        <Styled.Tr>
                          <Styled.Td>
                            <Styled.ProfileDataFieldName platform={platform}>
                              {labelStrings.viewsMedian}:
                            </Styled.ProfileDataFieldName>
                          </Styled.Td>
                          <Styled.Td>
                            <Styled.ProfileDataValue platform={platform}>
                              {profile.medianViewCountPosts
                                ? pretifyBigNum(profile.medianViewCountPosts)
                                : '-'}
                            </Styled.ProfileDataValue>
                          </Styled.Td>
                        </Styled.Tr>
                      </Styled.Tbody>
                    </Styled.Table>
                  </Styled.InfoGroup>
                </>
              ) : (
                <Styled.InfoGroup note={statisticsNote}>
                  <Styled.InfoGroupName>{labelStrings.statistics}</Styled.InfoGroupName>

                  <Styled.Table>
                    <Styled.Tbody>
                      {(() => {
                        switch (platform) {
                          case USER_INDEXES.youtube:
                            return (
                              <>
                                <Styled.Tr>
                                  <Styled.Td>
                                    <Styled.ProfileDataFieldName platform={platform}>
                                      {labelStrings.viewsPerPost}*:
                                    </Styled.ProfileDataFieldName>
                                  </Styled.Td>
                                  <Styled.Td>
                                    <Styled.ProfileDataValue platform={platform}>
                                      {profile.medianViewsPerPost
                                        ? pretifyBigNum(profile.medianViewsPerPost)
                                        : '-'}
                                    </Styled.ProfileDataValue>
                                  </Styled.Td>
                                </Styled.Tr>
                              </>
                            )
                          case USER_INDEXES.instagram:
                            return (
                              <>
                                <Styled.Tr>
                                  <Styled.Td>
                                    <Styled.ProfileDataFieldName platform={platform}>
                                      {labelStrings.viewsPerPost}*:
                                    </Styled.ProfileDataFieldName>
                                  </Styled.Td>
                                  <Styled.Td>
                                    <Styled.ProfileDataValue platform={platform}>
                                      {profile.medianViewsPerPost
                                        ? pretifyBigNum(profile.medianViewsPerPost)
                                        : '-'}
                                    </Styled.ProfileDataValue>
                                  </Styled.Td>
                                </Styled.Tr>

                                {profile.medianViewsPerStoryObject && (
                                  <Styled.Tr>
                                    <Styled.Td>
                                      <Styled.ProfileDataFieldName platform={platform}>
                                        {labelStrings.viewsPerStory}*:
                                      </Styled.ProfileDataFieldName>
                                    </Styled.Td>
                                    <Styled.Td>
                                      <Styled.ProfileDataValue platform={platform}>
                                        {pretifyBigNum(profile.medianViewsPerStoryObject.from)} -{' '}
                                        {pretifyBigNum(profile.medianViewsPerStoryObject.to)}
                                      </Styled.ProfileDataValue>
                                    </Styled.Td>
                                  </Styled.Tr>
                                )}

                                {profile.medianPlaysPerReel && (
                                  <Styled.Tr>
                                    <Styled.Td>
                                      <Styled.ProfileDataFieldName platform={platform}>
                                        {labelStrings.playsPerReel}:
                                      </Styled.ProfileDataFieldName>
                                    </Styled.Td>
                                    <Styled.Td>
                                      <Styled.ProfileDataValue platform={platform}>
                                        {pretifyBigNum(profile.medianPlaysPerReel)}
                                      </Styled.ProfileDataValue>
                                    </Styled.Td>
                                  </Styled.Tr>
                                )}

                                <Styled.Tr>
                                  <Styled.Td pt="5px">
                                    <Styled.ProfileDataFieldName platform={platform}>
                                      {labelStrings.engagementPosts}:
                                    </Styled.ProfileDataFieldName>
                                  </Styled.Td>
                                  <Styled.Td pt="5px">
                                    <Styled.ProfileDataValue platform={platform}>
                                      {profile.engagementMean || '-'}
                                    </Styled.ProfileDataValue>
                                  </Styled.Td>
                                </Styled.Tr>

                                {profile.engagementReels &&
                                  parseFloat(profile.engagementReels) !== 0 && (
                                    <Styled.Tr>
                                      <Styled.Td>
                                        <Styled.ProfileDataFieldName platform={platform}>
                                          {labelStrings.engagementReels}:
                                        </Styled.ProfileDataFieldName>
                                      </Styled.Td>
                                      <Styled.Td>
                                        <Styled.ProfileDataValue platform={platform}>
                                          {profile.engagementReels}
                                        </Styled.ProfileDataValue>
                                      </Styled.Td>
                                    </Styled.Tr>
                                  )}
                                {profile.brandCollaborations?.metadata?.totalEngagementSponsored &&
                                  parseFloat(
                                    profile.brandCollaborations.metadata.totalEngagementSponsored
                                  ) !== 0 && (
                                    <Styled.Tr>
                                      <Styled.Td>
                                        <Styled.ProfileDataFieldName platform={platform}>
                                          {labelStrings.engagementSponsored}:
                                        </Styled.ProfileDataFieldName>
                                      </Styled.Td>
                                      <Styled.Td>
                                        <Styled.ProfileDataValue platform={platform}>
                                          {
                                            profile.brandCollaborations.metadata
                                              .totalEngagementSponsored
                                          }
                                        </Styled.ProfileDataValue>
                                      </Styled.Td>
                                    </Styled.Tr>
                                  )}
                              </>
                            )
                          case USER_INDEXES.tiktok: {
                            return (
                              <>
                                <Styled.Tr>
                                  <Styled.Td>
                                    <Styled.ProfileDataFieldName platform={platform}>
                                      {labelStrings.engagement}:
                                    </Styled.ProfileDataFieldName>
                                  </Styled.Td>
                                  <Styled.Td>
                                    <Styled.ProfileDataValue platform={platform}>
                                      {profile.engagementMean || '-'}
                                    </Styled.ProfileDataValue>
                                  </Styled.Td>
                                </Styled.Tr>
                                <Styled.Tr>
                                  <Styled.Td>
                                    <Styled.ProfileDataFieldName platform={platform}>
                                      {labelStrings.viewsPerVideo}:
                                    </Styled.ProfileDataFieldName>
                                  </Styled.Td>
                                  <Styled.Td>
                                    <Styled.ProfileDataValue platform={platform}>
                                      {profile.impressions ? pretifyBigNum(profile.impressions) : '-'}
                                    </Styled.ProfileDataValue>
                                  </Styled.Td>
                                </Styled.Tr>

                                <Styled.Tr>
                                  <Styled.Td>
                                    <Styled.ProfileDataFieldName platform={platform}>
                                      {labelStrings.totalPlays}:
                                    </Styled.ProfileDataFieldName>
                                  </Styled.Td>
                                  <Styled.Td>
                                    <Styled.ProfileDataValue platform={platform}>
                                      {profile.totalViews ? pretifyBigNum(profile.totalViews) : '-'}
                                    </Styled.ProfileDataValue>
                                  </Styled.Td>
                                </Styled.Tr>
                              </>
                            )
                          }
                        }
                      })()}
                    </Styled.Tbody>
                  </Styled.Table>
                </Styled.InfoGroup>
              )}
            </Styled.InfoColumn>
          </Styled.InfoContainer>
          {isCollabInfo && (
            <ProfileOverviewVerified platform={platform} collaborationInfo={collaborationInfo} />
          )}
        </Styled.ProfileOverview>
      </Box>

      <ProfileOverviewTools
        profile={profile}
        campaigns={profile.campaignArray}
        isProfileVerified={isProfileVerified}
        source='search'
      />
    </Styled.ProfileOverviewContainer>
  )
}

ProfileOverview.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default ProfileOverview
