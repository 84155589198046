import React from 'react'
import { CardHeader, Box } from '@mui/material'
import Chart, { useChart } from '../../index'
import { pretifyBigNum } from '../../../../../utils'
import { fShortenNumber } from '../../../../utils/formatNumber'

export const ColumnAreaChartExport = ({ title, subheader, chart, ...other }) => {
  const { labels, colors, series, options, isData } = chart

  const chartOptions = useChart({
    chart: {
      type: 'line',
      stacked: false,
    },
    colors,
    plotOptions: {
      bar: {
        columnWidth: '20%',
        borderRadius: 2,
      },
    },
    fill: {
      type: series?.map(i => i.fill),
    },
    stroke: {
      curve: 'smooth',
    },
    legend: {
      offsetY: -2,
      horizontalAlign: 'start',
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    labels,
    xaxis: {
      type: 'datetime',
      labels: {
        show: !isData?.length ? false : true,
        format: 'yyyy/MM/dd',
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        labels: {
          show: !isData?.length ? false : true,
          formatter: function(value) {
            return pretifyBigNum(value)
          },
        },
        title: {
          text: isData?.length ? 'Reach' : '',
          offsetY: -156,
          offsetX: 25,
          rotate: 0,
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value, obj, t) => {
          if (typeof value !== 'undefined') {
            return fShortenNumber(value)
          }
          return value
        },
      },
    },
    ...options,
  })

  return (
    <Box {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Chart type="line" series={series} options={chartOptions} height={364} width={'1200px'} />
      </Box>
    </Box>
  )
}
