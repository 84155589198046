import React, { useRef, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Portal,
} from '@mui/material'
import { UsersList } from './UsersList'
import { TooltipComponent } from '../../../components/tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import html2canvas from 'html2canvas'
import pptxgen from 'pptxgenjs'
import { TableViewGraphExport } from './Export/TableViewGraphExport'

export const TableViewGraph = ({ data, isPdf, helperText, subheader, currency }) => {
  const { engagement, overview, cpm, cpe, emv } = data || {}
  const exportRef = useRef(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const [duplicateFilteredStatusForExport, setDuplicateFilteredStatusForExport] = useState(
    'overview'
  )

  const onChangeDuplicateFilteredStatus = status => setDuplicateFilteredStatusForExport(status)

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const exportElement = exportRef.current

    const cardImage = await html2canvas(exportElement, { scale: 2 })
    const cardDataUrl = cardImage.toDataURL('image/jpeg', 1.0)

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const cardAspectRatio = exportElement.offsetWidth / exportElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / cardAspectRatio

    slide.addImage({
      data: cardDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: slideHeight,
      type: 'contain',
    })

    pptx.writeFile('CampaignMetrics.pptx').then(() => {
      handleDownloadClose()
    })
  }

  const exportToImage = async () => {
    const exportElement = exportRef.current

    const originalWidth = exportElement.style.width
    exportElement.style.width = `${window.innerWidth}px`

    const options = {
      scale: 2,
      useCORS: true,
      logging: true,
    }

    const cardImage = await html2canvas(exportElement, options)
    const cardDataUrl = cardImage.toDataURL('image/png')

    exportElement.style.width = originalWidth

    const link = document.createElement('a')
    link.href = cardDataUrl
    link.download = 'CampaignMetrics.png'
    link.click()
    handleDownloadClose()
  }

  return (
    <>
      <Card sx={{ mb: '24px !important', mt: 1 }}>
        <CardHeader
          sx={{ pt: isPdf ? 0 : '24px' }}
          title={'Campaign Metrics'}
          subheader={subheader}
          action={
            <>
              {helperText?.length && (
                <IconButton aria-label="Helper-Text">
                  <TooltipComponent text={helperText}>
                    <InfoOutlinedIcon />
                  </TooltipComponent>
                </IconButton>
              )}
              <IconButton aria-label="Download" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
                <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
                <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
              </Menu>
            </>
          }
        />

        <CardContent
          sx={{
            p: 2,
            pb: `16px !important`,
            '& .MuiTimelineItem-missingOppositeContent:before': {
              display: 'none',
            },
          }}
        >
          <Box>
            <UsersList
              overviewRowData={overview}
              engagementRowData={engagement}
              cpmRowData={cpm}
              cpeRowData={cpe}
              emvRowData={emv}
              currency={currency}
              onChangeDuplicateFilteredStatus={onChangeDuplicateFilteredStatus}
            />
          </Box>
        </CardContent>
      </Card>
      <Portal>
        <Box
          ref={exportRef}
          sx={{ position: `fixed`, top: `-9999px`, left: `-9999px`, zIndex: `-9999` }}
        >
          <TableViewGraphExport
            data={data}
            subheader={subheader}
            currency={currency}
            duplicateFilteredStatus={duplicateFilteredStatusForExport}
          />
        </Box>
      </Portal>
    </>
  )
}
