import React, { useState, useCallback, useLayoutEffect } from 'react'
import styled from 'styled-components'
import useTranslation from '../../../../../localization/useTranslation'
import { ClickHere } from '../../../../../components/common/styledWrappers'
import { COMPARED_PROFILES_LIMIT } from '../../../../../constants/comparedProfiles'
import { debouncedAsync } from '../../../../../utils'
import { CompareProfilesIcon } from '../../../../../components/common/icons'
import WithUpgradeTip from '../../../../../components/common/popups/WithUpgradeTip'
import WithTip from '../../../../../components/common/popups/WithTip'

const Wrapper = styled.div`
  position: relative;
`

const IconWrapper = styled.div`
  margin: 0 5px;
  ${props => props.disabled && props.theme.disableBlock};
  & > span {
    margin: 3px;
  }
`
const TipWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: #442424;
  font-family: Inter;
`
const ClickWrapper = styled(ClickHere)`
  color: ${props => props.theme.color.mineShaft};
`

const ProfileCompareTool = ({
  userindex = '',
  enableCompareProfiles,
  isUserAuthenticated,
  isAlreadyCompared,
  manageCompareProfile,
  isForbiddenPlatform,
  isInCollections,
  resetComparedProfiles,
  comparedProfilesCount = 0,
  isQuickPreview
}) => {
  const { labelStrings } = useTranslation()

  const [isCompared, setIsCompared] = useState(false)

  useLayoutEffect(() => {
    if (isCompared !== isAlreadyCompared) {
      setIsCompared(isAlreadyCompared)
    }
  }, [isAlreadyCompared, isCompared])

  const isDisabled = !isUserAuthenticated || !enableCompareProfiles
  const isLimitReached = comparedProfilesCount >= COMPARED_PROFILES_LIMIT
  const isAddExeedsLimits = !isCompared && isLimitReached
  const isActionInProgress = isCompared !== isAlreadyCompared

  const noActions = isDisabled || isForbiddenPlatform || isAddExeedsLimits

  // handlers
  const handleCompare = debouncedAsync(async (e, toAdd) => await manageCompareProfile(e,toAdd), 500)

  const onCompareClick = async (e) => {
    if (noActions) return
    const toAdd = !isCompared
    setIsCompared(toAdd)
    const success = await handleCompare(e, toAdd)
    if (!success) setIsCompared(false)
  }
  // helper components
  const CompareIcon = (
    <IconWrapper disabled={noActions} onClick={onCompareClick}>
      <CompareProfilesIcon
        userindex={userindex}
        isActiveIcon={noActions ? false : isCompared}
        {...(!isCompared && { tabIcon: false })}
      />
    </IconWrapper>
  )

  const _createTip = useCallback(() => {
    if (!isActionInProgress && (isForbiddenPlatform || isAddExeedsLimits)) {
      return (
        <TipWrapper>
          {isForbiddenPlatform
            ? labelStrings.cantAddManyPlatformsToCompare
            : labelStrings.comparedProfilesLimitReach}
          .
          {isInCollections && isForbiddenPlatform && (
            <>
              &nbsp;{labelStrings.useAnotherCollection} {labelStrings.or}&nbsp;
            </>
          )}
          <span>
            <ClickWrapper onClick={resetComparedProfiles}>{labelStrings.clickHere}</ClickWrapper>
            &nbsp;
            {labelStrings.toClearAllComparedProfiles}
          </span>
        </TipWrapper>
      )
    }
    return (
      <TipWrapper>
        {isCompared ? labelStrings.removeFromCompareProfiles : labelStrings.addToCompareProfiles}
      </TipWrapper>
    )
  }, [isForbiddenPlatform, isAddExeedsLimits, isInCollections, isActionInProgress, isQuickPreview])

  const _createUpgradeRequired = () => {
    return (
      <WithUpgradeTip
        tipText={labelStrings.useComapreProfiles}
        userindex={userindex}
        withSignup={!isUserAuthenticated}
        position={'left center'}
        arrow={isQuickPreview ? false : true}
      >
        {CompareIcon}
      </WithUpgradeTip>
    )
  }

  const _createEnabled = () => {
    return (
      <WithTip TipComponent={_createTip()} arrow={isQuickPreview ? false : true} position={'left center'} userindex={userindex}>
        {CompareIcon}
      </WithTip>
    )
  }

  return <Wrapper>{isDisabled ? _createUpgradeRequired() : _createEnabled()}</Wrapper>
}

export default ProfileCompareTool
